import { URIBuilder } from '_helpers/urlHelper';
import { store } from 'store';
import { IPaxpartnerAuthData, IPaxReferenceData } from 'types/backendProtocol';
import { IStoreState } from 'types/store';
import { IAuthSrcData } from '../../types/auth';

export function getPaxReferenceData(state: IStoreState): IPaxReferenceData {
  const authSrcData = state.auth.authSrcData.data;

  if (!authSrcData) {
    return null;
  }

  return authSrcData.authType === 'BOOKING'
    ? authSrcData.bookingSrc.referenceData
    : authSrcData.norecSrc.referenceData;
}

function getPaxPartnerAuthData(state: IStoreState): IPaxpartnerAuthData {
  const authSrcData = state.auth.authSrcData.data;

  if (!authSrcData) {
    return null;
  }

  return (authSrcData.authType === 'BOOKING'
    ? authSrcData.bookingSrc
    : authSrcData.norecSrc) as IPaxpartnerAuthData;
}

export function addAuthParamsToReturnURI(uri: string): string {
  const state = store.getState();

  const uriBuilder = URIBuilder.createForURI(uri);

  const authData = state.auth.authData.data;

  const paxRefData = getPaxReferenceData(state);

  const paxPartnerData = getPaxPartnerAuthData(state);

  if (authData) {
    uriBuilder.addParam('bookingNumber', authData.bookingNumber);
    uriBuilder.addParam('departureDate', authData.departureDate);
  }

  if (authData && paxRefData) {
    if (paxRefData.referrer)
      uriBuilder.addParam('referrer', paxRefData.referrer);
    if (paxRefData.utmCampaign)
      uriBuilder.addParam('utm_campaign', paxRefData.utmCampaign);
    if (paxRefData.utmMedium)
      uriBuilder.addParam('utm_medium', paxRefData.utmMedium);
    if (paxRefData.utmSource)
      uriBuilder.addParam('utm_source', paxRefData.utmSource);
  }

  if (authData && paxPartnerData) {
    if (paxPartnerData.lastName)
      uriBuilder.addParam('lastName', paxPartnerData.lastName);
    if (paxPartnerData.resellerData)
      uriBuilder.addParam('resellerData', paxPartnerData.resellerData);
  }

  return uriBuilder.toString();
}
