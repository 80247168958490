import * as React from 'react';
import { appConfig } from 'config';
import PropTypes from 'prop-types';
import { reduxForm, SubmissionError } from 'redux-form';
import { Text } from 'languages/components/text';
import { Bem } from 'react-bem-classes';
import http from 'axios';
import TextInput from 'commonBlocks/components/textInput';
import * as norecActions from 'norec/actions';
import {
  backendErrorFromAxiosError,
  defaultBackendErrorHandler,
} from '_helpers/backendError';
import * as wizLogic from 'norec/wizardLogic';
import { connect } from 'react-redux';
import { allCMSKeysRawSelector } from 'languages/selectors';
import { IStoreState } from 'types/store';

const formDef = wizLogic.FORMS_DEF.FLIGHT_NUMBER_FORM;

function checkFlightNumber(flightNumber) {
  return http.get(
    `${
      appConfig.apiUrl
    }/info/carrier-customer?flightNumber=${encodeURIComponent(
      flightNumber
    )}&paxshopId=${encodeURIComponent(`${appConfig.paxshopId}`)}`
  );
}

const bem = new Bem('modalNorec');

class FlightNumDialog extends React.Component<any, any> {
  static contextTypes = {
    store: PropTypes.object.isRequired,
  };

  submit = (values, dispatch) => {
    const { norecState } = this.props;

    const { flightNumber } = values;

    const { store } = this.context;

    return new Promise((resolve, reject) => {
      checkFlightNumber(flightNumber.toUpperCase())
        .then(result => {
          const freshNorecState = store.getState().norec;
          if (wizLogic.isStaleState(norecState, freshNorecState)) {
            return;
          }

          if (result.data.norecSupport) {
            dispatch(
              norecActions.finishFlightNumberForm(
                flightNumber.toUpperCase(),
                result.data.carrierCustomerId
              )
            );
            dispatch(norecActions.norecNext());
            resolve();
          } else {
            // reject({ flightNumber: "CMS_NOREC_UNSUPPORTED_ERROR"});
            reject(
              new SubmissionError({
                flightNumber: 'CMS_NOREC_UNSUPPORTED_ERROR',
              })
            );
          }
        })
        .catch(axiosError => {
          const freshNorecState = store.getState().norec;
          if (wizLogic.isStaleState(norecState, freshNorecState)) {
            return;
          }

          if (
            axiosError.name === 'SubmissionError' ||
            (!!axiosError.errors && axiosError.errors.segments)
          ) {
            reject(new SubmissionError(axiosError.errors));
          }

          const err = backendErrorFromAxiosError(axiosError);
          defaultBackendErrorHandler(err);
          resolve();
        });
    });
  };

  render() {
    const { handleSubmit, submitting, cmsKeys } = this.props;

    return (
      <form
        onSubmit={handleSubmit(this.submit)}
        className={bem.element('formRoot')}
      >
        <div className={bem.element('form')}>
          <div className={`${bem.element('formRow')} row`}>
            <div className={`${bem.element('formCol')} col-md-12`}>
              <div className={bem.element('subText')}>
                <Text>NOREC_CHECK_POPUP_DETAILS</Text>
              </div>
            </div>
          </div>

          <div className={`${bem.element('formRow')} row`}>
            <div className={`${bem.element('formCol')} col-md-6`}>
              <div className={`${bem.element('formRow')} row`}>
                <div className={`${bem.element('formCol')} col-md-12`}>
                  <label>
                    <Text>FLIGHT_NUMBER</Text>
                  </label>
                  <TextInput name="flightNumber" />
                </div>
              </div>
            </div>

            <div className={`${bem.element('formCol')} col-md-6`}>
              <div className={`${bem.element('formRow')} row`}>
                <div className={`${bem.element('formCol')} col-md-12`}>
                  <label>&nbsp;</label>

                  <button className="btn btn-primary btn--autoLineHeight">
                    <Text>NOREC_CHECK_POPUP_SUBMIT_BUTTON</Text>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`local-loader ${submitting ? 'active' : ''}`} />
      </form>
    );
  }
}

const connectedForm = connect((state: IStoreState) => ({
  cmsKeys: allCMSKeysRawSelector(state),
}))(FlightNumDialog);

export default reduxForm({
  form: formDef.reduxFormId,
})(connectedForm as any);
