import React from 'react';
import { connect } from 'react-redux';
import { IStoreState } from 'types/store';
import { allCMSKeysRawSelector } from 'languages/selectors';

type IProps = {
  src: string;
  cmsKey: string;
} & React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

function CMSImage({ src, alt, ...props }: IProps) {
  return <img alt={alt} src={src} {...props} />;
}

export default connect((state: IStoreState, { cmsKey }: IProps) => ({
  src: allCMSKeysRawSelector(state)[cmsKey],
}))(CMSImage);
