import React, { Component } from 'react';
import bem from 'react-bem-classes';
import './index.scss';
import { Text } from 'languages/components/text';
import { Price } from 'commonBlocks/components/price';

@bem({
  block: 'seatLegendModal',
})
class SeatLegendModal extends Component {
  render() {
    const {
      seat: { info, price = {}, showPrice },
    } = this.props;

    return (
      <div className={this.block()}>
        <Text className={this.element('title')}>{info.nameCmsKey}</Text>
        <Text className={this.element('info')}>{info.infoCmsKey}</Text>
        <div className={this.element('image')}>
          <img src={info.imageName} alt="" />
        </div>
        <div className={this.element('price')}>
          {showPrice && (
            <Price price={price.amount || ''} currency={price.currency || ''} />
          )}
        </div>
      </div>
    );
  }
}

export default SeatLegendModal;
