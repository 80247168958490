import React from 'react';
import { connect } from 'react-redux';
import { Bem } from 'react-bem-classes';
import Page, { Content } from 'layouts/page';
import { CurrentPageWizardHeader } from 'mainMenu/containers/currentPageWizardHeader';
import { IStoreState } from 'types/store';
import { Text } from 'languages/components/text';
import {
  selectFabScopeStatus,
  selectFabServicesOfScope,
} from 'services/selectors/fab';
import { addServicesToBasket, removeServicesFromBasket } from 'basket/actions';
import { segmentsSelector } from 'segments/helpers/selectors';
import { Loader } from 'commonBlocks/components/loader';
import AirportServicesList from './components/AirportServicesList';
import './index.scss';

const mapStateToProps = (state: IStoreState) => ({
  services: selectFabServicesOfScope(state, 'lounges'),
  segments: segmentsSelector(state),
  loungesStatus: selectFabScopeStatus(state, 'lounges'),
});

const mapDispatchToProps = {
  addServicesToBasket,
  removeServicesFromBasket,
};

type IProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const bem = new Bem('loungesPage');

function LoungesPage(props: IProps) {
  const {
    services,
    addServicesToBasket: addToBasket,
    removeServicesFromBasket: removeFromBasket,
    segments,
    loungesStatus,
  } = props;

  return (
    <Page>
      <CurrentPageWizardHeader />
      <Content mobileFullWidth>
        <Text className={bem.element('noteText')} as="p">
          SERVICES_LOUNGES_GROUP_NOTE_TEXT
        </Text>
        {loungesStatus === 'fulfilled' ? (
          <>
            {segments.map(segment => (
              <AirportServicesList
                segment={segment}
                services={services}
                addToBasket={addToBasket}
                removeFromBasket={removeFromBasket}
              />
            ))}
          </>
        ) : (
          <Loader />
        )}
      </Content>
    </Page>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoungesPage);
