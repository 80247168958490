import React, { Component } from 'react';
import bem from 'react-bem-classes';
import './index.scss';
import CartPassengersBySegment from 'passengers/components/cartPassengersBySegment';
import { JourneyBasedList } from '../JourneyBasedCart';

@bem({
  block: 'cartSegmentsList',
})
class SegmentsList extends Component {
  render() {
    const {
      segmentsList,
      passengersList,
      serviceGroups,
      removeServiceAction,
      basketInfo,
      currency,
    } = this.props;

    const hasJourneyBasedServices = basketInfo.some(
      service => service.journeyBased
    );

    return (
      <>
        <div className={this.block()}>
          {segmentsList.map((segment, i) => (
            <CartPassengersBySegment
              key={i}
              currency={currency}
              segment={segment}
              passengersList={passengersList}
              removeItem={removeServiceAction}
              basketInfo={basketInfo}
              serviceGroups={serviceGroups}
            />
          ))}
        </div>
        {hasJourneyBasedServices && (
          <JourneyBasedList
            servicesList={basketInfo}
            passengersList={passengersList}
            currency={currency}
            removeItem={removeServiceAction}
          />
        )}
      </>
    );
  }
}

export default SegmentsList;
