import React from 'react';
import { Text } from 'languages/components/text';
import { connect, Provider } from 'react-redux';
import bem from 'react-bem-classes';
import { allCMSKeysRawSelector } from 'languages/selectors';

import './index.scss';
import { Link } from '_helpers/link';

@connect(
  state => ({
    cmsKeys: allCMSKeysRawSelector(state),
  }),
  dispatch => ({ dispatch })
)
@bem({
  block: 'paymentFailed',
})
export default class PagePaymentFailed extends React.Component {
  render() {
    return (
      <div className={this.block()}>
        <div className={this.element('row', { paddingCompensation: true })}>
          <div className={this.element('titleRow')}>
            <div className={this.element('title')}>
              <h1>
                <Text useSpan>PAYMENT_FAILED_TITLE</Text>
              </h1>
            </div>
          </div>
          <Text className={this.element('subTitle')}>PAYMENT_FAILED_INFO</Text>
          <div className={this.element('row')}>
            <div className={this.element('columnHalf')}>
              <Link toPageId="checkout">
                <button className={this.element('backButton')}>
                  <Text useSpan>PAYMENT_FAILED_BUTTON</Text>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
