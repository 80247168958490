import React, { Component } from 'react';
import bem from 'react-bem-classes';
import './index.scss';

import { MobileMenu } from 'mainMenu/containers/mobileMenu/index';
import { goToPage } from 'routing/routing';
import { modalOpened, modalClosed } from '_helpers/modals';
import PropTypes from 'prop-types';

@bem({
  block: 'mobileHeaderMenu',
})
export class MobileHeaderMenu extends Component {
  static contextTypes = {
    loggedIn: PropTypes.bool.isRequired,
  };

  static propTypes = {
    basketItems: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      menuPopupVisible: false,
    };
  }

  closeMenuPopup = () => {
    if (this.state.menuPopupVisible) {
      modalClosed('MobileMenu');
    }

    this.setState({ menuPopupVisible: false });
  };

  openMenuPopup = () => {
    if (!this.state.menuPopupVisible) {
      modalOpened('MobileMenu');
    }

    this.setState({ menuPopupVisible: true });
  };

  componentWillUnmount() {
    if (this.state.menuPopupVisible) {
      modalClosed('MobileMenu');
    }
  }

  cartClicked = () => {
    const { basketItems } = this.props;
    if (basketItems.length === 0) {
      return;
    }

    goToPage('cart');
  };

  render() {
    const { basketItems } = this.props;

    const { loggedIn } = this.context;

    const { menuPopupVisible } = this.state;

    return (
      <div className={this.block()}>
        <div
          className={`${this.element('menuItem')} ${this.element('cart', {
            hidden: !loggedIn,
          })}`}
          onClick={this.cartClicked}
        >
          <i className="icon cart" />
          <div
            className={this.element('cartItemsCount', {
              hidden: basketItems.length === 0,
            })}
          >
            {basketItems.length}
          </div>
        </div>
        <div
          className={`${this.element('menuItem')} ${this.element('menu')}`}
          onClick={this.openMenuPopup}
        >
          <i className="icon menu" />
        </div>
        <MobileMenu
          active={menuPopupVisible}
          requestClose={this.closeMenuPopup}
        />
      </div>
    );
  }
}
