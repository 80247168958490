import React, { Component } from 'react';
import HtmlText from 'languages/components/htmlText';
import bem from 'react-bem-classes';
import './index.scss';

@bem({
  block: 'homeInfo',
})
class HomeInfo extends Component {
  render() {
    return (
      <div className={this.block()}>
        <HtmlText className={this.element('title')}>HOME_HEAD</HtmlText>
        <HtmlText className={this.element('text')}>HOME_TEXT</HtmlText>
        <button
          onClick={this.props.showHowtoModal}
          className={this.element('button')}
        >
          <HtmlText className={this.element('buttonText')}>
            LOGIN_PAGE_FAQ_EXTRA_BUTTON_TEXT
          </HtmlText>
        </button>
      </div>
    );
  }
}

export default HomeInfo;
