import React from 'react';
import { connect } from 'react-redux';
import {
  totalPriceSelector,
  basketEmptySelector,
} from 'serviceInBasket/helpers/selectors';
import { WizardHeader } from 'mainMenu/components/wizardHeader';
import { WizardFooter } from 'mainMenu/components/wizardFooter';
import { currentMenuItemSelector } from 'mainMenu/selectors';
import { cartCurrencySelector } from 'authorization/selectors';

@connect(state => ({
  mainMenuItem: currentMenuItemSelector(state),
  totalPrice: totalPriceSelector(state),
  currency: cartCurrencySelector(state),
  wizardEnabled: state.init.paxshopData.data.wizardMenu,
  basketEmpty: basketEmptySelector(state),
  paxshopPage: state.routing.paxshopPage,
}))
export class CurrentPageWizardHeader extends React.Component {
  render() {
    const {
      mainMenuItem,
      totalPrice,
      currency,
      wizardEnabled,
      nextStepAvailabilityStatus,
      isValidationPending,
      basketEmpty,
      paxshopPage,
    } = this.props;

    return (
      <div>
        <WizardHeader
          mainMenuItem={mainMenuItem}
          totalPrice={totalPrice}
          currency={currency}
          nextStepAvailabilityStatus={nextStepAvailabilityStatus}
          wizardEnabled={wizardEnabled}
          isValidationPending={isValidationPending}
          paxshopPage={paxshopPage}
        />
        {wizardEnabled && (
          <WizardFooter
            basketEmpty={basketEmpty}
            mainMenuItem={mainMenuItem}
            totalPrice={totalPrice}
            currency={currency}
            nextStepAvailabilityStatus={nextStepAvailabilityStatus}
            isValidationPending={isValidationPending}
          />
        )}
      </div>
    );
  }
}
