export default function CreateTriggerEventMiddleware(eventEmitter) {
  return function triggerEventMiddleware() {
    return next => action => {
      const { triggerEvent, ...rest } = action;
      next({ ...rest });
      if (!triggerEvent) return false;

      const eventData =
        typeof triggerEvent.data !== 'function'
          ? triggerEvent.data
          : triggerEvent.data(action);

      eventEmitter.trigger(triggerEvent.type, eventData);
    };
  };
}
