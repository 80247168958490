import {
  basketEmptySelector,
  hasBaggageServicesInBasket,
  hasLastSeatingValidationSelector,
  hasOtherServicesInBasket,
  hasSeatingServicesInBasket,
  hasSeatingValidationError,
  seatingValidatingSelector,
} from 'serviceInBasket/helpers/selectors';
import {
  hasBaggageServicesSelector,
  hasOtherServicesSelector,
} from 'services/selectors';
import { createSelector } from 'reselect';
import {
  IMainMenuItem,
  MainMenuItemId,
  MainMenuItemMarkType,
} from 'types/mainMenu';
import { IPaxshopPage, PaxshopPageId } from 'types/routing';
import { IStoreState, PaxSelector } from 'types/store';
import { selectFabScopeStatus } from '../../services/selectors/fab';

function getPaxshopPageId(id: MainMenuItemId): PaxshopPageId {
  switch (id) {
    case 'SEATING':
      return 'SEATING';
    case 'BAGGAGE':
      return 'SVC_BAGGAGE';
    case 'OTHER':
      return 'SVC_OTHER';
    case 'LOUNGES':
      return 'SVC_LOUNGES';
    case 'BOOKING':
      return 'MY_BOOKING';
    case 'CHECKOUT':
      return 'CART';
    case 'CONFIRMATION':
      return 'CONFIRMATION';
    case 'PAYMENT':
      return 'CHECKOUT';

    default:
      throw new Error(`Invalid MainMenuItemId ${id}`);
  }
}

function buildMenuItemSkeleton(
  id: MainMenuItemId,
  visible: boolean,
  enabled: boolean,
  pending: boolean,
  type: MainMenuItemMarkType
): IMainMenuItem {
  return {
    id,
    visible,
    enabled,
    pending,
    type,
    paxshopPageId: getPaxshopPageId(id),

    index: null,
    nextItem: null,
    prevItem: null,
    nextAllowedItem: null,
    prevAllowedItem: null,
  };
}

function linkMenuItems(menuItems: IMainMenuItem[]): void {
  let prevVisible: IMainMenuItem = null;

  let prevAllowed: IMainMenuItem = null;

  let index = 0;

  for (let i = 0; i < menuItems.length; i++) {
    const menuItem = menuItems[i];
    if (menuItem.visible) {
      if (prevVisible) {
        prevVisible.nextItem = menuItem;
        menuItem.prevItem = prevVisible;
      }

      menuItem.prevAllowedItem = prevAllowed;
      if (menuItem.enabled) {
        if (prevAllowed) {
          prevAllowed.nextAllowedItem = menuItem;
        }

        prevAllowed = menuItem;
      }

      prevVisible = menuItem;
      menuItem.index = index++;
    }
  }
}

function buildMenuItems(
  hasBaggageServices: boolean,
  hasOtherServices: boolean,
  hasSomethingInBasket: boolean,
  hasSeatingServicesInBasket: boolean,
  hasBaggageServicesInBasket: boolean,
  hasOtherServicesInBasket: boolean,
  seatingValidationInProgress: boolean,
  seatingValidationError: boolean,
  myBookingAsIcon: boolean,
  seatsValidatedAtLeastOnce: boolean,
  wizardEnabled: boolean,
  loungesStatus: 'absent' | 'fulfilled' | 'fetching'
): IMainMenuItem[] {
  const seatingItemType: MainMenuItemMarkType =
    hasSeatingServicesInBasket && !seatingValidationInProgress
      ? seatingValidationError
        ? 'ERROR'
        : 'CHECK'
      : 'NONE';

  const menuItems = [
    buildMenuItemSkeleton('BOOKING', !myBookingAsIcon, true, false, 'NONE'),
    buildMenuItemSkeleton(
      'SEATING',
      true,
      true,
      seatingValidationInProgress,
      seatingItemType
    ),
    buildMenuItemSkeleton(
      'BAGGAGE',
      hasBaggageServices,
      true,
      false,
      hasBaggageServicesInBasket ? 'CHECK' : 'NONE'
    ),
    buildMenuItemSkeleton(
      'OTHER',
      hasOtherServices,
      true,
      false,
      hasOtherServicesInBasket ? 'CHECK' : 'NONE'
    ),
    buildMenuItemSkeleton(
      'LOUNGES',
      loungesStatus !== 'absent',
      true,
      loungesStatus === 'fetching',
      'NONE'
    ),
    buildMenuItemSkeleton(
      'CHECKOUT',
      wizardEnabled,
      hasSomethingInBasket,
      false,
      'NONE'
    ),
  ];

  linkMenuItems(menuItems);

  const paymentItem = buildMenuItemSkeleton(
    'PAYMENT',
    false,
    false,
    false,
    'NONE'
  );
  paymentItem.prevItem = paymentItem.prevAllowedItem = menuItems.find(
    itm => itm.id === 'CHECKOUT'
  );
  menuItems.push(paymentItem);

  const confirmationItem = buildMenuItemSkeleton(
    'CONFIRMATION',
    false,
    false,
    false,
    'NONE'
  );
  confirmationItem.nextItem = confirmationItem.nextAllowedItem = menuItems.find(
    itm => itm.id === 'SEATING'
  );
  menuItems.push(confirmationItem);

  return menuItems;
}

export const mainMenuItemsSelector = createSelector(
  hasBaggageServicesSelector,
  hasOtherServicesSelector,
  basketEmptySelector,
  seatingValidatingSelector,
  hasSeatingValidationError,
  hasSeatingServicesInBasket,
  hasBaggageServicesInBasket,
  hasOtherServicesInBasket,
  state => state.init.paxshopInitParams.myBookingAsIcon,
  hasLastSeatingValidationSelector,
  state => state.init.paxshopData.data.wizardMenu,
  state => selectFabScopeStatus(state, 'lounges'),
  (
    hasBaggageServices: boolean,
    hasOtherServices: boolean,
    basketEmpty: boolean,
    seatingValidationInProgress: boolean,
    seatingValidationError: boolean,
    hasSeatingServicesInBasket: boolean,
    hasBaggageServicesInBasket: boolean,
    hasOtherServicesInBasket: boolean,
    myBookingAsIcon: boolean,
    seatsValidatedAtLeastOnce: boolean,
    wizardEnabled: boolean,
    loungesStatus
  ) =>
    buildMenuItems(
      hasBaggageServices,
      hasOtherServices,
      !basketEmpty,
      hasSeatingServicesInBasket,
      hasBaggageServicesInBasket,
      hasOtherServicesInBasket,
      seatingValidationInProgress,
      seatingValidationError,
      myBookingAsIcon,
      seatsValidatedAtLeastOnce,
      wizardEnabled,
      loungesStatus
    )
);

export const currentMenuItemSelector: PaxSelector<IMainMenuItem> = createSelector(
  state => state.routing.paxshopPage,
  mainMenuItemsSelector,
  (paxshopPage: IPaxshopPage, menuItems: IMainMenuItem[]) => {
    if (!paxshopPage) return null;
    return menuItems.find(m => m.paxshopPageId === paxshopPage.pageId);
  }
);
