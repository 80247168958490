import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { IStoreState } from 'types/store';
import { allCMSKeysRawSelector } from 'languages/selectors';
import { IPaxshopPage } from 'types/routing';
import { ICMSKeysMap } from 'types/languages';
import { PAGE_LOGIN } from 'routing/pages';
import { MatchedView } from 'router';
import CheckIn from 'check-in/containers/check-in';
import PageFooter from 'commonBlocks/components/pageFooter';
import PageHeader from 'layouts/pageHeader/containers';
import { TopLevelContainer } from 'layouts/topLevelContainer';
import { hot } from 'react-hot-loader/root';
import { ApisTopBar } from 'apis/containers/apisTopBar';
import { ordersSelector } from 'payment/selectors';
import { IEnhancedBookingOrder } from 'types/payment';
import { appConfig } from 'config';
import {
  IGTMProductWithCoupon,
  googleTagManager,
} from '_helpers/googleTagManager';
import { selectAllServices } from 'services/selectors';
import { IServiceFromApi } from 'types/services';

function RootView({
  paxshopPage,
  cmsKeys,
  orders,
  services,
}: {
  paxshopPage: IPaxshopPage;
  cmsKeys: ICMSKeysMap;
  orders: IEnhancedBookingOrder[];
  services: IServiceFromApi[];
}) {
  const showBgImage = paxshopPage === PAGE_LOGIN;

  const bgImageStyle =
    showBgImage && cmsKeys && cmsKeys.LOGIN_PAGE_BACKGROUND_IMAGE_URL
      ? {
          backgroundImage: `url(${cmsKeys.LOGIN_PAGE_BACKGROUND_IMAGE_URL})`,
        }
      : {};

  const [url, setUrl] = useState(new URL(window.location.href));
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (orders.length && services.length && !isSent) {
      const orderId = url.searchParams.get('orderId');
      if (!orderId) return;

      const orderData = orders.filter(
        order =>
          order.orderId === parseInt(orderId, 0) && order.state === 'PAID'
      );

      if (!orderData.length) return;

      let totalPrice = 0;
      const products = orderData
        .map<IGTMProductWithCoupon>(order => {
          totalPrice += parseInt(order.price, 0) * order.quantity;

          const serviceMatch = services.find(
            service => service.serviceId === order.serviceId
          );
          return {
            name: order.name,
            id: serviceMatch.serviceCode,
            price: order.price,
            category: order.serviceTag,
            quantity: order.quantity,
          };
        })
        .reduce<Record<string, IGTMProductWithCoupon>>((mapped, service) => {
          const { quantity, ...rest } = service;
          const key = JSON.stringify(rest);
          if (!mapped[key]) {
            // eslint-disable-next-line no-param-reassign
            mapped[key] = service;
          } else {
            // eslint-disable-next-line no-param-reassign
            mapped[key].quantity += quantity;
          }
          return mapped;
        }, {});

      googleTagManager.pushToDataLayer({
        event: 'purchase',
        ecommerce: {
          currencyCode: orderData[0].currency,
          purchase: {
            actionField: {
              revenue: totalPrice,
              id: orderId,
              affiliation: appConfig.baseUrl,
            },
            products: Object.values(products),
          },
        },
      });

      setIsSent(true);
    }
  }, [orders, services]);

  return (
    <div className={`paxshopApp ${paxshopPage.containerClass}`}>
      <DocumentTitle
        title={cmsKeys[paxshopPage.titleCms] || paxshopPage.titleFallback}
      />
      <div
        id="paxshopWrapper"
        className="paxshopApp__wrapper"
        style={bgImageStyle}
      >
        <div className="paxshopApp__mainContent">
          {paxshopPage.auth.requiresLogin && <CheckIn isTopSegmentBar />}
          {paxshopPage.auth.requiresLogin && <ApisTopBar />}
          <PageHeader />
          <TopLevelContainer>
            <MatchedView paxshopPage={paxshopPage} />
          </TopLevelContainer>
        </div>
        <div className="paxshopApp__buffer" />
      </div>
      <PageFooter />
    </div>
  );
}

export default hot(
  connect((state: IStoreState) => ({
    paxshopPage: state.routing.paxshopPage,
    cmsKeys: allCMSKeysRawSelector(state),
    orders: ordersSelector(state),
    services: selectAllServices(state),
  }))(RootView)
);
