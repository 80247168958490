import * as React from 'react';

export const menuVisualData = {
  BOOKING: {
    title: 'ALL_PAGES_CATEGORY_NAME_1',
    getIconClassName: cmsKeys => 'icon pass2',
    getChildren: () => null,
  },
  SEATING: {
    title: 'ALL_PAGES_CATEGORY_NAME_2',
    getIconClassName: cmsKeys => 'icon chair',
    description: 'SEATING_PAGE_SUB_HEADING',
    getChildren: _this =>
      !_this.props.wizardMenu &&
      _this.props.isShowingSeatingError && (
        <div className={_this.element('errorIcon')}>!</div>
      ),
  },
  BAGGAGE: {
    title: 'ALL_PAGES_CATEGORY_NAME_3',
    getIconClassName: cmsKeys => 'icon baggage',
    description: 'BAGGAGE_PAGE_SUB_HEADING',
    getChildren: () => null,
  },
  OTHER: {
    title: 'ALL_PAGES_CATEGORY_NAME_4',
    getIconClassName: cmsKeys => `icon ${cmsKeys.ICON_CATEGORY_4}`,
    description: 'SERVICES_PAGE_SUB_HEADING',
    getChildren: () => null,
  },
  LOUNGES: {
    title: 'ALL_PAGES_CATEGORY_NAME_4',
    getIconClassName: cmsKeys => `icon ${cmsKeys.ICON_CATEGORY_4}`,
    description: 'SERVICES_PAGE_SUB_HEADING',
    getChildren: () => null,
  },
  CHECKOUT: {
    title: 'PAYMENT_PAGE_HEADING',
    getIconClassName: cmsKeys => 'icon cart',
    description: 'SHOPPING_CART_SUB_HEADING',
    getChildren: () => null,
  },
};
