import React, { ChangeEvent, SyntheticEvent, useRef, useState } from 'react';
import cn from 'classnames';
import { Bem } from 'react-bem-classes';
import { WrappedFieldProps } from 'redux-form';
import './index.scss';
import { useOnClickOutside } from '_helpers/useOnClickOutside';
import { Close } from './Close';
import { useLocale } from '../../languages/_helpers/localeContext';

interface IOption {
  label: string;
  name: string;
}

interface IProps {
  options: IOption[];
  value: string[];
  onChange: (val: string[]) => void;
  touched: boolean;
  error?: string;
  onBlur: (val: string[]) => void;
  onFocus: (e: SyntheticEvent) => void;
  small?: boolean;
}

const bem = new Bem('multicheckbox');

function getNewValueForChange(value: string[], name: string, checked: boolean) {
  if (checked && !value.includes(name)) {
    return [...value, name];
  }

  if (!checked && value.includes(name)) {
    return value.filter(selectedValue => selectedValue !== name);
  }

  return value;
}

function MultiCheckboxSelect({
  onChange,
  value,
  options,
  error,
  onBlur,
  onFocus,
  touched,
  small,
}: IProps) {
  const { t } = useLocale();
  const [isOpened, setIsOpened] = useState(false);
  const wrapperRef = useRef<null | HTMLDivElement>(null);

  const focus = (e: SyntheticEvent) => {
    onFocus(e);
    setIsOpened(true);
  };

  const blur = () => {
    if (isOpened) {
      onBlur(value);
      setIsOpened(false);
    }
  };

  useOnClickOutside(wrapperRef, blur);

  const currentStringValue =
    value.length > 0
      ? `${value.length} ${t('MULTI_CHECKBOX_SELECTED_NUM')}`
      : t('MULTI_CHECKBOX_CLICK_TO_SELECT');

  return (
    <div className={bem.block({ small })} ref={wrapperRef}>
      <div
        className={bem.element('popup', {
          opened: isOpened,
          closed: !isOpened,
        })}
      >
        <div className={bem.element('close-button-wrapper')}>
          <button
            className={bem.element('close-button')}
            type="button"
            onClick={blur}
            aria-label="Close"
            aria-hidden
          >
            <Close width="25" height="25" />
          </button>
        </div>
        <div
          className={bem.element('checkboxes-container')}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(
              getNewValueForChange(value, e.target.name, e.target.checked)
            )
          }
        >
          {options.map(option => (
            <label className={bem.element('checkbox-label')} key={option.name}>
              <input
                className={bem.element('checkbox-input')}
                type="checkbox"
                name={option.name}
              />
              {option.label}
            </label>
          ))}
        </div>
      </div>
      <input
        aria-hidden
        type="text"
        className={cn('textInput', { 'textInput--small': small })}
        value={currentStringValue}
        onChange={() => {}}
        onFocus={focus}
      />
      <div className={bem.element('error')}>{touched && error && t(error)}</div>
    </div>
  );
}

export function renderMultiCheckboxSelect(
  field: WrappedFieldProps & Partial<IProps>
) {
  return (
    <MultiCheckboxSelect
      options={field.options}
      value={field.input.value}
      onChange={field.input.onChange}
      onFocus={field.input.onFocus}
      onBlur={field.input.onBlur}
      touched={field.meta.touched}
      error={field.meta.error}
      small={field.small}
    />
  );
}
