import React, { Component } from 'react';
import bem from 'react-bem-classes';
import './index.scss';
import { Text } from 'languages/components/text';
import { goToPage } from 'routing/routing';
import { scrollToTop } from '_helpers/domHelper';
import { connect } from 'react-redux';
import { totalPriceSelector } from 'serviceInBasket/helpers/selectors';

@connect(state => ({
  totalPrice: totalPriceSelector(state),
}))
@bem({
  block: 'cartTotal',
  modifiers: ['noTotalAmount'],
})
class CartTotal extends Component {
  onCheckout = () => {
    goToPage('checkout');
    scrollToTop();
  };

  render() {
    const { currency, nextStepCondition, totalPrice } = this.props;

    return (
      <div className={this.block()}>
        <div className={this.element('amount')}>
          <Text className={this.element('amountText')}>BASKET_TOTAL</Text>
          <div className={this.element('amountNumber')}>
            {totalPrice.toFixed(2)} {currency}
          </div>
        </div>
        <Text
          className={this.element('button', { disabled: !nextStepCondition })}
          onClick={nextStepCondition && this.onCheckout}
        >
          BASKET_CHECKOUT_BUTTON
        </Text>
      </div>
    );
  }
}

export default CartTotal;
