import React, { ComponentProps } from 'react';
import TextInput from 'commonBlocks/components/textInput';
import { Text } from 'languages/components/text';
import { Bem } from 'react-bem-classes';

const bem = new Bem('specialAssistanceForm');

export function TextFieldWithLabel({
  labelCms,
  name,
  placeholder,
  ...props
}: {
  labelCms: string;
  name: string;
  placeholder: string;
} & ComponentProps<typeof TextInput>) {
  return (
    <div>
      <Text className={bem.element('smallLabel')}>{labelCms}</Text>
      <TextInput name={name} placeholder={placeholder} {...props} />
    </div>
  );
}
