import http from 'axios';
import { initialize } from 'redux-form';
import { appConfig } from 'config';
import * as norecActions from 'norec/actions';
import {
  backendErrorFromAxiosError,
  defaultBackendErrorHandler,
  anyToBackendError,
} from '_helpers/backendError';
import { createUrlParams } from '_helpers/urlHelper';
import { internalDateToPaxshop } from '_helpers/dateFormat';
import { dateOptionsSelector } from 'languages/selectors';
import errorLogger from '_helpers/errorLogger';

export const FORMS_DEF = {
  FLIGHT_NUMBER_FORM: {
    fields: ['flightNumber'],
    reduxFormId: 'NOREC__FLIGHT_NUMBER_FORM',
  },

  FLIGHTS_FORM: {
    fields: [
      'segments[].enabled',
      'segments[].flightNumber',
      'segments[].departureDate',
      'segments[].bookingClassId',
      'overriddenTourOperatorId',
      'cabinClassTermsAccepted',
      '_formInitialized',
    ],
    reduxFormId: 'NOREC__FLIGHTS_FORM',
  },

  AIRPORTS_FORM: {
    fields: [
      'segments[].enabled',
      'segments[].flightNumber',
      'segments[].departureDate',
      'segments[].beginningDepartureId',
      'segments[].endingDepartureId',
    ],
    reduxFormId: 'NOREC__AIRPORTS_FORM',
  },

  PASSENGERS_FORM: {
    fields: [
      'passengers[].firstName',
      'passengers[].lastName',
      'passengers[].passengerType',
    ],
    reduxFormId: 'NOREC__PASSENGERS_FORM',
  },
};

FORMS_DEF.FLIGHT_NUMBER_FORM.prepareForm = function prepareFlightNumFormAction(
  norecState
) {
  const formDef = FORMS_DEF.FLIGHT_NUMBER_FORM;

  return initialize(
    formDef.reduxFormId,
    {
      flightNumber: norecState.carrierFlightNumber,
    },
    formDef.fields
  );
};

function getBookingConfig(carrierCustomerId, getState) {
  const { paxPartner } = getState().init.paxshopAppCtx;

  const cabinClassesParams = {
    carrierCustomerId: undefinedIfNull(carrierCustomerId),
    paxshopId: appConfig.paxshopId,
    ...(paxPartner &&
      appConfig.paxpartnerSalesChannel && {
        isPaxPartner: true,
      }),
  };

  const cabinClassesPromise = http.get(
    `${appConfig.apiUrl}/info/cabin-classes${createUrlParams(
      cabinClassesParams
    )}`
  );

  const tourOperatorsPromise = http.get(
    `${appConfig.apiUrl}/info/tour-operators${createUrlParams({
      carrierCustomerId: undefinedIfNull(carrierCustomerId),
      paxshopId: appConfig.paxshopId,
    })}`
  );

  return new Promise((resolve, reject) => {
    Promise.all([cabinClassesPromise, tourOperatorsPromise])
      .then(
        values => {
          resolve({
            cabinClasses: values[0].data,
            tourOperators: values[1].data,
          });
        },
        axiosError => reject(backendErrorFromAxiosError(axiosError))
      )
      .catch(errorLogger);
  });
}

function fetchBookingConfigIfRequired(dispatch, getState, onDataReady) {
  const norecState = getState().norec;
  const { bookingConfig } = norecState;
  const { carrierCustomerId } = norecState;

  if (
    !bookingConfig.origin ||
    bookingConfig.origin.carrierCustomerId !== carrierCustomerId ||
    bookingConfig.origin.fetching
  ) {
    // Need to fetch data from backend
    dispatch(
      norecActions.updateBookingConfig(
        { carrierCustomerId, fetching: true },
        null,
        null
      )
    );
    getBookingConfig(carrierCustomerId, getState)
      .then(
        res => {
          const freshNorecState = getState().norec;
          if (isStaleState(norecState, freshNorecState, 1)) {
            return;
          }

          dispatch(
            norecActions.updateBookingConfig(
              { carrierCustomerId, fetching: false },
              res.cabinClasses,
              res.tourOperators
            )
          );
          onDataReady();
        },
        backendError => {
          errorLogger(backendError);

          const freshNorecState = getState().norec;
          if (isStaleState(norecState, freshNorecState, 1)) {
            return;
          }

          dispatch(norecActions.hideNorecDialog());
          defaultBackendErrorHandler(backendError);
        }
      )
      .catch(error => {
        errorLogger(error);
        defaultBackendErrorHandler(anyToBackendError(error));
      })
      .catch(errorLogger);
  } else {
    onDataReady();
  }
}

FORMS_DEF.FLIGHTS_FORM.prepareForm = function prepareFlightsFormAction(
  norecState
) {
  const formDef = FORMS_DEF.FLIGHTS_FORM;

  return function(dispatch, getState) {
    dispatch(
      initialize(
        formDef.reduxFormId,
        { _formInitialized: false },
        formDef.fields
      )
    );

    fetchBookingConfigIfRequired(dispatch, getState, () => {
      const state = getState();
      const paxshopDateFormat = dateOptionsSelector(state).paxshopDateFormat;
      const norecState = state.norec;

      dispatch(
        initialize(
          formDef.reduxFormId,
          {
            segments: norecState.segments.map((segment, segmentIndex) => {
              const {
                enabled,
                flightNumber,
                departureDate,
                bookingClassId,
              } = segment;
              return {
                enabled,
                flightNumber,
                bookingClassId: bookingClassId || '',
                departureDate: internalDateToPaxshop(
                  !departureDate && segmentIndex === 0
                    ? norecState.bookingDate
                    : departureDate,
                  paxshopDateFormat
                ),
              };
            }),

            overriddenTourOperatorId: norecState.overriddenTourOperatorId || '',
            cabinClassTermsAccepted: norecState.cabinClassTermsAccepted,
            _formInitialized: true,
          },
          formDef.fields
        )
      );
    });
  };
};

FORMS_DEF.AIRPORTS_FORM.prepareForm = function prepareAirportsFormAction(
  norecState,
  state
) {
  const formDef = FORMS_DEF.AIRPORTS_FORM;

  return initialize(
    formDef.reduxFormId,
    {
      segments: norecState.segments.map(segment => {
        const {
          enabled,
          flightNumber,
          departureDate,
          beginningDepartureId,
          endingDepartureId,
        } = segment;
        return {
          enabled,
          flightNumber,
          beginningDepartureId: beginningDepartureId || '',
          endingDepartureId: endingDepartureId || '',
        };
      }),
    },
    formDef.fields
  );
};

FORMS_DEF.PASSENGERS_FORM.prepareForm = function preparePassengersFormAction(
  norecState
) {
  const formDef = FORMS_DEF.PASSENGERS_FORM;

  return initialize(
    formDef.reduxFormId,
    {
      passengers: norecState.passengers.map(passenger => ({
        firstName: passenger.firstName,
        lastName: passenger.lastName,
        passengerType: passenger.passengerType || '',
      })),
    },
    formDef.fields
  );
};

function undefinedIfNull(val) {
  if (val === null) return undefined;

  return val;
}

export function hasMultilegFlight(norecState) {
  return norecState.segments.findIndex(s => s.departures.length > 1) !== -1;
}

export function isCarrierShop(paxshopData) {
  return paxshopData && !paxshopData.tourOperator;
}

export function isStaleState(baseNorecState, currentNorecState, incStep = 0) {
  return currentNorecState.stepsCounter > baseNorecState.stepsCounter + incStep;
}

export function getNorecNextStep(norecState, paxshopData) {
  switch (norecState.norecStep) {
    case 'DISABLED':
      return isCarrierShop(paxshopData) ? 'FLIGHTS_FORM' : 'FLIGHT_NUMBER_FORM';

    case 'FLIGHT_NUMBER_FORM':
      return 'FLIGHTS_FORM';

    case 'FLIGHTS_FORM':
      return hasMultilegFlight(norecState)
        ? 'AIRPORTS_FORM'
        : 'PASSENGERS_FORM';

    case 'AIRPORTS_FORM':
      return 'PASSENGERS_FORM';

    case 'PASSENGERS_FORM':
      return null;

    default:
      return null;
  }
}

export function getNorecPrevStep(norecState, paxshopData) {
  switch (norecState.norecStep) {
    case 'DISABLED':
      return null;

    case 'FLIGHT_NUMBER_FORM':
      return null;

    case 'FLIGHTS_FORM':
      return isCarrierShop(paxshopData) ? null : 'FLIGHT_NUMBER_FORM';

    case 'AIRPORTS_FORM':
      return 'FLIGHTS_FORM';

    case 'PASSENGERS_FORM':
      return hasMultilegFlight(norecState) ? 'AIRPORTS_FORM' : 'FLIGHTS_FORM';

    default:
      return null;
  }
}

export const PASSENGER_GENDERS = {
  MALE: { text: 'NOREC_GENDER_MALE', age: 99 },
  FEMALE: { text: 'NOREC_GENDER_FEMALE', age: 99 },
  CHILD: { text: 'NOREC_GENDER_CHILD', age: 11 },
  INFANT: { text: 'NOREC_GENDER_INFANT', age: 1 },
};

export const PASSENGER_GENDERS_LIST = Object.keys(
  PASSENGER_GENDERS
).map(id => ({ ...PASSENGER_GENDERS[id], id }));

export const STEP_TO_INDEX = {
  DISABLED: 0,
  FLIGHT_NUMBER_FORM: 1,
  FLIGHTS_FORM: 2,
  AIRPORTS_FORM: 3,
  PASSENGERS_FORM: 4,
};
