import {
  UNLOCK_SEAT_SELECTION,
  LOCK_SEAT_SELECTION,
} from '../constants/selectionLockTypes';

export function lockSelection() {
  return {
    type: LOCK_SEAT_SELECTION,
  };
}

export function unlockSelection() {
  return {
    type: UNLOCK_SEAT_SELECTION,
  };
}
