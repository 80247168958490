import './index.scss';

import React from 'react';
import bem from 'react-bem-classes';
import { ServicesList } from 'services/components/list';
import { Text } from 'languages/components/text';
import Icon from '_helpers/Icon';
import ForExistCmsKey from 'languages/components/forExistCmsKey';
import BasketContainer from 'basket/containers';
import MediaQuery from 'react-responsive';

@bem({
  block: 'listContent',
})
export class ServicesListContent extends React.Component {
  render() {
    const { services, headerText, isHeaderShowing } = this.props;

    return (
      <div className={this.block()}>
        <div>
          {services.length === 0 || !headerText || !isHeaderShowing ? null : (
            <ForExistCmsKey cmsKey={headerText}>
              <MediaQuery minWidth={LAYOUT_BREAKS.desktop} key="A">
                <div
                  className={this.element('note', {
                    hasOtherGroups: this.props.hasOtherGroups,
                  })}
                >
                  <Icon iconCode="note" />
                  <Text>{headerText}</Text>
                </div>
              </MediaQuery>
              <MediaQuery maxWidth={LAYOUT_BREAKS.desktop - 1} key="B">
                <div className={this.element('note')}>
                  <Icon iconCode="note" />
                  <Text>{headerText}</Text>
                </div>
              </MediaQuery>
            </ForExistCmsKey>
          )}
          <BasketContainer>
            <ServicesList services={services} />
          </BasketContainer>
        </div>
      </div>
    );
  }
}
