import * as React from 'react';
import * as ReactDOM from 'react-dom';

export function isReactElementVisible(
  reactElement: React.ReactInstance
): boolean {
  if (!reactElement) {
    return false;
  }

  const domNode = ReactDOM.findDOMNode(reactElement);

  if (!domNode || !(domNode instanceof Element)) {
    return false;
  }

  const computedStyle = window.getComputedStyle(domNode, null);

  if (!computedStyle) {
    return false;
  }

  return (
    computedStyle.display && computedStyle.display.toLowerCase() !== 'none'
  );
}
