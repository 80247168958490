import React from 'react';
import { IPaxshopPage } from './types/routing';
import PageHome from './pageHome/containers';
import PageMyBooking from './pageMybooking/containers';
import PageSeating from './pageSeating/containers';
import { PageBaggage } from './pageBaggage/containers';
import { PageOtherServices } from './pageOtherServices/containers';
import PagePaymentFailed from './pagePaymentFailed/containers';
import PageConfirmation from './pageConfirmation';
import CheckoutPage from './payment/containers/checkoutPage';
import PageCart from './pageCart/containers';
import {
  PAGE_BAGGAGE,
  PAGE_CART,
  PAGE_CHECKOUT,
  PAGE_CONFIRMATION,
  PAGE_LOGIN, PAGE_LOUNGES,
  PAGE_MY_BOOKING,
  PAGE_OTHER_SERVICES,
  PAGE_PAYMENT_FAILED,
  PAGE_SEATING,
} from './routing/pages';
import LoungesPage from './pages/lounges';

interface IProps {
  paxshopPage: IPaxshopPage;
}

export function MatchedView(props: IProps): JSX.Element {
  const { paxshopPage } = props;

  switch (paxshopPage) {
    case PAGE_LOGIN:
      return <PageHome />;
    case PAGE_MY_BOOKING:
      return <PageMyBooking />;
    case PAGE_SEATING:
      return <PageSeating />;
    case PAGE_BAGGAGE:
      return <PageBaggage />;
    case PAGE_OTHER_SERVICES:
      return <PageOtherServices />;
    case PAGE_LOUNGES:
      return <LoungesPage />;
    case PAGE_CART:
      return <PageCart />;
    case PAGE_CHECKOUT:
      return <CheckoutPage />;
    case PAGE_CONFIRMATION:
      return <PageConfirmation />;
    case PAGE_PAYMENT_FAILED:
      return <PagePaymentFailed />;
    default:
      return null;
  }
}
