import React from 'react';
import { history } from 'routing/routing';
import { ALL_PAGES } from 'routing/pages';

interface IProps {
  toPageId: string;
  toLocalUrl: string;
  useSpan: boolean;
  disabled: boolean;
}

export class Link extends React.Component<IProps> {
  private go = e => {
    const { toPageId, toLocalUrl, disabled } = this.props;

    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    if (disabled) {
      return;
    }

    if (toPageId) {
      const page = ALL_PAGES.find(p => p.pageId === toPageId.toUpperCase());
      if (page) {
        history.push(page.url);
      }
    } else if (toLocalUrl) {
      history.push(toLocalUrl);
    }
  };

  render(): JSX.Element {
    const { disabled, children, useSpan, toPageId, ...restProps } = this.props;

    return useSpan ? (
      <span
        {...restProps}
        onClick={this.go}
        style={{ cursor: disabled ? null : 'pointer' }}
      >
        {children}
      </span>
    ) : (
      <div
        {...restProps}
        onClick={this.go}
        style={{ cursor: disabled ? null : 'pointer' }}
      >
        {children}
      </div>
    );
  }
}
