import './index.scss';

import React from 'react';
import bem from 'react-bem-classes';
import PropTypes from 'prop-types';
import { appConfig } from 'config';
import { resetSection } from 'redux-form';
import { ServicesItem } from 'services/components/item';
import { FakeItem } from 'services/components/fakeItem';

import { connect } from 'react-redux';
import { allCMSKeysRawSelector, localeDataSelector } from 'languages/selectors';
import { Text } from 'languages/components/text';
import { currentPassengerSelector } from 'passengers/helpers/selectors';
import { RawSelectInput } from '../../../commonBlocks/components/selectInput';
import { T } from '../../../languages/components/text';

@connect(state => ({
  allCMSKeys: allCMSKeysRawSelector(state),
  currentPassengerId: state.passengers.currentPassengerId,
  currentPassenger: currentPassengerSelector(state),
  currentServiceGroup: state.services.currentGroup,
  localeData: localeDataSelector(state),
}))
@bem({
  block: 'servicesList',
})
export class ServicesList extends React.Component {
  static contextTypes = {
    showServiceInfoDialog: PropTypes.func.isRequired,
    hideServiceInfoDialog: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedResidencyCountry: '',
    };
  }

  addToBasketClicked = services => {
    const { currentPassengerId, currentServiceGroup } = this.props;

    const { hideServiceInfoDialog } = this.context;

    this.props
      .addItem(services, currentPassengerId, currentServiceGroup)
      .then(isAdded => {
        isAdded && hideServiceInfoDialog();
      });
  };

  openServiceModal = (service, addCartProps) => {
    const { showServiceInfoDialog } = this.context;

    showServiceInfoDialog(service, this.addToBasketClicked, addCartProps);
  };

  renderFakeServices() {
    const { currentServiceGroup, allCMSKeys } = this.props;

    const fakeService = {
      imageName: allCMSKeys.AIRSHELLS_IMAGE,
      nameCmsKey: 'AIRSHELLS_NAME',
      infoCmsKey: 'AIRSHELLS_SHORT_DESCRIPTION',
      infoFullCmsKey: 'AIRSHELLS_INFO',
      isExternalService: true,
      extInfo: {
        link: allCMSKeys.AIRSHELLS_LINK,
      },
    };

    return currentServiceGroup === 'baggage' && appConfig.paxshopId === 11 ? (
      <FakeItem
        service={fakeService}
        openServiceModal={this.openServiceModal}
      />
    ) : null;
  }

  renderAirshellsCountryNote() {
    const { currentServiceGroup, localeData } = this.props;
    const { selectedResidencyCountry } = this.state;
    const isAirshells = currentServiceGroup === 'airshells';

    if (isAirshells) {
      return (
        <div className={this.element('airshellsNote')}>
          <Text as="p">CMS_AIRSHELLS_INSURANCE_FOR_NORDIC_ONLY</Text>
          <label style={{ display: 'block' }}>
            <Text fallback="Your country of residence">
              CMS_AIRSHELLS_YOUR_COUNTRY_OF_RESIDENCE
            </Text>
            <RawSelectInput
              options={['Norway', 'Finland', 'Sweden', 'Denmark', 'Other']}
              getOptionId={a => a}
              getOptionText={a => a}
              addEmptyOption
              placeholder={localeData.printCmsText(
                'CMS_AIRSHELLS_YOUR_COUNTRY_OF_RESIDENCE_PLACEHOLDER'
              )}
              meta={{
                touched: false,
                error: false,
                invalid: false,
              }}
              input={{
                value: selectedResidencyCountry,
                onChange: e =>
                  this.setState({ selectedResidencyCountry: e.target.value }),
              }}
            />
          </label>
        </div>
      );
    }
  }

  render() {
    const { services, currentPassenger } = this.props;
    const restrictedTags =
      !this.state.selectedResidencyCountry ||
      this.state.selectedResidencyCountry === 'Other'
        ? ['airshell_with_insurance']
        : [];

    const filteredServices = services
      .filter(svc => !svc.areSegmentsSoldOutAndUnavailable)
      .filter(service => isIntersectionEmpty(service.tags, restrictedTags));

    return filteredServices.length === 0 ? (
      <div className={this.block()}>
        <div className={this.element('empty')}>
          {currentPassenger
            ? `${currentPassenger.firstName} ${currentPassenger.lastName}`
            : ''}
          : <Text useSpan>SERVICES_EMPTY_LIST_TEXT</Text>
        </div>
      </div>
    ) : (
      <div className={this.block()}>
        {this.renderFakeServices()}
        {this.renderAirshellsCountryNote()}
        {filteredServices.map((service, i) => (
          <ServicesItem
            key={service.serviceId}
            service={service}
            openServiceModal={this.openServiceModal}
            addToBasket={this.addToBasketClicked}
          />
        ))}
      </div>
    );
  }
}

function isIntersectionEmpty(arr1, arr2) {
  for (let elem of arr2) {
    if (arr1.includes(elem)) {
      return false;
    }
  }

  return true;
}
