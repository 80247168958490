import React, { Component } from 'react';
import { connect } from 'react-redux';
import { allCMSKeysRawSelector } from 'languages/selectors';

@connect(state => ({
  cmsKeys: allCMSKeysRawSelector(state),
}))
export default class ServiceMoreInfoCheck extends Component {
  render() {
    const { cmsKeys, serviceInfoCms, charactersLimit, className } = this.props;

    const limit =
      charactersLimit && typeof charactersLimit === 'number'
        ? charactersLimit
        : 0;

    const isInfoShow = !!(
      cmsKeys[serviceInfoCms] && cmsKeys[serviceInfoCms].length > limit
    );

    const childrenLength = React.Children.count(this.props.children);

    const childrenMap = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { isInfoShow })
    );

    return childrenLength === 1 ? childrenMap[0] : <div>{childrenMap}</div>;
  }
}
