import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HomeContentWrapper from 'pageHome/components/homeContentWrapper';
import { anyToBackendError } from '_helpers/backendError';
import {
  createAuthFinishPromise,
  authFieldsSelector,
  selectIsBookingNumberMandatory,
} from 'authorization/selectors';
import { allCMSKeysRawSelector } from 'languages/selectors';
import * as nonBindedNorecActions from 'norec/actions';
import * as nonBindedAuthActions from 'authorization/actions';
import * as nonBindedBasketActions from 'basket/actions/index';
import { store } from 'store';
import PropTypes from 'prop-types';
import { IStoreState } from '../../types/store';
import { ICMSKeysMap } from '../../types/languages';

interface IProps {
  cmsKeys: ICMSKeysMap;
  authActions: typeof nonBindedAuthActions;
  norecActions: typeof nonBindedNorecActions;
  basketActions: typeof nonBindedBasketActions;
  authFields: { [key: string]: boolean };
  isBookingNumberMandatory: boolean;
}

const connectPageHome = connect(
  (state: IStoreState) => ({
    cmsKeys: allCMSKeysRawSelector(state),
    authData: state.auth.authData.data,
    myBookingAsIcon: state.init.paxshopInitParams.myBookingAsIcon,
    authFields: authFieldsSelector(state),
    isBookingNumberMandatory: selectIsBookingNumberMandatory(state),
  }),
  dispatch => ({
    dispatch,
    norecActions: bindActionCreators(nonBindedNorecActions, dispatch),
    authActions: bindActionCreators(nonBindedAuthActions, dispatch),
    basketActions: bindActionCreators(nonBindedBasketActions, dispatch),
  })
);

class PageHome extends React.Component<IProps, { norecErrorCounter: number }> {
  static contextTypes = {
    showTextInfoDialog: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      norecErrorCounter: 0,
    };
  }

  login = values =>
    new Promise((resolve, reject) => {
      const { authActions, authFields, isBookingNumberMandatory } = this.props;

      const shouldShowNorecCreation =
        !authFields.bookingNumber ||
        (!values.bookingNumber && !isBookingNumberMandatory);

      if (shouldShowNorecCreation) {
        this.showNorecModal('', values.departureDate, undefined);
        reject();
        return;
      }

      authActions.initiateLogin({
        authType: 'BOOKING',
        bookingSrc: values,
      });

      createAuthFinishPromise(store).then(resolve, err => {
        const backendErr = anyToBackendError(err);
        reject();
        this.errorAuth(backendErr, values);
      });
    });

  showHowtoModal = () => {
    const { showTextInfoDialog } = this.context;
    showTextInfoDialog(
      'info',
      'FAQ_EXTRA_HEADER',
      'FAQ_EXTRA_TEXT',
      'FAQ_EXTRA_BUTTON_TEXT'
    );
  };

  showNorecModal(bookingNumber, departureDate, lastName) {
    const { norecActions, basketActions } = this.props;

    norecActions.showNorecDialog(bookingNumber, departureDate, lastName);
    basketActions.clearBasket();
  }

  errorAuth(error, formValues) {
    if (error.data.errorCode === 'MORE_THAN_16_PASSENGERS') {
      this.context.showTextInfoDialog(
        'info',
        'CMS_MORE_THAN_16_PASSENGERS_TITLE',
        'CMS_MORE_THAN_16_PASSENGERS_CONTENT',
        'NEXT_BUTTON'
      );
      return;
    }
    if (
      error.errorKind === 'ERROR_STRUCTURED' &&
      error.data.errorCode === 'NOT_FOUND_BUT_NOREC_SUPPORTED'
    ) {
      this.setState(prevState => ({
        norecErrorCounter: prevState.norecErrorCounter + 1,
      }));

      if (this.state.norecErrorCounter === 2) {
        this.setState({ norecErrorCounter: 0 });
        this.showNorecModal(
          formValues.bookingNumber,
          formValues.departureDate,
          formValues.lastName
        );
      } else {
        this.showMiniNorecModal();
      }
    } else {
      const { showTextInfoDialog } = this.context;
      showTextInfoDialog(
        'error',
        'LOGIN_INVALID_CREDENTIALS_TITLE',
        'LOGIN_TEXT_ERROR'
      );
    }
  }

  showMiniNorecModal() {
    const { showTextInfoDialog } = this.context;
    showTextInfoDialog(
      'info',
      'LOGIN_INVALID_CREDENTIALS_TITLE',
      'LOGIN_TEXT_ERROR',
      'NEXT_BUTTON'
    );
  }

  render() {
    return (
      <div className="home">
        <HomeContentWrapper
          login={this.login}
          showHowtoModal={this.showHowtoModal}
        />
      </div>
    );
  }
}

export default connectPageHome(PageHome);
