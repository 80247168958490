import React from 'react';
import bem from 'react-bem-classes';
import languages from '../../../../languages.json';
import './index.scss';

import be from './images/be.svg';
import da from './images/dk.svg';
import de from './images/de.svg';
import en from './images/gb.svg';
import es from './images/es.svg';
import et from './images/ee.svg';
import fi from './images/fi.svg';
import fr from './images/fr.svg';
import it from './images/it.svg';
import lv from './images/lv.svg';
import nl from './images/nl.svg';
import no from './images/no.svg';
import pl from './images/pl.svg';
import ru from './images/ru.svg';
import sv from './images/se.svg';

const FLAG_BY_LANGUAGE_CODE = {
  be,
  da,
  de,
  en,
  es,
  et,
  fi,
  fr,
  it,
  lv,
  nl,
  no,
  pl,
  ru,
  sv,
};

// Runtime check that all flags for languages are defined
languages.forEach(language => {
  if (!FLAG_BY_LANGUAGE_CODE[language]) {
    throw new Error(`Flag for language ${language} is not added`);
  }
});

@bem({
  block: 'flagIcon',
})
class FlagIcon extends React.Component {
  render() {
    if (!this.props.code) return null;

    const style = {
      backgroundImage: `url("${FLAG_BY_LANGUAGE_CODE[this.props.code]}") `,
    };

    return <i style={style} className={this.block()} />;
  }
}

export default FlagIcon;
