import React, { PropsWithChildren, useState } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Bem } from 'react-bem-classes';
import { IFabService } from 'types/services';
import { IStoreState } from 'types/store';
import { T } from 'languages/components/text';
import {
  selectIfServiceInBasketForAllPassengers,
  selectServicesOfNumGroupInBasket,
} from 'basket/selectors';
import { selectIfServiceBoughtForAllPassengers } from 'payment/selectors';
import { DescriptionModal } from '../DescriptionModal';
import './index.scss';

const bem = new Bem('fabServiceItem');

function getPrice(service: IFabService) {
  const cumulativePrice = service.eligiblePassengers
    .reduce((sum, eligiblePassenger) => sum + eligiblePassenger.price, 0)
    .toFixed(2);
  const { currency } = service.eligiblePassengers[0];

  return `${cumulativePrice} ${currency}`;
}

function BuyForAllPassengersButton({
  service,
  onClick,
}: {
  service: IFabService;
  onClick: () => void;
}) {
  return (
    <button onClick={onClick} type="button" className={bem.element('button')}>
      <T>ADD_BUTTON_TEXT</T> {getPrice(service)}
    </button>
  );
}

function ReplaceWithAnotherButton({
  service,
  onClick,
}: {
  service: IFabService;
  onClick: () => void;
}) {
  return (
    <button onClick={onClick} type="button" className={bem.element('button')}>
      <T>BTN_REPLACE_WITH_OTHER</T> {getPrice(service)}
    </button>
  );
}

function InactiveButton({ children }: PropsWithChildren<{}>) {
  return (
    <button disabled type="button" className={bem.element('button')}>
      {children}
    </button>
  );
}

type OwnProps = {
  className?: string;
  service: IFabService;
  onAdd: () => void;
  onReplace: () => void;
};

const mapStateToProps = (state: IStoreState, props: OwnProps) => ({
  isInBasket: selectIfServiceInBasketForAllPassengers(state, props.service),
  isBought: selectIfServiceBoughtForAllPassengers(state, props.service),
  otherAddedLounges: selectServicesOfNumGroupInBasket(
    state,
    props.service.uniqueNumGroup
  ),
});

function getServiceStatus(
  service: IFabService,
  isBought: boolean,
  isInBasket: boolean,
  isOtherLoungesAdded: boolean
) {
  if (isBought) return 'bought';
  if (isInBasket) return 'chosen';
  if (service.eligiblePassengers.length === 0) return 'unavailable';
  if (isOtherLoungesAdded) return 'same';
  return 'available';
}

function FabServiceItem(props: ReturnType<typeof mapStateToProps> & OwnProps) {
  const {
    className,
    service,
    onAdd,
    onReplace,
    isInBasket,
    isBought,
    otherAddedLounges,
  } = props;

  const [detailsOpened, setDetailsOpened] = useState(false);

  const actionElement = {
    bought: (
      <InactiveButton>
        <T>SERVICE_ALREADY_BOUGHT</T>
      </InactiveButton>
    ),
    chosen: (
      <InactiveButton>
        <T>ADDED_BUTTON_TEXT</T>
      </InactiveButton>
    ),
    unavailable: (
      <InactiveButton>
        <T>NOT_AVAILABLE_FOR_FLIGHT</T>
      </InactiveButton>
    ),
    same: <ReplaceWithAnotherButton onClick={onReplace} service={service} />,
    available: <BuyForAllPassengersButton onClick={onAdd} service={service} />,
  }[
    getServiceStatus(
      service,
      isBought,
      isInBasket,
      otherAddedLounges.length > 0
    )
  ];

  const openDescription = () => setDetailsOpened(true);

  return (
    <article className={cn(bem.block(), className)}>
      <div onClick={openDescription} className={bem.element('imageWrapper')}>
        <img
          className={bem.element('image')}
          src={service.imageData.url}
          width={service.imageData.width}
          height={service.imageData.height}
          alt={service.name}
        />
      </div>
      <div className={bem.element('content')}>
        <header onClick={openDescription} className={bem.element('header')}>
          {service.name}
        </header>
        <p className={bem.element('description')}>{service.text}</p>
        <button
          onClick={openDescription}
          type="button"
          className={bem.element('readMoreButton')}
        >
          <T>SERVICE_DETAILS_READ_MORE</T>
        </button>
        {actionElement}
      </div>
      {detailsOpened && (
        <DescriptionModal
          service={service}
          onClose={() => setDetailsOpened(false)}
        >
          {actionElement}
        </DescriptionModal>
      )}
    </article>
  );
}

export const ConnectedFabServiceItem = connect(mapStateToProps)(FabServiceItem);
