import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { IServiceFromApi } from 'types/services';
import {
  currentBookingNumberSelector,
  selectIsAutoAllocationAllowed,
} from 'authorization/selectors';
import { passengersForSeatingPageSelector } from 'passengers/helpers/selectors';
import { allCMSKeysRawSelector, localeDataSelector } from 'languages/selectors';
import { IStoreState } from 'types/store';
import { selectAllServices } from 'services/selectors';
import {
  currentSegmentSelector,
  segmentsSelector,
} from '../../segments/helpers/selectors';
import { selectSuggestedSeatsForSegment } from '../selectors';
import {
  chosenSeatsSelectorForCurrentSegment,
  haveAvailabilityToSelectSeatsForCurrentSegment,
  haveAvailabilityToSelectSeatsForOtherSegment,
  IsSeatsBought,
} from '../../seatingWidget/helpers/selectors';
import {
  seatValidationErrorSelector,
  selectHasSelectedSeatsForCurrentSegment,
  ssrsAffectToSeatsServicesSelector,
} from '../../serviceInBasket/helpers/selectors';
import { selectUpToDateBasket } from '../../basket/selectors/selectBasket';

const selectSeatingServicesFromWidget = (state: IStoreState) =>
  state.seating.widgetInfo.seatsServices;

const seatServicesSelector = createSelector<
  IStoreState,
  ReturnType<typeof selectAllServices>,
  ReturnType<typeof selectSeatingServicesFromWidget>,
  ReturnType<typeof currentSegmentSelector>,
  IServiceFromApi[]
>(
  selectAllServices,
  selectSeatingServicesFromWidget,
  currentSegmentSelector,
  (services, seatServices, currentSegment) =>
    services.filter(
      service =>
        service.tags.includes('seating') &&
        seatServices.find(i => i.id === service.serviceId) &&
        service.segmentId === currentSegment.segmentId
    )
);

export default connect((state: IStoreState) => {
  const currentSegment = currentSegmentSelector(state);
  const suggestedSeats = selectSuggestedSeatsForSegment(
    state,
    currentSegment.segmentId
  );

  return {
    allInfantsHaveRef:
      state.auth.authData.data &&
      state.auth.authData.data.booking.infantRefInfo.allInfantsHaveRef,
    autoAllocationAllowed: selectIsAutoAllocationAllowed(state),
    passengers: passengersForSeatingPageSelector(state),
    currentPassengerId: state.passengers.currentPassengerId,
    chosenSeats: chosenSeatsSelectorForCurrentSegment(state),
    isSeatsBought: IsSeatsBought(state),
    suggestedSeats,
    haveAvailabilityToSelectSeatsForCurrentSegment: haveAvailabilityToSelectSeatsForCurrentSegment(
      state
    ),
    haveAvailabilityToSelectSeatsForOtherSegment: haveAvailabilityToSelectSeatsForOtherSegment(
      state
    ),
    hasSelectedSeatsForSegment: selectHasSelectedSeatsForCurrentSegment(state),
    segments: segmentsSelector(state),
    currentSegment,
    currentSegmentId: currentSegment.segmentId,
    bookingNumber: currentBookingNumberSelector(state),
    basket: selectUpToDateBasket(state),
    serviceList: selectAllServices(state),
    seatServices: seatServicesSelector(state),
    cmsKeys: allCMSKeysRawSelector(state),
    localeData: localeDataSelector(state),
    seatingErrors: seatValidationErrorSelector(state),
    widgetInfoLoading: state.seating.widgetInfo.loading,
    isValidationPending:
      state.seating.seatSetValidation.validationProcess.validating,
    forceLoadCounter: state.seating.widgetInfo.forceLoadCounter,
    ssrsAffectToSeatsServices: ssrsAffectToSeatsServicesSelector(state),
    seatingPageMoreInfoIsOpened:
      state.init.paxshopData.data.seatingPageMoreInfoIsOpened,
  };
});
