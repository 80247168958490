import * as types from '../constants/PlaneActionTypes';

const defaultState = {
  backdrop: '',
  seatMap: [],
};

export default function aircraft(state = defaultState, action) {
  switch (action.type) {
    case types.FETCH_CONFIGURATION:
      const {
        aircraft,
        definitions: { seatmap },
      } = action.res.data;
      return {
        backdrop: aircraft.decks[0].backdrop_id,
        seatMap: seatmap,
      };
    default:
      return state;
  }
}
