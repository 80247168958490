import React from 'react';

export default class Price extends React.Component {
  render() {
    const { price, currency, ...rest } = this.props;

    switch (currency) {
      case 'GBP':
        return <span {...rest}>£{price.toFixed(2)}</span>;
      default:
        return (
          <span {...rest}>
            {price.toFixed(2)} {currency}
          </span>
        );
    }
  }
}
