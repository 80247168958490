export default function matcher(input, equal, field) {
  var returnArray = [];
  if (input && input.length) {
    for (var i = 0; i < input.length; i++) {
      if (field && field != undefined && input[i][field] == equal) {
        returnArray.push(input[i]);
      } else if (input[i] == equal) {
        returnArray.push(input[i]);
      }
    }
  }
  return returnArray;
}
