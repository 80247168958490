import * as types from '../constants/PlaneActionTypes';

const defaultState = new Map();

export default function servicesReducer(state = defaultState, action) {
  switch (action.type) {
    case types.FETCH_ALLOCATION:
      const services = action.res.data.definitions.services;
      return new Map(services.map(type => [type.id, type]));
    default:
      return state;
  }
}
