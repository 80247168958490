import React, { useCallback, useReducer, useRef, useState } from 'react';
import { Bem } from 'react-bem-classes';
import { Text } from 'languages/components/text';
import { AddingToBasket } from 'services/components/AddingToBasket';
import { useLocale } from 'languages/_helpers/localeContext';
import { IActiveServiceWithOfferStatus, IApiService } from 'types/services';
import { IEnhancedSegment } from 'types/segments';
import './index.scss';

const bem = new Bem('servicesItem');

interface IServiceSegment
  extends IEnhancedSegment,
    IActiveServiceWithOfferStatus {}

type IService = IApiService & {
  outboundSegment: IServiceSegment;
  inboundSegment: IServiceSegment;
  isExternalService: boolean;
};

interface IProps {
  service: IService;
  addToBasket(services: any[]): void;
  openServiceModal: any;
}

/*
 * Because we need to pass the value to the modal
 * which is not connected to component after creation
 * we want to have a possibility to pass getter
 * which will return the correct value between renders
 *
 * This way we simulate a class component behaviour,
 * when changing state we also synchronizing it with
 * mutable ref, and return a getter to it
 *
 * Anyway, we don't give a possibility to mutate it outside
 */
function useToggleWithGetter(
  initialValue: boolean
): [boolean, () => void, () => boolean] {
  const ref = useRef(initialValue);
  const [state, toggle] = useReducer((prevState: boolean) => {
    ref.current = !prevState;
    return !prevState;
  }, initialValue);

  const getter = useCallback(() => ref.current, []);

  return [state, toggle, getter];
}

export function ServicesItem(props: IProps) {
  const [
    inboundSegment,
    toggleInboundSegment,
    getInboundSegment,
  ] = useToggleWithGetter(false);
  const [
    outboundSegment,
    toggleOutboundSegment,
    getOutboundSegment,
  ] = useToggleWithGetter(false);

  const { isFilled } = useLocale();
  const { service, addToBasket, openServiceModal } = props;

  const openServicePopup = () => {
    openServiceModal(service, {
      getInboundSegment,
      getOutboundSegment,
      toggleInboundSegmentCheckbox: toggleInboundSegment,
      toggleOutboundSegmentCheckbox: toggleOutboundSegment,
    });
  };

  const imageUrl = service.imageName;
  const imageNameSuffix = imageUrl.split('.').reverse()[1];
  const isPhoto = imageNameSuffix === 'big' || service.supplier === 'fab';

  const imageStyle = {
    backgroundImage: `url(${service.imageName})`,
  };

  return (
    <div onClick={openServicePopup} className={bem.block()}>
      <div
        className={bem.element('image', { photo: isPhoto })}
        style={imageStyle}
      />
      <div className={bem.element('body')}>
        {service.nameCmsKey ? (
          <Text className={bem.element('title')}>{service.nameCmsKey}</Text>
        ) : (
          <header className={bem.element('title')}>{service.name}</header>
        )}

        <Text useSpan className={bem.element('readMoreMobile')}>
          SERVICE_DETAILS_READ_MORE
        </Text>

        {service.infoCmsKey && isFilled(service.infoCmsKey, false) && (
          <div className={bem.element('info')}>
            <Text deleteImage className={bem.element('serviceDescription')}>
              {service.infoCmsKey}
            </Text>
            <Text useSpan className={bem.element('readMore')}>
              SERVICE_DETAILS_READ_MORE
            </Text>
          </div>
        )}

        {!service.infoCmsKey && service.text && (
          <div className={bem.element('info')}>
            <p className={bem.element('serviceDescription')}>{service.text}</p>
            <Text useSpan className={bem.element('readMore')}>
              SERVICE_DETAILS_READ_MORE
            </Text>
          </div>
        )}

        <AddingToBasket
          service={service}
          handleAdd={addToBasket}
          toggleInboundSegmentCheckbox={toggleInboundSegment}
          toggleOutboundSegmentCheckbox={toggleOutboundSegment}
          getInboundSegment={() => inboundSegment}
          getOutboundSegment={() => outboundSegment}
        />
      </div>
    </div>
  );
}
