import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Bem } from 'react-bem-classes';
import onClickOutside from 'react-onclickoutside';
import 'react-day-picker/lib/style.css';
import './index.scss';
import moment from 'moment/moment';
import { haveNativeDatepicker } from '_helpers/nativeDatepickerSupport';

const NATIVE_DATEPICKER_FORMAT = 'YYYY-MM-DD';

const formatDate = formatStr => date => {
  if (!date) {
    return undefined;
  }
  return moment(date)
    .format(formatStr.toUpperCase())
    .toString();
};
const parseDate = formatStr => dateStr => {
  if (!dateStr || !moment(dateStr, formatStr.toUpperCase(), true).isValid()) {
    return undefined;
  }
  return moment(dateStr, formatStr.toUpperCase()).toDate();
};

const dayPickerPopupBem = new Bem('dayPickerPopup');
class DayPickerPopup extends React.Component {
  handleClickOutside() {
    this.props.input.props.onKeyUp({ keyCode: 27 });
  }

  render() {
    const { input, children } = this.props;
    return (
      <div
        className={dayPickerPopupBem.block({
          shiftToTop: input.props.shiftToTop,
          anchorToParent: input.props.anchorToParent,
        })}
      >
        {input.props.showHeader && (
          <div className={dayPickerPopupBem.element('header')}>
            <div className={dayPickerPopupBem.element('title')}>
              {input.props.headerTitle}
            </div>
            <div
              className={dayPickerPopupBem.element('closeButton')}
              onClick={() => input.props.onKeyUp({ keyCode: 27 })}
            >
              <i className="icon close-light" />
            </div>
          </div>
        )}
        {children}
      </div>
    );
  }
}
const DayPickerPopupEnchanced = onClickOutside(DayPickerPopup);

const dateInputWithBtnBem = new Bem('dateInputWithBtn');

class DateInputWithBtn extends React.Component {
  handleChange(e) {
    this.props.textChange(e);
    this.props.onChange(e);
  }

  handleCalendarClick() {
    if (!this.props.disabled) {
      this.props.onFocus();
    }
  }

  render() {
    let {
      value,
      placeholder,
      textValue,
      name,
      onBlur,
      anchorToParent,
      disabled,
      small,
    } = this.props;
    if (value === '') {
      value = textValue;
    }
    return (
      <div className={dateInputWithBtnBem.block({ anchorToParent, small })}>
        <input
          type="text"
          name={name}
          className={dateInputWithBtnBem.element('text')}
          onChange={e => this.handleChange(e)}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
        />
        <div
          className={dateInputWithBtnBem.element('pickerButton')}
          onClick={() => this.handleCalendarClick()}
        >
          <i className="icon calendar pr pl" />
        </div>
      </div>
    );
  }
}

export class NativeDatePickerInput extends React.Component {
  handleNativeChange(e) {
    e.preventDefault();
    const newDate = moment(e.target.value, NATIVE_DATEPICKER_FORMAT).toDate();
    this.props.onChange(newDate);
  }

  handleInputChange(e) {
    e.preventDefault();
    const newDate = parseDate(e.target.value);
    this.props.onChange(newDate);
  }

  render() {
    console.log('in native picker', this.props.disabled);
    const { value, placeholder } = this.props;

    const displayValue = value
      ? formatDate(this.props.dateFormatStr)(value)
      : '';
    const nativeValue = value
      ? formatDate(NATIVE_DATEPICKER_FORMAT)(value)
      : '';

    return (
      <div className={dateInputWithBtnBem.block()}>
        <input
          type="text"
          className={dateInputWithBtnBem.element('text')}
          onChange={e => this.handleInputChange(e)}
          value={displayValue}
          placeholder={placeholder}
          disabled={this.props.disabled}
        />
        <input
          type="date"
          className={dateInputWithBtnBem.element('nativeDatepicker')}
          onChange={e => this.handleNativeChange(e)}
          value={nativeValue}
          placeholder={placeholder}
          disabled={this.props.disabled}
        />
        <div className={dateInputWithBtnBem.element('pickerButton')}>
          <i className="icon calendar pr pl" />
        </div>
      </div>
    );
  }
}

export class ReactDayPickerInput extends React.Component {
  render() {
    const {
      headerTitle,
      localeUtils,
      value,
      onChange,
      onBlur,
      placeholder,
      dateFormatStr,
      textChange,
      textValue,
      name,
      shiftToTop,
      anchorToParent,
      disabled,
      showHeader,
      small,
    } = this.props;
    return (
      <DayPickerInput
        component={DateInputWithBtn}
        overlayComponent={DayPickerPopupEnchanced}
        formatDate={formatDate(dateFormatStr)}
        parseDate={parseDate(dateFormatStr)}
        dayPickerProps={{ localeUtils }}
        inputProps={{
          headerTitle,
          placeholder,
          textChange: e => textChange(e.target.value),
          textValue,
          name,
          disabled,
          shiftToTop,
          anchorToParent,
          onBlur,
          showHeader,
          small,
        }}
        onDayChange={onChange}
        value={value}
      />
    );
  }
}

export class DateInput extends React.Component {
  constructor(props) {
    super(props);
    this.internalOnChange = this.internalOnChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  internalOnChange(value) {
    const returnValue = formatDate(this.props.dateFormatStr)(value);
    this.props.input.onChange(returnValue);
  }

  handleTextChange(value) {
    this.props.input.onChange(value);
  }

  render() {
    const {
      isNativePicker,
      input,
      meta: { touched, error, invalid, active },
      disabled,
      showHeader,
      shiftToTop,
      anchorToParent,
      getErrorMsg,
      small,
      ...other
    } = this.props;

    const shouldDisplayError = touched && invalid;

    const internalValue = parseDate(this.props.dateFormatStr)(input.value);

    const textValue = input.value;

    const name = input.name;

    let hasNativeDatePicker = isNativePicker;
    if (isNativePicker === undefined) {
      hasNativeDatePicker = haveNativeDatepicker;
    }

    const dateInputBem = new Bem('DateInput');
    return (
      <div
        className={dateInputBem.block({
          shiftToTop,
          anchorToParent: anchorToParent && !shiftToTop,
        })}
      >
        {hasNativeDatePicker ? (
          <NativeDatePickerInput
            onChange={this.internalOnChange}
            textChange={this.handleTextChange}
            textValue={textValue}
            value={internalValue}
            name={name}
            disabled={disabled}
            showHeader={showHeader}
            onBlur={input.onBlur}
            {...other}
          />
        ) : (
          <ReactDayPickerInput
            onChange={this.internalOnChange}
            textChange={this.handleTextChange}
            textValue={textValue}
            value={internalValue}
            name={name}
            shiftToTop={shiftToTop}
            anchorToParent={anchorToParent && !shiftToTop}
            disabled={disabled}
            showHeader={showHeader}
            onBlur={input.onBlur}
            small={small}
            {...other}
          />
        )}
        <div className={dateInputWithBtnBem.element('error')}>
          {shouldDisplayError && error ? getErrorMsg(error) : ' '}
        </div>
      </div>
    );
  }
}
