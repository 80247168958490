window.PSW = {};

// var config = {
//   flightNumber: 'BLX123',
//   departureDate: '2015-12-13',
//   bookingNumber: 'Q33NY'
// }

PSW.init = config => {
  if (typeof config !== 'undefined') {
    console.log('Config passed!');
  }
};
PSW.onLoad = callback => {
  if (typeof callback === 'function') {
    callback();
  }
};
PSW.onSeatClick = callback => {
  if (typeof callback === 'function') {
    callback();
  }
};
PSW.onPassengerSwitch = callback => {
  if (typeof callback === 'function') {
    callback();
  }
};
