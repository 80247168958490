import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  fetchConfiguration,
  fetchAllocation,
  changeLoadStatus,
} from '../../actions/planeActions';
import SeatMap from '../../components/seatMap';
import { toggleSeat } from '../../actions/seatActions';
import './app.css';

let touchSupport = false;
if (
  'ontouchstart' in window ||
  (window.DocumentTouch && document instanceof DocumentTouch)
) {
  touchSupport = true;
}

@connect(
  state => ({
    seatsMap: state.seatsMap,
    seatTypes: state.seatTypes,
    seatImages: state.seatImages,
    seatsServices: state.services,
    seatsAllocation: state.seatAllocations,
    passengers: state.passengers.list,
    activePassenger: state.passengers.current,
    seats: state.seats,
    suggestedSeats: state.suggestedSeats,
    loaded: state.mainReducer.loaded,
    context: state.mainReducer.context,
    aircraftDecs: state.mainReducer.aircraftDecs,
    selectionLocked: state.selectionLock.locked,
  }),
  dispatch => ({
    fetchConfiguration: bindActionCreators(fetchConfiguration, dispatch),
    fetchAllocation: bindActionCreators(fetchAllocation, dispatch),
    changeLoadStatus: bindActionCreators(changeLoadStatus, dispatch),
    dispatch,
  })
)
export default class App extends Component {
  componentDidMount() {
    const {
        fetchConfiguration,
        fetchAllocation,
        changeLoadStatus,
        widgetConfig,
      } = this.props,
      occupiedSeats = widgetConfig.occupiedSeats || [];

    fetchConfiguration(
      `/${widgetConfig.flightNumber}/${widgetConfig.departureDate}/configuration`
    )
      .then(function() {
        return fetchAllocation(
          `/booking/${widgetConfig.bookingNumber}/${widgetConfig.departureDate}/allocation?direction=${widgetConfig.direction}&flight=${widgetConfig.flightNumber}`,
          occupiedSeats,
          widgetConfig.ssrs
        );
      })
      .then(function() {
        changeLoadStatus(true);
      });
  }

  getSeatsMapForCurrentPassenger() {
    const {
      seatsMap,
      seatTypes,
      seatImages,
      seatsAllocation,
      activePassenger,
      seats,
      suggestedSeats,
      seatsServices,
      passengers,
      selectionLocked,
    } = this.props;

    return Array.from(seatsMap, seat => seat[1]).map(
      ({ id, image_ref, seat_type_ref, position, ...rest }) => {
        const eligible_passengers = seatsAllocation.size
          ? seatsAllocation.get(id).eligible_passengers || []
          : [];
        const seatAllocation = seatsAllocation.get(id) || {};
        const seatService = seatsServices.get(seatAllocation.service_ref) || {};
        const selectForPassengerById = (
          seats.find(s => s.seatNumber === id) || []
        ).passengerId;
        const suggestion = suggestedSeats.find(
          suggestedSeat => suggestedSeat.seatNumber === id
        );

        return {
          id,
          display: {
            ...seatTypes.get(seat_type_ref),
            image: seatImages.get(image_ref),
            image_ref,
            ...position,
          },
          service: seatAllocation
            ? seatsServices.get(seatAllocation.service_ref)
            : null,
          price: (seatService.prices || []).find(
            p => p.passengerId === activePassenger.id
          ),
          seatProperties: seatAllocation.seat_properties,
          isBusy: !!selectForPassengerById,
          suggestedFor: suggestion
            ? passengers.get(suggestion.passengerId)
            : null,
          isBusyForCurrentUser: !!seats.find(
            s => s.seatNumber === id && s.passengerId === activePassenger.id
          ),
          passenger: !!selectForPassengerById
            ? passengers.get(selectForPassengerById)
            : {},
          availability:
            !!activePassenger &&
            eligible_passengers.indexOf(activePassenger.id) !== -1,
          isAllowed:
            seatsAllocation.size !== 0 &&
            eligible_passengers.length !== 0 &&
            !selectionLocked,
          allocation: seatAllocation.allocation || {},
          ...rest,
        };
      }
    );
  }

  onSeatClick(seat) {
    this.props.dispatch(toggleSeat(seat, this.props.activePassenger.id));
  }

  render() {
    const { aircraftDecs, context, loaded, selectionLocked } = this.props;

    const seatMap = this.getSeatsMapForCurrentPassenger();
    let events = touchSupport
      ? {
          onTouchEnd: e => {
            const openSeatInfo = document.querySelector('.psw-seatInfo');
            if (!!openSeatInfo) {
              openSeatInfo.style.display = 'none';
            }
          },
        }
      : {};

    return (
      <div className="psw-app">
        {
          <div className="psw-aircraft" {...events}>
            {aircraftDecs.length > 0 && (
              <div className="psw-backdrop">
                {aircraftDecs.map((deck, i) => (
                  <img
                    src={deck.backdrop_id}
                    alt="plane"
                    className="psw-backdrop__img"
                    key={i}
                  />
                ))}
              </div>
            )}
            {seatMap.length > 0 && context.length !== 0 && loaded && (
              <div className="psw-seatmap">
                <SeatMap
                  {...this.props}
                  selectionLocked={selectionLocked}
                  seatMap={seatMap}
                  onSeatClick={this.onSeatClick.bind(this)}
                />
              </div>
            )}
          </div>
        }
        <div className={loaded ? 'psw-loader' : 'psw-loader process'} />
      </div>
    );
  }
}
