import React from 'react';
import { appConfig } from 'config';
import Page, { Content } from 'layouts/page';
import { Text } from 'languages/components/text';
import { connect } from 'react-redux';
import { firstFlightDepartureAirportSelector } from 'segments/helpers/selectors';
import { PassengerSelector } from 'passengers/containers/selector';
import { ServicesContent } from 'services/containers/servicesContent';
import { VaziloBanner } from 'services/components/vaziloBanner';
import Columns from 'layouts/pageColumns';
import Cloud, { CloudTitle } from 'layouts/pageCloud';
import { CurrentPageWizardHeader } from 'mainMenu/containers/currentPageWizardHeader';
import { authOnly } from 'authorization/helpers/authOnly';
import PropTypes from 'prop-types';
import GroupsSelector from '../../services/components/groups';

@authOnly()
@connect(state => ({
  currentSegmentId: state.segments.currentSegmentId,
  firstAirport: firstFlightDepartureAirportSelector(state),
}))
export class PageBaggage extends React.Component {
  static contextTypes = {
    showValizoBannerModal: PropTypes.func.isRequired,
  };

  render() {
    const { store, currentSegmentId, firstAirport } = this.props;

    const showValizoBanner =
      appConfig.showValizoMVPBanner &&
      appConfig.showValizoMVPBannerIfAirportCodes.includes(firstAirport.code);

    return (
      <Page>
        <CurrentPageWizardHeader />

        <Content mobileFullWidth>
          <Columns
            fullWidthBreakPoint={LAYOUT_BREAKS.landscapeTablet}
            stickyPassengersSelector
          >
            <div>
              <Cloud>
                <CloudTitle>
                  <Text>LIST_OF_PASSENGERS</Text>
                </CloudTitle>
                <PassengerSelector
                  key={currentSegmentId}
                  mobileBreakpoint={LAYOUT_BREAKS.landscapeTablet}
                  adultsFirst
                />
              </Cloud>
            </div>
            <div>
              {showValizoBanner && (
                <VaziloBanner
                  showVaziloBannerModal={this.context.showValizoBannerModal}
                />
              )}
              <GroupsSelector primaryGroup="baggage" />
              <ServicesContent otherGroups={['baggage', 'airshells']} />
            </div>
          </Columns>
        </Content>
      </Page>
    );
  }
}
