import React, { useState } from 'react';
import { Bem } from 'react-bem-classes';
import { Text } from 'languages/components/text';
import Collapse from 'react-collapse';
import { connect } from 'react-redux';
import './index.scss';
import { IStoreState } from 'types/store';
import { allCMSKeysRawSelector } from 'languages/selectors';
import { ICMSKeysMap } from 'types/languages';

const bem = new Bem('seatingPageInfo');

interface IStoreProps {
  allCMSKeys: ICMSKeysMap;
}

interface IOwnProps {
  seatingPageMoreInfoIsOpened: boolean;
}

function SeatingPageInfo({
  seatingPageMoreInfoIsOpened,
  allCMSKeys,
}: IStoreProps & IOwnProps) {
  const [isOpened, setIsOpened] = useState(seatingPageMoreInfoIsOpened);

  const hasNoteText =
    allCMSKeys.SEATING_PAGE_NOTE_1 || allCMSKeys.SEATING_PAGE_NOTE_2;

  return (
    <div className={bem.block({ isOpened, hide: !hasNoteText })}>
      <Text
        onClick={() => setIsOpened(!isOpened)}
        className={bem.element('title')}
      >
        SEATING_PAGE_NOTE_HEAD
      </Text>
      <Collapse isOpened={isOpened} className={bem.element('contentWrapper')}>
        <div className={bem.element('content')}>
          <Text>SEATING_PAGE_NOTE_1</Text>
          <Text>SEATING_PAGE_NOTE_2</Text>
        </div>
      </Collapse>
    </div>
  );
}

export default connect((state: IStoreState) => ({
  allCMSKeys: allCMSKeysRawSelector(state),
}))(SeatingPageInfo);
