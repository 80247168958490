import { IFabService, IServiceInBasket } from '../../../../types/services';
import {
  addServicesToBasket,
  removeServicesFromBasket,
} from '../../../../basket/actions';

const addHandlerCache = new Map<string, () => void>();

/*
 * Returns stable reference for handler to concrete service
 */
export function getAddHandlerForService(
  service: IFabService,
  direction: 'IN' | 'OUT',
  addToBasket: typeof addServicesToBasket
) {
  if (!addHandlerCache.has(service.serviceId)) {
    addHandlerCache.set(service.serviceId, () => {
      addToBasket(
        service.eligiblePassengers.map(passenger => ({
          ...service,
          passengerId: passenger.passengerId,
          addedAs: 'lounges',
          direction,
        }))
      );
    });
  }

  return addHandlerCache.get(service.serviceId);
}

export function getReplaceHandlerForService(
  newService: IFabService,
  direction: 'IN' | 'OUT',
  loungesToRemove: IServiceInBasket[],
  addToBasket: typeof addServicesToBasket,
  removeFromBasket: typeof removeServicesFromBasket
) {
  return () => {
    removeFromBasket(loungesToRemove);
    addToBasket(
      newService.eligiblePassengers.map(passenger => ({
        ...newService,
        passengerId: passenger.passengerId,
        addedAs: 'lounges',
        direction,
      }))
    );
  };
}
