import './index.scss';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Bem } from 'react-bem-classes';
import { localeDataSelector } from 'languages/selectors';
import { DisplayDate, DisplayTime } from '_helpers/displayDate';
import { selectCheckInSegments } from 'check-in/helpers/selectors';
import { selectIsAutoAllocationAllowed } from 'authorization/selectors';
import { IStoreState } from 'types/store';
import { TeaserShownReasons } from 'types/checkIn';
import { T } from 'languages/components/text';

const mapStateToProps = (state: IStoreState) => ({
    checkIn: selectCheckInSegments(state).find(
        c => c.segmentId === state.segments.currentSegmentId
    ),
    currentSegmentId: state.segments.currentSegmentId,
    suggestedSeats: state.seatingReducer.suggestedSeats,
    localeData: localeDataSelector(state),
    isAutoAllocationAllowed: selectIsAutoAllocationAllowed(state),
});

interface IProps extends ReturnType<typeof mapStateToProps> {}

class CheckinTeaserComponent extends React.Component<
  IProps,
  { closed: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { closed: false };
  }

  onCloseClicked = () => {
    this.setState({ closed: true });
  };

  renderDateAndTime(): JSX.Element {
    const { checkIn, localeData } = this.props;
    const date = moment(checkIn.teaser.checkinOpenAt).add(
      -new Date().getTimezoneOffset(),
      'minutes'
    );

    return (
      <span>
        &nbsp;
        <DisplayDate inline date={date} localeData={localeData} />
        <DisplayTime inline date={date} utc>
          {formatted => <>&nbsp;{formatted}</>}
        </DisplayTime>
      </span>
    );
  }

  render(): React.ReactNode {
    const bem = new Bem('checkinTeaser', this.props, []);

    const { checkIn, isAutoAllocationAllowed, suggestedSeats, currentSegmentId } = this.props;

    let suggestedSeatsForCurrentSegment = [];

    if (suggestedSeats && currentSegmentId) {
        suggestedSeatsForCurrentSegment = suggestedSeats[currentSegmentId];
    }

    const suggestedSeatsAreNotEmpty = (suggestedSeatsForCurrentSegment)?
        suggestedSeatsForCurrentSegment.length > 0 : false;

    /*
     In this case backend tells that teaser should be shown,
     but we don't want to show it unless auto allocation is allowed
    */
    const teaserShouldBeHidden =
      checkIn &&
      checkIn.teaser &&
      checkIn.teaser.causeShowTeaser === TeaserShownReasons.NotCheckInStation &&
      !isAutoAllocationAllowed;

    const teaserShown =
      checkIn &&
      checkIn.teaser &&
      checkIn.teaser.causeShowTeaser &&
      !teaserShouldBeHidden;

    if (teaserShown) {
      return (
        <div className={bem.block({ hide: this.state.closed })}>
          <div className={bem.element('paxIcon')} />
          <div className={bem.element('content')}>
            {checkIn.teaser.causeShowTeaser ===
              TeaserShownReasons.BeforeSalesWindow && (
              <div>
                <T>CHECKIN_TEASER_OPENS_AT_PREFIX</T>
                {this.renderDateAndTime()}
              </div>
            )}
            {checkIn.teaser.causeShowTeaser ===
              TeaserShownReasons.NeedToBuySeats &&
                (!isAutoAllocationAllowed || !suggestedSeatsAreNotEmpty) && (
                <T>
                  CHECKIN_TEASER_AVAILABLE_AFTER_SEATS_SELECT_WIDGET_EXTENDED
                </T>
              )}
            {checkIn.teaser.causeShowTeaser ===
              TeaserShownReasons.NeedToBuySeats &&
              isAutoAllocationAllowed && suggestedSeatsAreNotEmpty && (
                <T>
                  CHECKIN_TEASER_AVAILABLE_AFTER_SEATING_WITH_AUTO_ALLOCATION
                </T>
              )}
            {checkIn.teaser.causeShowTeaser ===
              TeaserShownReasons.NotCheckInStation &&
              isAutoAllocationAllowed && (
                <T>CHECKIN_TEASER_NOT_CHECKIN_STATION</T>
              )}
          </div>
          <div
            className={bem.element('closeButton')}
            onClick={this.onCloseClicked}
          />
        </div>
      );
    }

    return null;
  }
}

export const CheckinTeaser = connect(mapStateToProps)(CheckinTeaserComponent);
