import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Bem } from 'react-bem-classes';
import './index.scss';
import { Text } from 'languages/components/text';
import { localeDataSelector } from '../../../languages/selectors';

const bem = new Bem('payVariants');

const connectComponent = connect(state => ({
  localeData: localeDataSelector(state),
}));

class PayVariants extends Component {
  componentDidMount() {
    const { variants, setCurrentPayVariant } = this.props;

    setCurrentPayVariant(variants[0]);
  }

  switchPayVariant(payVariant) {
    this.props.setCurrentPayVariant(payVariant);
  }

  render() {
    const { variants, currentPayVariant, localeData } = this.props;

    return (
      <div className={bem.block()} role="tablist">
        {variants.map(payVariant => (
          <div
            key={payVariant.name}
            className={bem.element('item', {
              current:
                currentPayVariant && payVariant.name === currentPayVariant.name,
            })}
            aria-selected={
              currentPayVariant && payVariant.name === currentPayVariant.name
            }
            onClick={this.switchPayVariant.bind(this, payVariant)}
            role="tab"
          >
            {payVariant.cmsLogoKey ? (
              <img
                src={localeData.printCmsText(payVariant.cmsLogoKey)}
                alt={payVariant.title}
                height="20"
              />
            ) : (
              <Text>{payVariant.cmsKey}</Text>
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default connectComponent(PayVariants);
