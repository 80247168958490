import React from 'react';
import bem from 'react-bem-classes';
import './index.scss';
import { Text } from 'languages/components/text';
import { ShareButtons } from 'react-share';

@bem({
  block: 'confirmationShare',
})
class ConfirmationShare extends React.Component {
  constructor(props) {
    super();

    this.state = {
      value: props.defaultText,
    };
  }

  handleChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  getUrl() {
    return this.props.shareUrl;
  }

  render() {
    const { FacebookShareButton, TwitterShareButton } = ShareButtons;

    const shareOptions = {
      url: this.getUrl(),
      title: this.state.value,
    };

    return (
      <div className={this.block()}>
        <Text className={this.element('title')}>CONFIRM_SHARE_FORM_TITLE</Text>
        <textarea
          className={this.element('textarea')}
          value={this.state.value}
          onChange={this.handleChange}
        />

        <div className={this.element('socialButtons')}>
          <FacebookShareButton
            {...shareOptions}
            className={this.element('socialButton', { type: 'fb' })}
          />
          {/* <GooglePlusShareButton {...shareOptions} className={this.element('socialButton', {type: 'gp'})} /> */}
          <TwitterShareButton
            {...shareOptions}
            className={this.element('socialButton', { type: 'tw' })}
          />
        </div>
      </div>
    );
  }
}

export default ConfirmationShare;
