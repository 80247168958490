import { Dispatch } from 'react-redux';
import http from 'axios';
import { IStoreState } from 'types/store';
import { appConfig } from '../../config';
import { axiosConfigSelector } from '../selectors';
import { currentLangSelector, dateOptionsSelector } from 'languages/selectors';
import { paxshopDateToInternal } from '../../_helpers/dateFormat';

interface ISpecialAssistanceDetails {
  assistanceNeeded: 'yes';
  bookingNumber?: string;
  departureDate: string;
  name: string;
  email: string;
  phoneNumber: string;
  disabilitiesList: string[];
  outSegment?: {
    flightNumber: string;
    departureDate: string;
  };
  inSegment?: {
    flightNumber: string;
    departureDate: string;
  };
  mobilityAid?: {
    mobilityAidType: string;
    length: string;
    height: string;
    width: string;
    weight: string;
    brand: string;
    model: string;
    collapsible: boolean;
    batteryType?: string;
    numberOfBatteries?: string;
    needToRemoveForTransport?: boolean;
  };
}

interface IDetailsToSend extends ISpecialAssistanceDetails {
  locale: string;
  paxshopId: number;
}

export function requestSpecialAssistance(
  assistanceDetails: ISpecialAssistanceDetails
) {
  return (dispatch: Dispatch<any>, getState: () => IStoreState) => {
    const state = getState();

    const locale = currentLangSelector(getState());
    const dateFormat = dateOptionsSelector(state).paxshopDateFormat;
    const reformattedDate = paxshopDateToInternal(
      assistanceDetails.departureDate,
      dateFormat
    );

    const dataToSend: IDetailsToSend = {
      ...assistanceDetails,
      paxshopId: appConfig.paxshopId,
      locale,
      departureDate: reformattedDate,
      mobilityAid: assistanceDetails.mobilityAid && {
        ...assistanceDetails.mobilityAid,
        length: `${assistanceDetails.mobilityAid.length} CM`,
        width: `${assistanceDetails.mobilityAid.width} CM`,
        height: `${assistanceDetails.mobilityAid.height} CM`,
        weight: `${assistanceDetails.mobilityAid.weight} KG`,
      },
    };

    return http.post(
      `${appConfig.apiUrl}/affiliate/specialAssistance`,
      dataToSend,
      axiosConfigSelector(getState())
    );
  };
}
