import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  currentLangSelector,
  allCMSKeysRawSelector,
} from 'languages/selectors';
import { Bem } from 'react-bem-classes';
import {
  mainMenuItemsSelector,
  currentMenuItemSelector,
} from 'mainMenu/selectors';
import { MobileMenuContent } from 'mainMenu/components/mobileMenuContent';
import { allSegmentsDepartedSelector } from 'segments/helpers/selectors';
import { goToPage } from 'routing/routing';

import './index.scss';
import { alreadyCheckedInOrBoughtSeatsSegmentsSelector } from 'check-in/helpers/alreadyCheckinOrBoughtSelector';
import { selectCheckInSegments } from 'check-in/helpers/selectors';

@connect(state => ({
  mainMenuItems: mainMenuItemsSelector(state),
  currentMenuItem: currentMenuItemSelector(state),
  currentLanguage: currentLangSelector(state),
  allCmsKeys: allCMSKeysRawSelector(state),
  checkIn: selectCheckInSegments(state),
  wizardMenu: state.init.paxshopData.data.wizardMenu,
  alreadyCheckinOrBoughtSeats: alreadyCheckedInOrBoughtSeatsSegmentsSelector(
    state
  ),
  allSegmentsDeparted: allSegmentsDepartedSelector(state),
}))
export class MobileMenu extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    requestClose: PropTypes.func,
    currentLanguage: PropTypes.string.isRequired,
    checkIn: PropTypes.array,
    alreadyCheckinOrBoughtSeats: PropTypes.bool,
  };

  static contextTypes = {
    languages: PropTypes.array.isRequired,
    paxShopLogout: PropTypes.func.isRequired,
    showFaqDialog: PropTypes.func.isRequired,
    openMybookingPopup: PropTypes.func.isRequired,
    openBoardingPassesPopup: PropTypes.func.isRequired,
    openContactInfoPopup: PropTypes.func.isRequired,
    enableNewCheckin: PropTypes.bool.isRequired,
    moveMyBooking: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
  };

  menuAction(callback) {
    return (...params) => {
      this.props.requestClose();
      setTimeout(() => callback(...params), 20);
    };
  }

  doLogout = this.menuAction(() => this.context.paxShopLogout());

  doOpenFaq = this.menuAction(() => this.context.showFaqDialog());

  doOpenMyBooking = this.menuAction(() => this.context.openMybookingPopup());

  doOpenContactInfoPopup = this.menuAction(() =>
    this.context.openContactInfoPopup()
  );

  doOpenBoardingPasses = this.menuAction(() =>
    this.context.openBoardingPassesPopup()
  );

  doNavigateToPage = this.menuAction(paxshopPageId => goToPage(paxshopPageId));

  static childContextTypes = {
    doLogout: PropTypes.func.isRequired,
    doOpenFaq: PropTypes.func.isRequired,
    doOpenMyBooking: PropTypes.func.isRequired,
    doOpenBoardingPasses: PropTypes.func.isRequired,
    doOpenContactInfoPopup: PropTypes.func.isRequired,
    navigateToPage: PropTypes.func.isRequired,
  };

  getChildContext() {
    return {
      doLogout: this.doLogout,
      doOpenFaq: this.doOpenFaq,
      doOpenMyBooking: this.doOpenMyBooking,
      doOpenBoardingPasses: this.doOpenBoardingPasses,
      doOpenContactInfoPopup: this.doOpenContactInfoPopup,
      navigateToPage: this.doNavigateToPage,
    };
  }

  render() {
    const {
      active,
      requestClose,
      currentLanguage,
      checkIn,
      alreadyCheckinOrBoughtSeats,
      allSegmentsDeparted,
      mainMenuItems,
      currentMenuItem,
      allCmsKeys,
      wizardMenu,
    } = this.props;

    const bem = new Bem('mobileMenu');

    return (
      <div className={bem.block()}>
        <div
          className={bem.element('overlay', { active })}
          onClick={requestClose}
        />
        <div className={bem.element('menuBox', { active })}>
          <MobileMenuContent
            mainMenuItems={mainMenuItems}
            currentMenuItem={currentMenuItem}
            currentLanguage={currentLanguage}
            allCmsKeys={allCmsKeys}
            wizardMenu={wizardMenu}
            checkIn={checkIn}
            alreadyCheckinOrBoughtSeats={alreadyCheckinOrBoughtSeats}
            allSegmentsDeparted={allSegmentsDeparted}
            closeMenu={requestClose}
          />
        </div>
      </div>
    );
  }
}
