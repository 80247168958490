import { reduxForm } from 'redux-form';
import { appConfig } from 'config';
import {
  validate,
  validateEmail,
  validateNotEmpty,
  validatePhone,
} from '_helpers/reduxFormHelpers';
import { COLLECTOR_CITIZENSHIPS } from './collectorCitizenships';

const CONTACT_DETAILS_VALIDATION_RULES = {
  ...(appConfig.contactDetailsFields.includes('phone') && {
    phone: [validatePhone],
  }),
  ...(appConfig.contactDetailsFields.includes('email') && {
    email: [validateEmail],
  }),
};

export const withForm = reduxForm({
  form: 'paymentForm',
  destroyOnUnmount: false,
  validate: validatePaymentForm,
});

function validatePaymentForm(values, props) {
  const {
    currentPayVariant,
    containsFabServices,
    isKlarnaPayment,
    basket,
  } = props;
  const isCollector =
    currentPayVariant && currentPayVariant.name === 'collector';

  const contactDetailsValidation = validate(
    values,
    CONTACT_DETAILS_VALIDATION_RULES
  );

  const collectorValidation: {
    citizenship?: string;
    personalId?: string;
  } = isCollector
    ? validate(values, {
        citizenship: [validateNotEmpty],
        personalId: [validateNotEmpty],
      })
    : {};

  if (isCollector && !collectorValidation.citizenship) {
    if (
      basket.currency !==
      COLLECTOR_CITIZENSHIPS.find(cs => cs.id === values.citizenship).currency
    ) {
      collectorValidation.citizenship =
        'CMS_COLLECTOR_CURRENCY_NOT_SUPPORTED_FOR_COUNTRY';
    }
  }

  const countryValidation = isKlarnaPayment
    ? validate(values, {
        country: [validateNotEmpty],
      })
    : null;

  const addressValidation = containsFabServices
    ? validate(values, {
        address1: [validateNotEmpty],
        city: [validateNotEmpty],
        postCode: [validateNotEmpty],
      })
    : null;

  return {
    _error: basket.validationStatus === 'VALID' ? null : 'BASKET_IS_INVALID',
    ...contactDetailsValidation,
    ...collectorValidation,
    ...countryValidation,
    ...addressValidation,
  };
}
