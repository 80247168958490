import { IStoreState } from 'types/store';
import { IApiService } from '../../types/services';
import { IPassenger } from '../../types/passengers';
import { passengersSelector } from '../../passengers/helpers/selectors';
import { selectUpToDateBasket } from './selectBasket';

export const selectIfServiceInBasketForPassenger = (
  state: IStoreState,
  service: IApiService,
  passenger: IPassenger
) => {
  const basket = selectUpToDateBasket(state);

  return Boolean(
    basket.find(
      s =>
        s.serviceId === service.serviceId &&
        s.segmentId === service.segmentId &&
        s.passengerId === passenger.passengerId
    )
  );
};

export const selectIfServiceInBasketForAllPassengers = (
  state: IStoreState,
  service: IApiService
) => {
  const passengers = passengersSelector(state);
  return passengers.every(passenger =>
    selectIfServiceInBasketForPassenger(state, service, passenger)
  );
};

export const selectServicesOfNumGroupInBasket = (
  state: IStoreState,
  numGroup: number
) => {
  const basket = selectUpToDateBasket(state);
  return basket.filter(service => service.uniqueNumGroup === numGroup);
};

export const selectLoungesInBasket = (state: IStoreState) => {
  const basket = selectUpToDateBasket(state);
  return basket.filter(
    service => service.supplier === 'fab' && service.tags.includes('lounges')
  );
};

export const selectLoungesForSegment = (
  state: IStoreState,
  segmentId: number
) => {
  const lounges = selectLoungesInBasket(state);
  return lounges.filter(lounge => lounge.segmentId === segmentId);
};
