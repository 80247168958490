import React from 'react';
import bem from 'react-bem-classes';
import './index.scss';

@bem({
  block: 'columns',
})
class Columns extends React.Component {
  render() {
    const { fullWidthBreakPoint = LAYOUT_BREAKS.portraitTablet } = this.props;

    return (
      <div>
        <div className={this.block({ fullWidth: fullWidthBreakPoint })}>
          {this.props.children.map((child, i) => (
            <div key={i} className={this.element('item')}>
              {child}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Columns;
