import './index.scss';
import PropTypes from 'prop-types';

import React from 'react';
import { Field } from 'redux-form';
import bem, { Bem } from 'react-bem-classes';
import { filterInputProps } from '_helpers/reduxFormHelpers';

let idCounter = 0;

@bem({
  block: 'slideCheckbox',
})
class renderSlideCheckbox extends React.Component {
  componentDidUpdate() {
    const { onFieldRendered, meta } = this.props;
    if (onFieldRendered) {
      onFieldRendered(meta, this.refs.root);
    }
  }

  componentDidMount() {
    const { onFieldRendered, meta } = this.props;
    if (onFieldRendered) {
      onFieldRendered(meta, this.refs.root);
    }
  }

  componentWillMount() {
    this.id = `__slideCheckBox__${idCounter++}`;
  }

  render() {
    const {
      input,
      checked,
      meta: { touched, error, invalid },
      className,
      cmsKeys,
      ...rest
    } = this.props;
    const bem = new Bem('field');

    const shouldDisplayError = touched && invalid;

    return (
      <div
        ref="root"
        className={`${this.block()} ${className || ''}`}
        data-error={shouldDisplayError && error ? cmsKeys[error] || error : ' '}
      >
        <input
          id={this.id}
          type="checkbox"
          className={this.element('input')}
          checked={checked}
          {...filterInputProps(input)}
        />
        <label htmlFor={this.id} className={this.element('slider')} />
      </div>
    );
  }
}

export default class SlideCheckbox extends React.Component {
  static propTypes = {
    field: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  render() {
    const { name, validate, className, ...rest } = this.props;

    return (
      <Field
        name={name}
        component={renderSlideCheckbox}
        validate={validate}
        className={className}
        {...rest}
      />
    );
  }
}
