import React, { Component } from 'react';
import bem from 'react-bem-classes';
import './index.scss';
import { connect } from 'react-redux';
import ServiceMoreInfoCheck from 'services/_helpers/serviceMoreInfoCheck';
import LegendType from 'seatingWidget/components/legendTypes';
import { hasInfantSelector } from 'services/selectors';
import Item from '../seat';
import { doublePricedServices } from '../../selectors/index';

@connect(state => ({
  currentPassengerId: state.passengers.currentPassengerId,
  hasInfant: hasInfantSelector(state),
  legendServices: doublePricedServices(state),
}))
@bem({
  block: 'seatsList',
})
class Legend extends Component {
  render() {
    const { currentPassengerId, hasInfant, legendServices } = this.props;

    return !currentPassengerId ? null : (
      <div>
        <LegendType hasInfant={hasInfant} />
        <div className={this.block()}>
          {legendServices.map((legendService, i) => (
            <ServiceMoreInfoCheck
              key={i}
              serviceInfoCms={legendService.service.infoCmsKey}
              charactersLimit={1}
            >
              <Item {...legendService} className={this.element('item')} />
            </ServiceMoreInfoCheck>
          ))}
        </div>
      </div>
    );
  }
}

export default Legend;
