import React, { Component } from 'react';
import './seat.scss';
import SeatInfo from '../seatInfo';
import bem from 'react-bem-classes';

let touched = false;
let touchSupport =
  'ontouchstart' in window ||
  (window.DocumentTouch && document instanceof DocumentTouch);

@bem({
  block: 'psw-seatBlock',
})
export default class Seat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInfoShow: false,
    };
  }

  detectSeatSize() {
    return this.props.seat.display.image.indexOf('large') > -1
      ? 'large'
      : 'small';
  }

  detectType() {
    const { seat, selectionLocked } = this.props;

    let type = '';

    if (!seat.availability) type = 'unavailable';
    if (seat.isBusyForCurrentUser) type = 'selected';
    if (seat.isBusy) type = 'busy';
    if (selectionLocked) type = 'unavailable';
    if (seat.suggestedFor) type = 'suggested';

    return type;
  }

  seatSelect = () => {
    const { seat, selectionLocked } = this.props;

    if (!touched) {
      if (
        !selectionLocked &&
        seat.availability &&
        (!seat.isBusy || (seat.isBusy && seat.isBusyForCurrentUser))
      ) {
        setTimeout(() => this.props.onSeatClick(this.props.seat), 0);
      }
    }

    touched = true;
    setTimeout(function() {
      touched = false;
    }, 0);
  };

  showInfo = e => {
    this.setState({ isInfoShow: true });
    e.preventDefault();
    return false;
  };

  hideInfo = () => {
    this.setState({ isInfoShow: false });
  };

  render() {
    const { seat } = this.props;

    let events = {};

    if (seat.availability) {
      events = touchSupport
        ? {
            onMouseUp: e => {
              e.preventDefault();
              return false;
            },
            onClick: this.seatSelect,
          }
        : {
            onClick: this.seatSelect,
          };
    }

    const showInfantIcon =
      this.props.context.passengers.filter(p => p.type === 'INF').length > 0;

    const scale = 1.7;

    const styles = {
      top: seat.display.depth_offset * scale,
      left: seat.display.width_offset * scale,
      width: seat.display.width * scale,
      height: seat.display.depth * scale,
    };

    return (
      <div
        className={this.block({ size: this.detectSeatSize() })}
        style={styles}
      >
        <div
          id={seat.id}
          className={this.element('seat', {
            type: this.detectType(),
            child: showInfantIcon && seat.infantAllowed,
          })}
          {...events}
          onMouseMove={this.showInfo}
          onMouseEnter={this.showInfo}
          onMouseLeave={this.hideInfo}
        >
          {seat.isAllowed && (
            <div
              className={this.element('seatImage')}
              style={{ backgroundImage: `url(${seat.display.image})` }}
            />
          )}
        </div>
        {this.state.isInfoShow && (
          <SeatInfo {...this.props} className={this.element('info')} />
        )}
      </div>
    );
  }
}
