import React, { Component } from 'react';
import { connect } from 'react-redux';
import { allCMSKeysRawSelector } from 'languages/selectors';

@connect(state => ({
  cmsKeys: allCMSKeysRawSelector(state),
}))
class ForExistCmsKey extends Component {
  render() {
    const { cmsKeys, cmsKey, children } = this.props;
    return cmsKeys[cmsKey] && cmsKeys[cmsKey] !== '' ? children : null;
  }
}

export default ForExistCmsKey;
