import React, { Component } from 'react';
import bem from 'react-bem-classes';
import './index.scss';
import { Text } from 'languages/components/text';
import { Price } from 'commonBlocks/components/price';
import { Modal } from 'layouts/modal';
import SeatLegend from 'services/components/seatLegendModal';

@bem({
  block: 'serviceSeat',
  modifiers: ['isInfoShow'],
})
class Legend extends Component {
  handleClick() {
    this.modal.open();
  }

  render() {
    const {
      service,
      price = [],
      isInfoShow,
      available,
      showPrice,
    } = this.props;

    return (
      <div
        className={this.block({ isInfoShow, unavailable: !available })}
        onClick={available ? this.handleClick.bind(this) : () => {}}
      >
        <div className={this.element('content')}>
          <img className={this.element('image')} src={service.imageName} />
          {available ? (
            <div className={this.element('info')}>
              <Text className={this.element('title')}>
                {service.nameCmsKey}
              </Text>
              {price.map((p, i) => (
                <span key={i}>
                  <Price
                    className={
                      i === 0
                        ? this.element('price')
                        : this.element('aditionalPrice')
                    }
                    price={p.amount}
                    currency={p.currency}
                  />
                  <br />
                </span>
              ))}
            </div>
          ) : (
            <div className={this.element('content')}>
              <Text className={this.element('unavailable')}>
                FORBIDDEN_BUTTON_TEXT
              </Text>
            </div>
          )}
        </div>

        <Modal ref={c => (this.modal = c)} centered>
          <SeatLegend seat={{ info: service, price: price[0], showPrice }} />
        </Modal>
      </div>
    );
  }
}

export default Legend;
