import React from 'react';
import cn from 'classnames';

interface IProps {
  iconCode: string;
  className: string;
}

export default function Icon(props: IProps): JSX.Element {
  const { iconCode, className } = props;

  return <i className={cn(className, 'icon', iconCode)} />;
}
