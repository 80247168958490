import React, { Component } from 'react';
import bem from 'react-bem-classes';

import './index.scss';

import { Text } from 'languages/components/text';
import CartPassengerBySegment from 'passengers/components/cartPassengerBySegment';

@bem({
  block: 'cartPassengersBySegment',
})
class CartPassengersBySegment extends Component {
  render() {
    const {
      segment,
      passengersList,
      serviceGroups,
      removeItem,
      basketInfo,
      currency,
    } = this.props;

    return (
      <div className={this.block()}>
        <div
          className={this.element('title', {
            direction: segment.direction.toLowerCase(),
          })}
        >
          {segment.direction === 'OUT' ? (
            <div>
              <div className={`${this.element('icon')} icon plane_takeoff`} />
              <Text>ALL_PAGES_OUTBOUND_BLOCK_TEXT</Text>
            </div>
          ) : (
            <div>
              <div className={`${this.element('icon')} icon plane-landing`} />
              <Text>ALL_PAGES_INBOUND_BLOCK_TEXT</Text>
            </div>
          )}
          , {segment.flightNumber}
        </div>
        {passengersList.map((passenger, i) => (
          <CartPassengerBySegment
            key={i}
            currency={currency}
            segment={segment}
            passenger={passenger}
            removeItem={removeItem}
            basketInfo={basketInfo}
            serviceGroups={serviceGroups}
          />
        ))}
      </div>
    );
  }
}

export default CartPassengersBySegment;
