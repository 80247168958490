import React, { useRef } from 'react';
import { Portal } from 'react-portal';
import { IFabService } from 'types/services';
import { Bem } from 'react-bem-classes';
import './index.scss';
import { useOnClickOutside } from '../../../../_helpers/useOnClickOutside';
import { useLockBodyScroll } from '../../../../_helpers/useLockBodyScroll';

const bem = new Bem('descriptionModal');

export function DescriptionModal(props: {
  service: IFabService;
  onClose: () => void;
  children: React.ReactNode;
}) {
  const { children, service, onClose } = props;
  const modalRef = useRef();
  useOnClickOutside(modalRef, onClose);
  useLockBodyScroll();

  return (
    <Portal>
      <div className={bem.element('overlay')}>
        <div className={bem.element('modal')} ref={modalRef}>
          <button
            onClick={onClose}
            type="button"
            className={bem.element('closeButton')}
          >
            <svg viewBox="0 0 413 413" width={15} height={15}>
              <path d="M413 24L389 0 207 182 24 0 0 24l182 183L0 389l24 24 183-182 182 182 24-24-182-182z" />
            </svg>
          </button>
          <div className={bem.element('header')}>
            <img
              className={bem.element('primaryImage')}
              src={service.imageData.url}
              alt=""
            />
            <div className={bem.element('headerColumn')}>
              <h2 className={bem.element('name')}>{service.name}</h2>
              {children}
            </div>
          </div>
          <p className={bem.element('description')}>{service.text}</p>
          <div className={bem.element('gallery')}>
            {service.additionalImages.map(image => (
              <div className={bem.element('galleryItem')}>
                <img src={image.url} alt={image.captionText} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Portal>
  );
}
