const defaultState = {};

export default function cmsKeys(state = defaultState, action) {
  switch (action.type) {
    case 'INIT':
      return action.config.cmsKeys;
    case 'SET_CMS_KEYS':
      return action.cmsKeys;
    default:
      return state;
  }
}
