import React from 'react';
import bem from 'react-bem-classes';
import { Text } from 'languages/components/text';
import './index.scss';
import onClickOutside from 'react-onclickoutside';

function Deferred() {
  this.promise = new Promise((resolve, reject) => {
    this.resolve = resolve;
    this.reject = reject;
  });

  this.then = this.promise.then.bind(this.promise);
  this.catch = this.promise.catch.bind(this.promise);
}

@onClickOutside
@bem({
  block: 'wizardTooltip',
  modifiers: ['type', 'position', 'leftover'],
})
export class WizardTooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isShowing: false };
  }

  showTooltip() {
    this.setState({ isShowing: true });
    this.defer = new Deferred();
    return this.defer;
  }

  hideTooltip() {
    this.setState({ isShowing: false });
  }

  render() {
    const {
      nextStepAvailabilityStatus: {
        messageCmsKey,
        type,
        rejectButtonCmsKey,
        resolveButtonCmsKey,
      },
    } = this.props;
    return (
      <div className={this.block({ hidden: !this.state.isShowing, type })}>
        <div className={this.element('content')}>
          <div className={this.element('icon')} />
          <Text className={this.element('message')}>{messageCmsKey}</Text>
          <div className={this.element('actions')}>
            {type === 'error' && (
              <Text
                className={this.element('cancelLink')}
                onClick={() => this.defer.reject()}
              >
                OK_I_GOT_IT
              </Text>
            )}

            {type === 'confirm' && (
              <div>
                <Text
                  className={this.element('button', { type: 'reject' })}
                  onClick={() => this.defer.reject()}
                >
                  {rejectButtonCmsKey}
                </Text>
                <Text
                  className={this.element('button', { type: 'resolve' })}
                  onClick={() => this.defer.resolve()}
                >
                  {resolveButtonCmsKey}
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
