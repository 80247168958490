import * as types from '../constants/PlaneActionTypes';
import { TOGGLE_SEAT, HOVER_SEAT } from '../constants/SeatActionTypes';
import { TOGGLE_PASSENGER } from '../constants/PassengersActionTypes';
import matcher from '../helpers/matcher';

const defaultState = {
  aircraftDecs: [],
  seatMap: [],
  seatTypes: [],
  seatImages: [],
  context: [],
  definitions: [],
  allocationSeatMap: [],
  activePassenger: [],
  loaded: false,
};

function priceByPassenger(matchedPriceForCurrentPassenger, seat) {
  var price, currency;

  if (
    matchedPriceForCurrentPassenger.length &&
    seat.passenger &&
    seat.passenger.length == 0
  ) {
    price = matchedPriceForCurrentPassenger[0].amount;
    currency = matchedPriceForCurrentPassenger[0].currency;
  } else if (seat.passenger) {
    price = seat.price;
    currency = seat.currency;
  } else {
    price = 0;
    currency = '';
  }

  return {
    price: price,
    currency: currency,
  };
}

export default function mainReducer(state = defaultState, action) {
  switch (action.type) {
    case `${types.FETCH_CONFIGURATION}_REQUEST`:
      return defaultState;
    case types.FETCH_CONFIGURATION:
      return Object.assign({}, state, {
        aircraftDecs: action.res.data.aircraft.decks,
        seatMap: action.res.data.definitions.seatmap.map((seat, i) => {
          return Object.assign({}, seat, {
            seatType: matcher(
              action.res.data.definitions.seat_types,
              seat.seat_type_ref,
              'id'
            ).length
              ? matcher(
                  action.res.data.definitions.seat_types,
                  seat.seat_type_ref,
                  'id'
                )[0]
              : '',
            seatImage: matcher(
              action.res.data.definitions.images,
              seat.image_ref,
              'id'
            ).length
              ? matcher(
                  action.res.data.definitions.images,
                  seat.image_ref,
                  'id'
                )[0]
              : '',
            isHovering: false,
            selected: false,
            availability: false,
            allocation: [],
            passenger: [],
            price: 0,
            currency: '',
          });
        }),
      });

    case types.FETCH_ALLOCATION:
      const newState = Object.assign({}, state, {
        seatMap: state.seatMap.map((seat, i) => {
          const occupiedSeat = action.occupiedSeats.filter(
            i => i.seatId == seat.id
          )[0];
          const matchedAllocationSeat = matcher(
            action.res.data.seatmap,
            seat.id,
            'id'
          );
          const newSeat = {
            seat_properties: seat.seat_properties,
          };
          let matchedService = [];

          if (matchedAllocationSeat.length) {
            Object.assign(seat, {
              allocation: matchedAllocationSeat[0].allocation,
            });
            matchedService = matcher(
              action.res.data.definitions.services,
              matchedAllocationSeat[0].allocation.service_ref,
              'id'
            );
          }

          if (matchedService.length) {
            Object.assign(seat, {
              service: matchedService[0],
            });
          } else {
            Object.assign(seat, {
              service: {},
            });
          }

          if (occupiedSeat && occupiedSeat.length != 0) {
            const occupiedPassenger =
                action.res.data.context.passengers.filter(
                  psng => psng.id == occupiedSeat.passengerId
                )[0] || {},
              matchedPriceForCurrentPassenger = matcher(
                seat.service.prices,
                occupiedPassenger.id,
                'passengerId'
              );

            Object.assign(seat, {
              selected: occupiedSeat ? true : false,
              passenger: occupiedPassenger,
              price: priceByPassenger(matchedPriceForCurrentPassenger, seat)
                .price,
              currency: priceByPassenger(matchedPriceForCurrentPassenger, seat)
                .currency,
            });
          }
          return seat;
        }),
        context: action.res.data.context,
        definitions: action.res.data.definitions,
        allocationSeatMap: action.res.data.seatmap,
      });
      // setTimeout(()=>{
      //   window.PSW.onLoad(newState.context.passengers)
      // })

      return newState;

    case types.CHANGE_LOAD_STATUS:
      return Object.assign({}, state, {
        loaded: action.status,
      });

    case TOGGLE_PASSENGER:
      return Object.assign({}, state, {
        activePassenger: action.passenger,
        seatMap: state.seatMap.map((seat, i) => {
          let prices = [];
          if (seat.service && seat.service.prices) {
            prices = seat.service.prices;
          }
          const matchedPriceForCurrentPassenger = matcher(
            prices,
            action.passenger.id,
            'passengerId'
          );
          let availability;

          if (
            (seat.allocation.eligible_passengers || []).indexOf(
              action.passenger.id
            ) !== -1
          ) {
            availability = true;
          } else {
            availability = false;
          }

          return Object.assign({}, seat, {
            availability: availability,
            price: priceByPassenger(matchedPriceForCurrentPassenger, seat)
              .price,
            currency: priceByPassenger(matchedPriceForCurrentPassenger, seat)
              .currency,
          });
        }),
      });

    case TOGGLE_SEAT:
      return Object.assign({}, state, {
        seatMap: state.seatMap.map((seat, i) => {
          let selected, passenger;
          const activePassenger =
            action.passenger == undefined
              ? state.activePassenger
              : action.passenger;
          if (seat.id === action.seat.id) {
            selected = !seat.selected;
            passenger = !selected ? [] : state.activePassenger;
          } else if (
            seat.passenger != undefined &&
            seat.passenger.id == activePassenger.id
          ) {
            selected = false;
            passenger = [];
            // cookie.remove(seat.id)
          } else {
            selected = seat.selected;
            passenger = seat.passenger;
          }

          return Object.assign({}, seat, {
            selected: selected,
            passenger: passenger,
          });
        }),
      });

    case HOVER_SEAT:
      return Object.assign(
        {},
        state,
        state.seatMap.map((seat, i) => {
          if (seat.id === action.seat.id) {
            Object.assign(seat, {
              isHovering: !seat.isHovering,
            });
          }
        })
      );

    default:
      return state;
  }
}
