import './index.scss';

import { appConfig } from 'config';

import React, { Component } from 'react';
import { reduxForm, FieldArray, SubmissionError } from 'redux-form';
import { validateNotEmpty } from '_helpers/reduxFormHelpers';
import { Text } from 'languages/components/text';
import {
  infantWithoutRefSelector,
  adultWithoutInfantRefSelector,
  infantSelector,
  infantRefInfoSelector,
} from 'passengers/helpers/selectors';
import * as passengersAction from 'passengers/actions';
import { updateAllInfantsHaveRef } from 'authorization/actions';
import { forceWidgetReload } from 'seatingWidget/actions';
import http from 'axios';
import { axiosConfigSelector } from 'authorization/selectors';
import {
  backendErrorFromAxiosError,
  defaultBackendErrorHandler,
  anyToBackendError,
} from '_helpers/backendError';
import errorLogger from '_helpers/errorLogger';
import { filterInputProps } from '_helpers/reduxFormHelpers';

import { Bem } from 'react-bem-classes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { allCMSKeysRawSelector } from 'languages/selectors';
import SelectInput from 'commonBlocks/components/selectInput/index';
import PropTypes from 'prop-types';

function validateAttachInfantForm(values) {
  function hasDuplicates(array) {
    const ids = array.map(s => parseInt(s)).filter(n => !isNaN(n));

    const valuesSoFar = {};

    for (let i = 0; i < ids.length; ++i) {
      if (valuesSoFar[ids[i]]) return true;
      valuesSoFar[ids[i]] = true;
    }

    return false;
  }

  const errors = values.adultIds.reduce((errors, adultId, idx) => {
    if (!Array.isArray(errors.adultIds)) {
      errors.adultIds = [];
    }
    errors[`adultIds[${idx}]`] = validateNotEmpty(adultId);
    errors.adultIds.push(validateNotEmpty(adultId));
    return errors;
  }, {});

  if (hasDuplicates(values.adultIds)) {
    errors._error = 'CMS_KEY_DUPLICATE_ADULTS_ATTACHED';
  }

  return errors;
}

const bem = new Bem('attachInfantForm');

const renderInfants = ({
  fields,
  meta: { touched, error, submitFailed },
  cmsKeys,
  adultOptions,
  infantPassengers,
}) =>
  fields.map((infant, idx) => (
    <div key={idx} className={bem.element('row')}>
      <span className={bem.element('infant')}>
        <i className={bem.element('infantIcon')} />
        {infantPassengers[idx].firstName} {infantPassengers[idx].lastName}
      </span>

      <SelectInput
        name={infant}
        options={adultOptions[idx]}
        className={bem.element('adultSelect')}
        getOptionId={pax => pax.passengerId}
        getOptionText={pax => pax.fullName}
        allowEmptyValue
        cmsKeys={cmsKeys}
        placeholder={
          cmsKeys.CMS_KEY_SELECT_ADULT_PLACEHOLDER || 'Select an adult'
        }
      />
    </div>
  ));

@connect(
  state => ({
    cmsKeys: allCMSKeysRawSelector(state),
    adultPassengers: adultWithoutInfantRefSelector(state),
    infantPassengers: infantWithoutRefSelector(state),
    payment: state.payment.payment,
    infantRefInfo: infantRefInfoSelector(state),
    axiosConfig: axiosConfigSelector(state),
    initialValues: {
      adultIds: infantSelector(state).map(() => ''),
    },
  }),
  {
    forceWidgetReload,
    updateAllInfantsHaveRef,
  }
)
@reduxForm({
  form: 'attachInfantForm',
  validate: validateAttachInfantForm,
})
class AttachInfantForm extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      adultOptions: props.infantPassengers.map(() =>
        props.adultPassengers.map(passenger =>
          Object.assign({}, passenger, {
            fullName: `${passenger.firstName} ${passenger.lastName}`,
          })
        )
      ),
    };
  }

  formSubmit = values => {
    const {
      authActions,
      infantPassengers,
      widgetActions,
      axiosConfig,
    } = this.props;

    const infantToAdultSrc = values.adultIds.map((adultId, i) => ({
      infantId: infantPassengers[i].passengerId,
      passengerId: parseInt(adultId),
    }));

    return new Promise((resolve, reject) =>
      http
        .post(
          `${appConfig.apiUrl}/seating/attachInfant`,
          infantToAdultSrc,
          axiosConfig
        )
        .then(
          () => {
            this.props.updateAllInfantsHaveRef();
            this.props.forceWidgetReload();

            resolve();
          },
          axiosError => {
            // reject(backendErrorFromAxiosError(axiosError));
            reject(new SubmissionError(backendErrorFromAxiosError(axiosError)));
          }
        )
        .catch(errorLogger)
    );
  };

  componentDidMount() {
    const { infantPassengers, adultPassengers, infantRefInfo } = this.props;

    if (infantRefInfo.allInfantsHaveRef) {
      return;
    }

    if (infantPassengers.length === 1 && adultPassengers.length === 1) {
      this.formSubmit({
        adultIds: adultPassengers.map(adult => adult.passengerId),
      });
    }
  }

  render() {
    const {
      cmsKeys,
      handleSubmit,
      infantPassengers,
      error,
      invalid,
      submitting,
    } = this.props;

    const { adultOptions } = this.state;

    return (
      <div className={bem.block()}>
        <div className={bem.element('header')}>
          <Text
            fallback="In order to proceed you have to choose an adult to sit together with the infant:"
            useSpan
          >
            INFANT_POPUP_INFO
          </Text>
        </div>
        <form
          className={bem.element('formContainer')}
          ref="myForm"
          onSubmit={handleSubmit(this.formSubmit)}
        >
          <FieldArray
            name="adultIds"
            component={renderInfants}
            cmsKeys={cmsKeys}
            adultOptions={adultOptions}
            infantPassengers={infantPassengers}
          />

          {error && (
            <Text
              className={bem.element('errorMessage')}
              fallback="You can't attach same adult to different infants"
              useSpan
            >
              {error}
            </Text>
          )}
          <button
            type="submit"
            className={bem.element('submitButton', { disabled: invalid })}
            value="Confirm"
            disabled={invalid}
          >
            {cmsKeys.INFANT_POPUP_CONFIRM || 'Confirm'}
          </button>
        </form>
        <div className={`local-loader ${submitting ? 'active' : ''}`} />
      </div>
    );
  }
}

export default AttachInfantForm;
