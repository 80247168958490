import React, { useCallback, useEffect, useRef } from 'react';
import { Bem } from 'react-bem-classes';
import { connect, Dispatch } from 'react-redux';
import GroupList from 'services/components/groupList';
import { selectSubgroups } from 'services/selectors';
import * as serviceActions from 'services/actions';
import { IStoreState } from 'types/store';

const bem = new Bem('groups');

const mapStateToProps = (
  state: IStoreState,
  props: { primaryGroup: 'baggage' | 'otherservices' }
) => ({
  groups: selectSubgroups(state, props.primaryGroup),
  currentGroup: state.services.currentGroup,
});

type IProps = ReturnType<typeof mapStateToProps> & {
  dispatch: Dispatch<any>;
  primaryGroup: 'baggage' | 'otherservices';
};

function ServiceGroupSelector(props: IProps) {
  const { groups, currentGroup, primaryGroup, dispatch } = props;

  const setGroup = useCallback(
    (group: string | undefined) => {
      dispatch(
        serviceActions.setCurrentServiceGroup(
          group === undefined ? primaryGroup : group
        )
      );
    },
    [primaryGroup]
  );

  useEffect(() => {
    const isGroupIncorrect =
      !groups.includes(currentGroup) && currentGroup !== primaryGroup;
    // Set first group by default if there is no group selected or it's incorrect
    if (currentGroup === null || isGroupIncorrect) {
      setGroup(groups[0]);
    }
  }, [currentGroup, groups]);

  if (groups.length === 1) {
    return null;
  }

  return (
    groups.length > 1 && (
      <div className={bem.block()}>
        <GroupList
          setCurrentGroup={setGroup}
          groups={groups}
          currentGroup={currentGroup}
        />
      </div>
    )
  );
}

export default connect(mapStateToProps)(ServiceGroupSelector);
