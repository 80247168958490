import * as types from '../constants/PlaneActionTypes';

export default function seatsMapReducer(state = new Map(), action) {
  switch (action.type) {
    case types.FETCH_CONFIGURATION:
      const seatsMap = action.res.data.definitions.seatmap;
      return new Map(seatsMap.map(seat => [seat.id, seat]));
    default:
      return state;
  }
}
