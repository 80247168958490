import * as types from '../constants/PlaneActionTypes';
import * as seatActionTypes from '../constants/SeatActionTypes';

const defaultState = new Map();

export default function seatsReducer(state = defaultState, action) {
  switch (action.type) {
    case types.FETCH_ALLOCATION:
      return new Map(
        action.res.data.seatmap.map(seat => [seat.id, seat.allocation])
      );
    case seatActionTypes.SELECT_SEAT: {
      let seats = new Map(state);
      seats.forEach((seat, id) => {
        if (id === action.seatId) {
          seat.passengerId = action.passengerId;
        } else if (
          seat.passengerId &&
          seat.passengerId === action.passengerId
        ) {
          seat.passengerId = null;
        }
      });
      return seats;
    }

    case seatActionTypes.UNSELECT_SEAT: {
      let seats = new Map(state);
      seats.forEach((seat, id) => {
        if (id === action.seatId) {
          seat.passengerId = null;
        }
      });
      return seats;
    }

    default:
      return state;
  }
}
