import * as types from '../constants/PlaneActionTypes';

const defaultState = new Map();

export default function seatTypesReducer(state = defaultState, action) {
  switch (action.type) {
    case types.FETCH_CONFIGURATION:
      const seatTypes = action.res.data.definitions.seat_types;
      return new Map(seatTypes.map(type => [type.id, type]));
    default:
      return state;
  }
}
