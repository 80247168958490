import React, { Component } from 'react';
import { Bem } from 'react-bem-classes';
import { Link } from '_helpers/link';
import MediaQuery from 'react-responsive';
import './index.scss';

export default class HeaderLogo extends Component {
  render() {
    const {
      allCmsKeys,
      linkToPage,
      topShopCode,
      carrierHasMultipleLogos,
      loggedIn,
      carrierLogoCentered,
      carrierLogoSpecAligned,
      carrierLogoLandscapeTabletSpecAligned,
      carrierLogoPortraitTabletSpecAligned,
      carrierLogoBox,
    } = this.props;

    const bem = new Bem('headerLogo');

    const airlineLogoStyle = {
      height: allCmsKeys.LOGIN_PAGE_AIRLINE_LOGO_HEIGHT_PX
        ? `${allCmsKeys.LOGIN_PAGE_AIRLINE_LOGO_HEIGHT_PX}px`
        : null,
      marginTop: allCmsKeys.LOGIN_PAGE_AIRLINE_LOGO_TOPMARGIN_PX
        ? `${allCmsKeys.LOGIN_PAGE_AIRLINE_LOGO_TOPMARGIN_PX}px`
        : null,
    };

    return (
      <Link toPageId={linkToPage} className={bem.block()}>
        {carrierHasMultipleLogos ? (
          <div>
            <img
              className={bem.element('brandImage', { mobile: true })}
              src={
                allCmsKeys[`LOGIN_PAGE_AIRLINE_LOGO_MOBILE_URL_${topShopCode}`]
                  ? allCmsKeys[
                      `LOGIN_PAGE_AIRLINE_LOGO_MOBILE_URL_${topShopCode}`
                    ]
                  : allCmsKeys.LOGIN_PAGE_AIRLINE_LOGO_MOBILE_URL
              }
              style={
                allCmsKeys.LOGIN_PAGE_AIRLINE_LOGO_MOBILE_HEIGHT_PX
                  ? {
                      height: `${
                        allCmsKeys.LOGIN_PAGE_AIRLINE_LOGO_MOBILE_HEIGHT_PX
                      }px`,
                    }
                  : null
              }
            />
            <img
              className={bem.element('brandImage', {
                desktop: true,
                carrierLogoBox,
              })}
              src={
                allCmsKeys[`LOGIN_PAGE_AIRLINE_LOGO_URL_${topShopCode}`]
                  ? allCmsKeys[`LOGIN_PAGE_AIRLINE_LOGO_URL_${topShopCode}`]
                  : allCmsKeys.LOGIN_PAGE_AIRLINE_LOGO_URL
              }
              style={airlineLogoStyle}
            />

            <div
              className={bem.element('tourOperatorBlock', {
                carrierLogoCentered,
                carrierLogoLandscapeTabletSpecAligned,
                carrierLogoPortraitTabletSpecAligned,
                carrierLogoSpecAligned: !!carrierLogoSpecAligned,
              })}
              style={
                carrierLogoSpecAligned
                  ? { marginBottom: `${carrierLogoSpecAligned}px` }
                  : null
              }
            >
              <span className={bem.element('airlineText')}>
                {allCmsKeys.LOGIN_PAGE_AIRLINE_TEXT}
              </span>
              <MediaQuery query={SCREENS_MEDIA.mobileAndSmaller}>
                <img
                  className={bem.element('topLogoImage')}
                  src={allCmsKeys.LOGIN_PAGE_TOP_LOGO_URL}
                  style={
                    allCmsKeys.LOGIN_PAGE_TOP_LOGO_MOBILE_HEIGHT_PX
                      ? {
                          height: `${
                            allCmsKeys.LOGIN_PAGE_TOP_LOGO_MOBILE_HEIGHT_PX
                          }px`,
                        }
                      : null
                  }
                />
              </MediaQuery>
              <MediaQuery
                minWidth={LAYOUT_BREAKS.portraitTablet}
                maxWidth={LAYOUT_BREAKS.desktop - 1}
              >
                <img
                  className={bem.element('topLogoImage')}
                  src={allCmsKeys.LOGIN_PAGE_TOP_LOGO_URL}
                  style={
                    allCmsKeys.LOGIN_PAGE_TOP_LOGO_TABLET_HEIGHT_PX
                      ? {
                          height: `${
                            allCmsKeys.LOGIN_PAGE_TOP_LOGO_TABLET_HEIGHT_PX
                          }px`,
                        }
                      : null
                  }
                />
              </MediaQuery>
              <MediaQuery query={SCREENS_MEDIA.desktopAndLarger}>
                <img
                  className={bem.element('topLogoImage')}
                  src={allCmsKeys.LOGIN_PAGE_TOP_LOGO_URL}
                  style={
                    allCmsKeys.LOGIN_PAGE_TOP_LOGO_HEIGHT_PX
                      ? {
                          height: `${
                            allCmsKeys.LOGIN_PAGE_TOP_LOGO_HEIGHT_PX
                          }px`,
                        }
                      : null
                  }
                />
              </MediaQuery>
            </div>
          </div>
        ) : (
          <img
            className={bem.element('brandImage', { isLoginPage: !loggedIn })}
            src={allCmsKeys.LOGIN_PAGE_LOGO_URL}
          />
        )}
      </Link>
    );
  }
}
