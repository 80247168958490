import React, { Component } from 'react';
import bem from 'react-bem-classes';
import { connect } from 'react-redux';
import './index.scss';

import { totalPriceSelector } from 'serviceInBasket/helpers/selectors';
import {
  currentLangSelector,
  allCMSKeysRawSelector,
  localeDataSelector,
} from 'languages/selectors';

import MediaQuery from 'react-responsive';
import { Modal } from 'layouts/modal';
import AttachInfantForm from 'passengers/components/attachInfantForm';

import PageMybooking from 'pageMybooking/containers';
import MenuNavigation from 'layouts/pageHeader/components/menuNavigation';
import LanguageList from 'layouts/pageHeader/components/languageList';
import HeaderLogo from 'layouts/pageHeader/components/headerLogo';
import MenuMisc from 'layouts/pageHeader/components/menuMisc';
import { MobileHeaderMenu } from 'layouts/pageHeader/components/mobileHeaderMenu';
import 'layouts/header/index.scss';
import BoardingPassesDownload from 'check-in/containers/boardingPasses';
import PropTypes from 'prop-types';
import { alreadyCheckedInOrBoughtSeatsSegmentsSelector } from 'check-in/helpers/alreadyCheckinOrBoughtSelector';
import { allSegmentsDepartedSelector } from 'segments/helpers/selectors';
import { Text } from 'languages/components/text';
import { selectCheckInSegments } from 'check-in/helpers/selectors';
import { selectUpToDateBasket } from '../../../basket/selectors/selectBasket';
import { selectAllServices } from '../../../services/selectors';

@connect(
  state => ({
    allCmsKeys: allCMSKeysRawSelector(state),
    currentLanguage: currentLangSelector(state),
    services: selectAllServices(state),
    basket: selectUpToDateBasket(state),
    basketTotalPrice: totalPriceSelector(state),
    checkIn: selectCheckInSegments(state),
    moveMyBooking: state.init.paxshopInitParams.myBookingAsIcon,
    carrierHasMultipleLogos:
      !!state.auth.authData.data &&
      state.auth.authData.data.carrierHasMultipleLogos,
    carrierLogoCentered:
      !!state.auth.authData.data &&
      state.auth.authData.data.carrierLogoCentered,
    carrierLogoSpecAligned:
      !!state.auth.authData.data &&
      state.auth.authData.data.carrierLogoSpecAligned,
    carrierLogoLandscapeTabletSpecAligned:
      !!state.auth.authData.data &&
      state.auth.authData.data.carrierLogoLandscapeTabletSpecAligned,
    carrierLogoPortraitTabletSpecAligned:
      !!state.auth.authData.data &&
      state.auth.authData.data.carrierLogoPortraitTabletSpecAligned,
    carrierLogoBox:
      !!state.auth.authData.data && state.auth.authData.data.carrierLogoBox,
    topShopCode:
      !!state.init.paxshopData.data && state.init.paxshopData.data.topShopCode,
    enableWizard: state.init.paxshopData.data.wizardMenu,
    isHomePage: state.routing.paxshopPage.pageId === 'LOGIN',
    infantModalVisible:
      !!state.auth.authData.data &&
      !state.auth.authData.data.booking.infantRefInfo.allInfantsHaveRef,
    alreadyCheckinOrBoughtSeats: alreadyCheckedInOrBoughtSeatsSegmentsSelector(
      state
    ),
    allSegmentsDeparted: allSegmentsDepartedSelector(state),
    isLoggedInAnotherBooking: state.init.isLoggedInAnotherBooking,
    localeData: localeDataSelector(state),
    enableNewCheckin:
      state.init.paxshopData.data.enableNewCheckin &&
      state.init.paxshopData.data.wizardMenu,
  }),
  dispatch => ({
    dispatch,
  })
)
@bem({
  block: 'pxHeader',
})
class PageHeader extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    loggedIn: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      languageMenu: false,
    };
  }

  languageTrigger = e => {
    this.setState({ languageMenu: !this.state.languageMenu });
  };

  render() {
    const {
      allCmsKeys,
      services,
      currentLanguage,
      basket,
      basketTotalPrice,
      checkIn,
      alreadyCheckinOrBoughtSeats,
      allSegmentsDeparted,
      moveMyBooking,
      topShopCode,
      carrierHasMultipleLogos,
      carrierLogoCentered,
      carrierLogoSpecAligned,
      carrierLogoLandscapeTabletSpecAligned,
      carrierLogoPortraitTabletSpecAligned,
      carrierLogoBox,
      enableWizard,
      store,
      isHomePage,
      isLoggedInAnotherBooking,
      infantModalVisible,
      enableNewCheckin,
    } = this.props;

    const openCheckin = checkIn.find(c => c.checkinStatus === 'AVAILABLE');
    const openAPIS = checkIn.find(
      item => item.checkinStatus === 'AVAILABLE_WITH_APIS'
    );

    const { loggedIn } = this.context;

    const { languageMenu } = this.state;

    const styles =
      loggedIn &&
      !!allCmsKeys.HEADER_BACKGROUND_IMAGE_URL &&
      allCmsKeys.HEADER_BACKGROUND_IMAGE_URL.trim() !== ''
        ? {
            backgroundImage: `url(${allCmsKeys.HEADER_BACKGROUND_IMAGE_URL})`,
          }
        : {};

    return (
      <div
        className={`topHeader menu-nav ${
          (openCheckin && enableNewCheckin) || openAPIS
            ? 'topHeaderCheckInMargin'
            : ''
        }`}
        style={styles}
      >
        <div className="topLineContainer">
          <div className="topLevelContainer clearfix">
            <div className="row">
              <HeaderLogo
                linkToPage={loggedIn ? 'my_booking' : 'home'}
                loggedIn={loggedIn}
                allCmsKeys={allCmsKeys}
                topShopCode={topShopCode}
                carrierHasMultipleLogos={carrierHasMultipleLogos}
                carrierLogoCentered={carrierLogoCentered}
                carrierLogoSpecAligned={carrierLogoSpecAligned}
                carrierLogoLandscapeTabletSpecAligned={
                  carrierLogoLandscapeTabletSpecAligned
                }
                carrierLogoPortraitTabletSpecAligned={
                  carrierLogoPortraitTabletSpecAligned
                }
                carrierLogoBox={carrierLogoBox}
              />

              <MediaQuery query={SCREENS_MEDIA.portraitTabletAndLarger}>
                <MenuMisc
                  basket={basket}
                  basketTotalPrice={basketTotalPrice}
                  checkIn={checkIn}
                  allSegmentsDeparted={allSegmentsDeparted}
                  languageMenu={languageMenu}
                  languageTrigger={this.languageTrigger}
                  currentLanguage={currentLanguage}
                  enableWizard={enableWizard}
                  carrierHasMultipleLogos={carrierHasMultipleLogos}
                  alreadyCheckinOrBoughtSeats={alreadyCheckinOrBoughtSeats}
                  allCMSKeys={this.props.localeData.cmsKeys}
                />
              </MediaQuery>

              <MediaQuery query={SCREENS_MEDIA.mobileAndSmaller}>
                <MobileHeaderMenu basketItems={basket} />
              </MediaQuery>
            </div>
          </div>
        </div>

        <div className="topLevelContainer clearfix">
          {loggedIn && services.length !== 0 && (
            <MenuNavigation
              haveHeaderBackImage={
                allCmsKeys.HEADER_BACKGROUND_IMAGE_URL !== ''
              }
            />
          )}
        </div>

        <LanguageList
          typeModifier="mobile"
          key={currentLanguage}
          languageMenu={languageMenu}
          currentLanguage={currentLanguage}
          languageTrigger={this.languageTrigger.bind(this)}
        />

        {moveMyBooking && (
          <Modal ref={c => (this.MybookingModal = c)} plain>
            <div className={this.element('myBookingModal')}>
              <PageMybooking store={store} />
            </div>
          </Modal>
        )}
        {!isHomePage && (
          <Modal
            ref={c => (this.BoardingPassesModal = c)}
            newDesign
            plain
            mobileFullScreen
            popupRootClass="boardingPassesDownloadPopup"
          >
            <BoardingPassesDownload store={store} />
          </Modal>
        )}

        <Modal
          stateless
          centered
          dontCloseOnOutsideClick
          noCloseButton
          opened={infantModalVisible}
        >
          <AttachInfantForm store={store} />
        </Modal>

        <Modal
          stateless
          dontCloseOnOutsideClick
          noCloseButton
          newDesign
          opened={isLoggedInAnotherBooking}
        >
          <div className={this.element('anotherTabLoginAlertModal')}>
            <div className={this.element('alertText')}>
              <Text useSpan>ANOTHER_TAB_LOGIN_ALERT_TEXT</Text>
            </div>
            <div style={{ textAlign: 'center' }}>
              <button
                className={this.element('reloadButton')}
                onClick={() => {
                  window.location.reload();
                }}
              >
                <Text useSpan>ANOTHER_TAB_LOGIN_ALERT_RELOAD_BUTTON</Text>
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default PageHeader;
