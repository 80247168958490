import React, { Component } from 'react';
import { connect } from 'react-redux';
import Page, { Content } from 'layouts/page';
import { noInfantSelector } from 'passengers/helpers/selectors';
import CartSegmentsList from 'pageCart/components/segmentsList';
import {
  servicesForBasketSelector,
  seatValidationErrorSelector,
} from 'serviceInBasket/helpers/selectors';
import { cartCurrencySelector } from 'authorization/selectors';
import CartTotal from 'pageCart/components/cartTotal';
import CartEmptyList from 'pageCart/components/cartEmptyList';
import { segmentsSelector } from 'segments/helpers/selectors';
import { CurrentPageWizardHeader } from 'mainMenu/containers/currentPageWizardHeader';
import { authOnly } from 'authorization/helpers/authOnly';
import { BasketErrors } from 'pageCart/components/bascketErrors';
import { selectAllServices, servicesGroupsSelector } from 'services/selectors';
import BasketWrapper from 'basket/containers';
import { selectUpToDateBasket } from '../../basket/selectors/selectBasket';

@authOnly()
@connect(state => ({
  services: selectAllServices(state),
  passengersList: noInfantSelector(state),
  serviceGroups: servicesGroupsSelector(state),
  currency: cartCurrencySelector(state),
  segmentsList: segmentsSelector(state),
  basketInfo: servicesForBasketSelector(state),
  basket: selectUpToDateBasket(state),
  seatingErrors: seatValidationErrorSelector(state),
}))
class PageCart extends Component {
  render() {
    const {
      store,
      basketInfo,
      currency,
      removeServiceAction,
      basket,
      seatingErrors,
      segmentsList,
      removeItem,
      passengersList,
    } = this.props;

    const nextStepCondition = basketInfo.length !== 0;

    const totalAmount = basketInfo.reduce((pv, cv) => pv + cv.price, 0);

    return (
      <Page>
        <CurrentPageWizardHeader />
        {!nextStepCondition ? (
          <CartEmptyList />
        ) : (
          <CartSegmentsList
            {...this.props}
            removeServiceAction={service => removeItem(service)}
          />
        )}
        <Content>
          <CartTotal
            nextStepCondition={nextStepCondition}
            currency={currency}
            totalAmount={totalAmount}
          />
          <BasketErrors errors={seatingErrors} segments={segmentsList} />
        </Content>
      </Page>
    );
  }
}

export default function PageCartWrapper() {
  return (
    <BasketWrapper>
      <PageCart />
    </BasketWrapper>
  );
}
