import React from 'react';

import bem from 'react-bem-classes';
import './index.scss';

import { Price } from 'commonBlocks/components/price';
import { Text } from 'languages/components/text';
import { Header } from 'layouts/page';

@bem({
  block: 'confirmationPageHeader',
})
class ConfirmationPageHeader extends React.Component {
  render() {
    const { totalPrice, currency, headerText } = this.props;

    return (
      <div className={this.block()}>
        <div className={this.element('titleContainer')}>
          <div className={this.element('icon')} />
          <div className={this.element('title')}></div>
          <Header>
            <Text>{headerText}</Text>
          </Header>
        </div>
        {totalPrice > 0 && currency && (
          <div className={this.element('basketInfo')}>
            <Text>CONFIRMATION_PAGE_PAID_TEXT</Text>
            <Price
              className={this.element('totalPrice')}
              price={totalPrice}
              currency={currency}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ConfirmationPageHeader;
