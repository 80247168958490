import React, { Component } from 'react';
import bem from 'react-bem-classes';
import './index.scss';
import { Text } from 'languages/components/text';

@bem({
  block: 'cartEmptyList',
})
class CartEmptyList extends Component {
  render() {
    return (
      <div className={this.block()}>
        <Text className={this.element('title')}>CART_IS_EMPTY</Text>
      </div>
    );
  }
}

export default CartEmptyList;
