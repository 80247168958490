import { combineReducers } from 'redux';
import mainReducer from './mainReducer';
import cms from './cmsKeys';
import aircraft from './aircraftReducer';
import seatsMap from './seatsMapReducer';
import seatTypes from './seatTypesReducer';
import seatImages from './seatImagesReducer';
import services from './servicesReducer';
import seatAllocations from './seatAllocations';
import passengers from './passengersRedcuer';
import seats from './seatsReducer';
import suggestedSeats from './suggestedSeats';
import selectionLock from './selectionLockReducer';

const rootReducer = combineReducers({
  mainReducer,
  cms,
  aircraft,
  selectionLock,
  seatAllocations,
  seatsMap,
  seatTypes,
  seatImages,
  services,
  passengers,
  seats,
  suggestedSeats,
});

export default rootReducer;
