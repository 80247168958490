import React, { Component } from 'react';
import bem from 'react-bem-classes';
import { Text } from 'languages/components/text';
import { Price } from 'commonBlocks/components/price';
import './index.scss';
import PropTypes from 'prop-types';
@bem({
  block: 'BasketDropdownFooter',
})
export class BasketDropdownFooter extends Component {
  static propTypes = {
    currency: PropTypes.string,
    total: PropTypes.number,
    disabled: PropTypes.bool,
    checkoutClicked: PropTypes.func,
  };

  render() {
    const { disabled, currency, total } = this.props;

    return (
      <div className={this.block()}>
        <div className={this.element('info')}>
          <Text className={this.element('title')}>BASKET_TOTAL</Text>
          <Price
            className={this.element('price')}
            price={total}
            currency={currency}
          />
        </div>
        <button
          onClick={this.props.checkoutClicked}
          className={this.element('button')}
          disabled={disabled}
        >
          <Text>BASKET_CHECKOUT_BUTTON</Text>
        </button>
      </div>
    );
  }
}
