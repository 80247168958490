import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BasketDropdownPanelTooltip } from 'basket/containers/dropdown';
import { hasDescendant } from '_helpers/domHelper';
import { getBodyOffset } from '_helpers/domHelper';
import bem from 'react-bem-classes';
import { goToPage } from 'routing/routing';
import { getDialogs } from 'dialogs/helpers';

import './index.scss';
import PropTypes from 'prop-types';
@bem({
  block: 'basketTooltipButton',
})
export class BasketTooltipButton extends React.Component {
  static propTypes = {
    checkoutClicked: PropTypes.func,
    shouldOpenTooltip: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.shouldRepositionTooltip = false;
    this.state = {
      tooltipOpen: false,
    };
    this.setTooltipRoot = this.setTooltipRoot.bind(this);
  }

  buttonClicked() {
    const { shouldOpenTooltip } = this.props;
    if (typeof shouldOpenTooltip === 'function') {
      if (!shouldOpenTooltip()) return;
    }

    if (!this.state.tooltipOpen) {
      this.shouldRepositionTooltip = true;
    }
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  }

  requestTooltipClose(event) {
    if (!this.state.tooltipOpen) return;

    const confirmDialog = getDialogs().confirmDeletingDialog;

    const shouldClose =
      !!confirmDialog &&
      !confirmDialog.refs.rootModal.state.isOpen &&
      (event && event.target
        ? !hasDescendant(event.target, this.refs.buttonElement)
        : true);
    if (shouldClose) {
      this.setState({ tooltipOpen: false });
    }
  }

  checkoutClicked() {
    if (!this.state.tooltipOpen) return;
    this.setState({ tooltipOpen: false });
    goToPage('cart');
  }

  repositionTooltipIfRequired(elem) {
    if (!this.shouldRepositionTooltip) {
      return;
    }

    this.shouldRepositionTooltip = false;
    const self = ReactDOM.findDOMNode(this);

    const tooltip = ReactDOM.findDOMNode(elem);

    if (!self || !tooltip) {
      return;
    }

    const selfPosition = getBodyOffset(self);

    const tooltipLeft = self.offsetWidth - tooltip.offsetWidth / 2;

    const tooltipTop = self.offsetHeight;

    tooltip.style.top = `${tooltipTop}px`;
    tooltip.style.left = `${tooltipLeft}px`;
  }

  setTooltipRoot(elem) {
    if (elem) {
      this.repositionTooltipIfRequired(elem);
    }
  }

  render() {
    const { className } = this.props;
    return (
      <div className={`${this.block()} ${className}`}>
        <div ref="buttonElement" onClick={() => this.buttonClicked()}>
          {React.cloneElement(this.props.children, {
            isActive: this.state.tooltipOpen,
          })}
        </div>

        <div
          className={this.element('tooltipRoot', {
            hidden: !this.state.tooltipOpen,
          })}
          ref={this.setTooltipRoot}
        >
          {this.state.tooltipOpen && (
            <BasketDropdownPanelTooltip
              handleClickOutside={event => this.requestTooltipClose(event)}
              checkoutClicked={() => this.checkoutClicked()}
            />
          )}
        </div>
      </div>
    );
  }
}
