import React from 'react';
import { Text } from 'languages/components/text';

import bem from 'react-bem-classes';
import './index.scss';

import { menuVisualData } from 'mainMenu/helpers/sharedData';
import { goToPage } from 'routing/routing';
import { BasketTooltipButton } from 'basket/components/basketTooltipButton';
import { WizardCartButton } from 'mainMenu/components/wizardCartButton';
import { WizardTooltip } from 'mainMenu/components/wizardTooltip';
import { Header } from 'layouts/page';
import Spinner from 'react-spinner';
import PropTypes from 'prop-types';
import { scrollToTop } from '_helpers/domHelper';

@bem({
  block: 'wizardHeader',
  modifiers: ['wizardEnabled'],
})
export class WizardHeader extends React.Component {
  static propTypes = {
    mainMenuItem: PropTypes.object,
    totalPrice: PropTypes.number,
    currency: PropTypes.string,
    wizardEnabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      basketOpen: false,
    };
  }

  backClicked() {
    const { mainMenuItem } = this.props;
    if (!mainMenuItem || !mainMenuItem.prevAllowedItem) {
      return;
    }

    goToPage(mainMenuItem.prevAllowedItem.paxshopPageId);
    scrollToTop();
  }

  nextClicked() {
    const {
      mainMenuItem,
      nextStepAvailabilityStatus,
      isValidationPending,
    } = this.props;
    if (!mainMenuItem || !mainMenuItem.nextAllowedItem || isValidationPending) {
      return;
    }

    if (nextStepAvailabilityStatus) {
      const tooltip = this._tooltip.getInstance();
      tooltip.showTooltip().then(
        () => {
          nextStepAvailabilityStatus.onResolveToolTip
            ? nextStepAvailabilityStatus.onResolveToolTip()
            : goToPage(mainMenuItem.nextAllowedItem.paxshopPageId);
          scrollToTop();
        },
        () => {
          tooltip.hideTooltip();
          nextStepAvailabilityStatus.onRejectToolTip &&
            nextStepAvailabilityStatus.onRejectToolTip();
        }
      );
      return;
    }

    goToPage(mainMenuItem.nextAllowedItem.paxshopPageId);
    scrollToTop();
  }

  handleTooltipClickOutside = () => {
    this._tooltip.getInstance().hideTooltip();
  };

  render() {
    const {
      mainMenuItem,
      totalPrice,
      currency,
      wizardEnabled,
      nextStepAvailabilityStatus,
      isValidationPending,
    } = this.props;

    if (!mainMenuItem) return null;

    const visualData = menuVisualData[mainMenuItem.id];

    const isCheckout =
      mainMenuItem.id === 'CHECKOUT' ||
      mainMenuItem.id === 'CONFIRMATION' ||
      mainMenuItem.id === 'PAYMENT';

    return (
      <div className={this.block({ wizardDisabled: !wizardEnabled })}>
        <div className={this.element('topRow')}>
          {wizardEnabled && (
            <div
              className={this.element('backArrow', {
                hidden: !mainMenuItem.prevAllowedItem,
              })}
              onClick={this.backClicked.bind(this)}
            >
              &larr;
            </div>
          )}

          {wizardEnabled && mainMenuItem.index !== null && (
            <div className={this.element('stepIndex')}>
              <span>{1 + mainMenuItem.index}</span>
            </div>
          )}

          {visualData && (
            <Header headerClass={this.element('headerTitle')}>
              <Text>{visualData.title}</Text>
            </Header>
          )}

          {wizardEnabled && (
            <div
              ref="nextButton"
              className={this.element('nextButton', {
                hidden: !mainMenuItem.nextItem,
                disabled: !mainMenuItem.nextAllowedItem,
                isload: isValidationPending,
              })}
            >
              <Text useSpan onClick={() => this.nextClicked()}>
                NEXT_BUTTON
              </Text>
              {isValidationPending && (
                <Spinner className={this.element('spinner')} />
              )}
              {!!nextStepAvailabilityStatus && (
                <WizardTooltip
                  handleClickOutside={this.handleTooltipClickOutside}
                  ref={c => (this._tooltip = c)}
                  className={this.element('tooltipMessage')}
                  position="bottom"
                  leftover
                  nextStepAvailabilityStatus={nextStepAvailabilityStatus}
                />
              )}
            </div>
          )}

          {wizardEnabled && (
            <div className={this.element('cart', { hidden: isCheckout })}>
              <BasketTooltipButton
                shouldOpenTooltip={() =>
                  !this.refs.wizardCartButton.linkVisible()
                }
              >
                <WizardCartButton
                  totalPrice={totalPrice}
                  currency={currency}
                  ref="wizardCartButton"
                />
              </BasketTooltipButton>
            </div>
          )}
        </div>

        {visualData && (
          <div
            className={this.element('descRow', {
              hidden: !visualData.description,
            })}
          >
            <Text>{visualData.description}</Text>
          </div>
        )}
      </div>
    );
  }
}
