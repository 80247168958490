import './index.scss';
import PropTypes from 'prop-types';
import React from 'react';
import { Bem } from 'react-bem-classes';
import { Field } from 'redux-form';
import { filterInputProps } from '_helpers/reduxFormHelpers';

export class RawSelectInput extends React.Component {
  componentDidUpdate() {
    const { onFieldRendered, meta } = this.props;
    if (onFieldRendered) {
      onFieldRendered(meta, this.refs.root);
    }
  }

  componentDidMount() {
    const { onFieldRendered, meta } = this.props;
    if (onFieldRendered) {
      onFieldRendered(meta, this.refs.root);
    }
  }

  render() {
    const {
      input,
      meta: { touched, error, invalid },
      cmsKeys,
      disabled,
      options,
      getOptionId,
      getOptionText,
      allowEmptyValue,
      emptyIfDisabled,
      placeholder,
      small,
    } = this.props;

    const addEmptyOption =
      placeholder || allowEmptyValue || (disabled && emptyIfDisabled);

    const bem = new Bem('selectInput');

    const fieldBem = new Bem('field');

    const shouldDisplayError = touched && invalid;
    return (
      <div
        ref="root"
        className={fieldBem.block({ invalid: shouldDisplayError })}
        data-error={shouldDisplayError && error ? cmsKeys[error] || error : ' '}
      >
        <select
          className={`${bem.block({ small })}`}
          disabled={disabled}
          {...filterInputProps(input)}
          value={disabled && emptyIfDisabled ? '' : input.value}
        >
          {!addEmptyOption ? null : placeholder ? (
            <option key="__default" disabled hidden value="">
              {placeholder}
            </option>
          ) : (
            <option key="__default" value="" />
          )}
          {disabled && emptyIfDisabled
            ? null
            : options.map((op, index) => (
                <option
                  key={`${getOptionId(op)}-${index}`}
                  value={getOptionId(op)}
                >
                  {getOptionText(op)}
                </option>
              ))}
        </select>
        <span className={bem.element('arrow')} />
      </div>
    );
  }
}

export default class SelectInput extends React.Component {
  static propTypes = {
    field: PropTypes.object,
    disabled: PropTypes.bool,
    options: PropTypes.array,
    getOptionId: PropTypes.func,
    getOptionText: PropTypes.func,
    allowEmptyValue: PropTypes.bool,
    emptyIfDisabled: PropTypes.bool,
    onFieldRendered: PropTypes.func,
    placeholder: PropTypes.string,
  };

  render() {
    const {
      name,
      validate,
      disabled,
      emptyIfDisabled,
      onFieldRendered,
      options,
      getOptionId,
      getOptionText,
      allowEmptyValue,
      placeholder,
      cmsKeys,
      ...rest
    } = this.props;
    return (
      <Field
        withRef
        placeholder={placeholder}
        name={name}
        validate={validate}
        onFieldRendered={onFieldRendered}
        disabled={disabled}
        emptyIfDisabled={emptyIfDisabled}
        options={options}
        getOptionId={getOptionId}
        getOptionText={getOptionText}
        allowEmptyValue={allowEmptyValue}
        cmsKeys={cmsKeys}
        component={RawSelectInput}
        {...rest}
      />
    );
  }
}
