import * as types from '../constants/SeatActionTypes';

export function toggleSeat(seat, passengerId) {
  return {
    type: types.TOGGLE_SEAT,
    seat,
    passengerId,
    triggerEvent: {
      type: 'seat:select',
      data: {
        seat,
        passengerId,
      },
    },
  };
}

export function hoverSeat(seat) {
  return {
    type: types.HOVER_SEAT,
    seat,
  };
}

export function seatsChange(seats) {
  return {
    type: types.SEATS_CHANGE,
    seats,
  };
}

export function changeSuggestedSeats(seats) {
  return {
    type: types.SUGGESTED_SEATS_CHANGE,
    seats,
  };
}
