import './index.scss';

import React, { Component } from 'react';
import bem from 'react-bem-classes';

import { Text } from 'languages/components/text';

@bem({
  block: 'groupList',
})
class GroupList extends React.Component {
  groupSwitch(group) {
    const { setCurrentGroup, afterSwitchCallback } = this.props;

    setCurrentGroup(group);
    if (afterSwitchCallback && typeof afterSwitchCallback === 'function')
      afterSwitchCallback();
  }

  render() {
    const { groups, currentGroup } = this.props;

    return (
      <div className={this.block()}>
        {groups.map(group => (
          <div
            className={this.element('item', {
              current: group === currentGroup,
            })}
            onClick={this.groupSwitch.bind(this, group)}
            key={group}
          >
            <span className="left-arrow" />
            <Text className={this.element('itemContent')}>{`SERVICES_${(
              group || 'OTHER'
            ).toUpperCase()}_GROUP`}</Text>
            <span className="right-arrow" />
          </div>
        ))}
      </div>
    );
  }
}

export default GroupList;
