import http from 'axios';
import { appConfig } from 'config';
import { axiosConfigSelector } from 'authorization/selectors';
import { currentLangSelector } from 'languages/selectors';

export function getDeepLink() {
  return (dispatch, getState) => {
    const state = getState();

    const currentLang = currentLangSelector(state);

    return dispatch({
      type: 'GET_DEEP_LINK',
      promise: http.get(
        `${appConfig.apiUrl}/affiliate/duty-free?locale=${currentLang}`,
        axiosConfigSelector(state)
      ),
    });
  };
}
