import React from 'react';
import PropTypes from 'prop-types';

import bem, { Bem } from 'react-bem-classes';
import { menuVisualData } from 'mainMenu/helpers/sharedData';
import LanguageList from 'layouts/pageHeader/components/languageList';
import MenuMisc from 'layouts/pageHeader/components/menuMisc';

import './index.scss';

export class MobileMenuContent extends React.PureComponent {
  static propTypes = {
    mainMenuItems: PropTypes.array,
    currentMenuItem: PropTypes.object,
    currentLanguage: PropTypes.string,
    allCmsKeys: PropTypes.object,
    wizardMenu: PropTypes.bool,
    checkIn: PropTypes.array,
    alreadyCheckinOrBoughtSeats: PropTypes.bool,
    allSegmentsDeparted: PropTypes.bool,

    closeMenu: PropTypes.func,
  };

  static contextTypes = {
    navigateToPage: PropTypes.func.isRequired,
    languages: PropTypes.array.isRequired,
    basket: PropTypes.array.isRequired,
    basketTotalPrice: PropTypes.number.isRequired,
    enableNewCheckin: PropTypes.bool.isRequired,
    moveMyBooking: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
  };

  render() {
    const {
      mainMenuItems,
      currentMenuItem,
      checkIn,
      alreadyCheckinOrBoughtSeats,
      allSegmentsDeparted,
      currentLanguage,
      allCmsKeys,
      wizardMenu,
      closeMenu,
    } = this.props;

    const { languages, navigateToPage, loggedIn } = this.context;

    const bem = new Bem('mobileMenuContent');

    return (
      <div className={bem.block()}>
        <div className={bem.element('closeButton')} onClick={closeMenu}>
          <span className="icon close-light" />
        </div>

        {/* navigation */}
        {loggedIn && (
          <NavigationBlock
            allCmsKeys={allCmsKeys}
            currentMenuItem={currentMenuItem}
            mainMenuItems={mainMenuItems}
            navigateToPage={navigateToPage}
            wizardMenu={wizardMenu}
          />
        )}

        {/* misc (ex MiscBlock) */}
        <MenuMisc
          isMobile
          currentLanguage={currentLanguage}
          checkIn={checkIn}
          alreadyCheckinOrBoughtSeats={alreadyCheckinOrBoughtSeats}
          allSegmentsDeparted={allSegmentsDeparted}
          allCMSKeys={allCmsKeys}
        />

        {/* languages (ex LangsBlock) */}
        <LanguageList
          typeModifier="mobileMenuBlock"
          languages={languages}
          currentLanguage={currentLanguage}
        />
      </div>
    );
  }
}

class NavigationBlock extends React.PureComponent {
  static propTypes = {
    mainMenuItems: PropTypes.array,
    currentMenuItem: PropTypes.object,
    allCmsKeys: PropTypes.object,
    wizardMenu: PropTypes.bool,

    navigateToPage: PropTypes.func,
  };

  render() {
    const {
      mainMenuItems,
      currentMenuItem,
      allCmsKeys,
      wizardMenu,
      navigateToPage,
    } = this.props;

    const bem = new Bem('mobileMenuContent');

    return (
      <ul className={bem.element('menuBlock')}>
        {mainMenuItems
          .filter(page => page.visible)
          .map(page => (
            <NavigationItem
              key={page.id}
              allCmsKeys={allCmsKeys}
              current={currentMenuItem && currentMenuItem.id === page.id}
              navigateToPage={navigateToPage}
              wizardMenu={wizardMenu}
              pageEnabled={page.enabled}
              pageId={page.id}
              pageIndex={page.index}
              pageType={page.type}
              paxshopPageId={page.paxshopPageId}
            />
          ))}
      </ul>
    );
  }
}

@bem({
  block: 'mobileMenuContent',
})
class NavigationItem extends React.PureComponent {
  static propTypes = {
    pageEnabled: PropTypes.bool,
    pageId: PropTypes.string,
    pageType: PropTypes.string,
    pageIndex: PropTypes.number,
    current: PropTypes.bool,
    paxshopPageId: PropTypes.string,

    allCmsKeys: PropTypes.object,
    wizardMenu: PropTypes.bool,

    navigateToPage: PropTypes.func,
  };

  navigate = () => this.props.navigateToPage(this.props.paxshopPageId);

  render() {
    const {
      pageEnabled,
      pageId,
      pageType,
      pageIndex,
      current,
      allCmsKeys,
      wizardMenu,
    } = this.props;

    const bem = new Bem('mobileMenuContent');

    return (
      <li
        className={this.element('menuItem', {
          disabled: !pageEnabled,
          current,
        })}
        onClick={() => {
          pageEnabled && this.navigate();
        }}
      >
        <div className={this.element('menuItemIcon')}>
          <span
            className={menuVisualData[pageId].getIconClassName(allCmsKeys)}
          />
        </div>
        <div className={bem.element('menuItemMarker')}>
          {!wizardMenu ? null : (
            <div className={bem.element('wizardIcon', { type: pageType })} />
          )}
        </div>
        <div className={bem.element('menuItemCaption')}>
          {wizardMenu ? `${1 + pageIndex}. ` : ''}
          <span>{allCmsKeys[menuVisualData[pageId].title]}</span>
        </div>
      </li>
    );
  }
}
