import * as React from 'react';
import { appConfig } from 'config';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, FieldArray } from 'redux-form';
import { Text } from 'languages/components/text';
import { Bem } from 'react-bem-classes';
import TextInput from 'commonBlocks/components/textInput';
import SelectInput from 'commonBlocks/components/selectInput';
import * as norecActions from 'norec/actions';
import * as wizLogic from 'norec/wizardLogic';
import { defaultBackendErrorHandler } from '_helpers/backendError';
import {
  isValid,
  validate,
  validateNotEmpty,
  regexValidator,
} from '_helpers/reduxFormHelpers';
import { createAuthFinishPromise } from 'authorization/selectors';
import * as authActions from 'authorization/actions';
import { allCMSKeysRawSelector } from 'languages/selectors';

const formDef = wizLogic.FORMS_DEF.PASSENGERS_FORM;

const NAME_REGEX = /[-!$%^&*()_+|\\~=`{}\[\]:\/;<>?,.@#"'0-9]/;

const onlyLettersValidator = regexValidator(
  NAME_REGEX,
  false,
  'CMS_VALIDATION_ONLY_LETTERS'
);

const infantValidation = passengers => {
  const invalidTypes = ['INFANT'];

  const validTypes = ['MALE', 'FEMALE'];

  const infantsLength = passengers.filter(p =>
    invalidTypes.includes(p.passengerType)
  ).length;

  const adultsLength = passengers.filter(p =>
    validTypes.includes(p.passengerType)
  ).length;

  return passengers.length > 0 && infantsLength > adultsLength;
};

function validatePassengersForm(values) {
  return {
    passengers: values.passengers.map(passenger =>
      validate(passenger, {
        firstName: [validateNotEmpty, onlyLettersValidator],
        lastName: [validateNotEmpty, onlyLettersValidator],
        passengerType: [validateNotEmpty],
      })
    ),
  };
}
const bem = new Bem('modalNorec');

class RenderPassengers extends React.Component {
  render() {
    const {
      fields,
      meta: { touched, error, submitFailed },
      cmsKeys,
      onFieldRendered,
    } = this.props;

    return fields.map((passenger, passengerIdx) => (
      <div key={passengerIdx} className={`${bem.element('passengerRow')} row`}>
        <div className={`${bem.element('formCol')} col-md-4`}>
          <label>
            <Text useSpan>NOREC_POPUP_FIRST_NAME</Text>
          </label>
          <TextInput
            name={`${passenger}.firstName`}
            onFieldRendered={onFieldRendered}
          />
        </div>

        <div className={`${bem.element('formCol')} col-md-4`}>
          <label>
            <Text useSpan>NOREC_POPUP_LAST_NAME</Text>
          </label>
          <TextInput
            name={`${passenger}.lastName`}
            onFieldRendered={onFieldRendered}
          />
        </div>

        <div className={`${bem.element('formCol')} col-md-4`}>
          <label>
            <Text>NOREC_POPUP_SEX</Text>
          </label>
          <SelectInput
            allowEmptyValue
            name={`${passenger}.passengerType`}
            options={wizLogic.PASSENGER_GENDERS_LIST}
            getOptionId={st => st.id}
            getOptionText={st => cmsKeys[st.text] || st.text}
            onFieldRendered={onFieldRendered}
            cmsKeys={cmsKeys}
          />
        </div>

        <div className={`${bem.element('passengerDelButtonContainer')}`}>
          {fields.length <= 1 ? null : (
            <button
              className={`${bem.element('passengerDelButton')} btn`}
              type="button"
              onClick={() => fields.remove(passengerIdx)}
            >
              &ndash;
            </button>
          )}
        </div>
      </div>
    ));
  }
}

class PassengersForm extends React.Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.shouldScrollToInvalid = false;
  }

  nextSubmit = (values, dispatch) => {
    this.shouldScrollToInvalid = false;
    const localValidation = validatePassengersForm(values);
    if (!isValid(localValidation)) {
      this.shouldScrollToInvalid = true;
      return null;
    }

    const { store } = this.context;

    return new Promise((resolve, reject) => {
      const processedPassengers = values.passengers.map(passenger => ({
        firstName: passenger.firstName,
        lastName: passenger.lastName,
        passengerType: passenger.passengerType || null,
      }));

      dispatch(norecActions.finishPassengersForm(processedPassengers));

      const norecState = store.getState().norec;

      const bookingConfig = norecState.bookingConfig;

      const paxPartner = store.getState().init.paxshopAppCtx.paxPartner;

      const requestData = {
        bookingNumber: norecState.bookingNumber,
        resellerData: paxPartner ? paxPartner.resellerData : null,
        lastname:
          processedPassengers.length > 0
            ? processedPassengers[0].lastName
            : norecState.lastName || null,
        segments: norecState.segments
          .filter(segment => segment.enabled)
          .map(segment => {
            const beginningDeparture = segment.departures.find(
              dep => dep.departureId == segment.beginningDepartureId
            );
            const endingDeparture = segment.departures.find(
              dep => dep.departureId == segment.endingDepartureId
            );

            return {
              departureDate: segment.departureDate,
              flightNumber: segment.flightNumber.toUpperCase(),
              direction: segment.segmentIndex === 0 ? 'OUT' : 'IN',
              bookingClass: segment.bookingClassId,
              departureAirportCode: beginningDeparture
                ? beginningDeparture.departureAirport.code
                : null,
              arrivalAirportCode: endingDeparture
                ? endingDeparture.arrivalAirport.code
                : null,
            };
          }),
        passengers: norecState.passengers.map(passenger => ({
          firstName: passenger.firstName,
          lastName: passenger.lastName,
          sex: passenger.passengerType,
          age: wizLogic.PASSENGER_GENDERS[passenger.passengerType].age,
        })),
        paxshopId: appConfig.paxshopId,
        carrierCustomerId: norecState.carrierCustomerId,
        tourOperatorId:
          norecState.overriddenTourOperatorId ||
          (bookingConfig.tourOperators &&
          bookingConfig.tourOperators.length === 1
            ? bookingConfig.tourOperators[0].topId
            : null),
        referenceData: null,
      };

      dispatch(
        authActions.initiateLogin({
          authType: 'NOREC',
          norecSrc: requestData,
        })
      );

      const authFinishedPromise = createAuthFinishPromise(store);
      authFinishedPromise
        .then(res => {
          const freshNorecState = store.getState().norec;
          if (wizLogic.isStaleState(norecState, freshNorecState)) {
            return;
          }

          dispatch(norecActions.hideNorecDialog());
          resolve();
        })
        .catch(err => {
          const freshNorecState = store.getState().norec;
          if (wizLogic.isStaleState(norecState, freshNorecState)) {
            return;
          }

          defaultBackendErrorHandler(err);
          resolve();
        });
    });
  };

  backSubmit() {
    this.shouldScrollToInvalid = false;

    const { store } = this.context;

    const { formValues } = this.props;

    const processedPassengers = formValues.passengers.map(passenger => ({
      firstName: passenger.firstName,
      lastName: passenger.lastName,
      passengerType: passenger.passengerType || null,
    }));

    store.dispatch(norecActions.finishPassengersForm(processedPassengers));
    store.dispatch(norecActions.norecPrev());
  }

  onFieldRendered = (field, elem) => {
    if (this.shouldScrollToInvalid && field.invalid) {
      this.shouldScrollToInvalid = false;
      elem.parentNode.scrollIntoView(true);
    }
  };

  addPassenger = () => {
    this.refs.passengersArray
      .getRenderedComponent()
      .props.fields.push({ firstName: '', lastName: '', passengerType: '' });
  };

  render() {
    let _context;
    const { store } = this.context;

    const { handleSubmit, submitting, cmsKeys, formValues } = this.props;

    const infantsInvalid = infantValidation(formValues.passengers);

    const wrappedFormSubmit = ev => {
      this.shouldScrollToInvalid = true;
      const handler = handleSubmit(this.nextSubmit);
      return handler.apply(null, [ev]);
    };

    return (
      <form onSubmit={wrappedFormSubmit} className={bem.element('formRoot')}>
        <div className={bem.element('form')}>
          <div className={`${bem.element('formRow')} row`}>
            <div className={`${bem.element('formCol')} col-md-12`}>
              <div className={`${bem.element('subText')}`}>
                <Text useSpan>NOREC_POPUP_PASSENGERS_DETAILS</Text>
              </div>
            </div>
          </div>
          <div className={`${bem.element('formRow')} row`}>
            <div className={`${bem.element('formCol')} col-md-12`}>
              <div className={`${bem.element('formRow')} row`}>
                <div className={`${bem.element('formCol')} col-md-12`}>
                  <div className={`${bem.element('subTitle')}`}>
                    <Text useSpan>LIST_OF_PASSENGERS</Text>
                  </div>
                </div>
              </div>
              <FieldArray
                name="passengers"
                withRef
                ref="passengersArray"
                component={RenderPassengers}
                onFieldRendered={this.onFieldRendered}
                cmsKeys={cmsKeys}
              />
            </div>
          </div>
          <div className={`${bem.element('formRow')} row`}>
            <div className={`${bem.element('formCol')} col-md-3`}>
              <button
                className={`${bem.element('addPsng')}`}
                type="button"
                onClick={this.addPassenger}
              >
                <Text useSpan>NOREC_POPUP_ADD_NEW_PASSENGER</Text>
              </button>
            </div>
          </div>
          {infantsInvalid ? (
            <div className={`${bem.element('formRow')} row`}>
              <div className={`${bem.element('formCol')} col-md-12`}>
                <div className={bem.element('validationText', { error: true })}>
                  <Text useSpan>NOREC_INFANTS_VALIDATION</Text>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {/* Next button */}
        <div className={`${bem.element('buttonRow')} row`}>
          <div className="col-md-6">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => this.backSubmit()}
            >
              <Text useSpan>PREV_BUTTON</Text>
            </button>
          </div>

          <div className="col-md-6">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={infantsInvalid}
            >
              <Text useSpan>NOREC_POPUP_SUBMIT_BUTTON</Text>
            </button>
          </div>
        </div>

        <div className={`local-loader ${submitting ? 'active' : ''}`} />
      </form>
    );
  }
}

const connectedForm = connect(state => ({
  cmsKeys: allCMSKeysRawSelector(state),
  formValues: getFormValues(formDef.reduxFormId)(state),
}))(PassengersForm);

export default reduxForm({
  form: formDef.reduxFormId,
  validate: validatePassengersForm,
})(connectedForm);
