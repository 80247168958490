import React, { Component } from 'react';

import Seat from '../seat';

export default class SeatMap extends Component {
  render() {
    const { seatMap, selectionLocked } = this.props;

    return (
      <div>
        {seatMap.map(seat => (
          <Seat
            key={seat.id}
            seat={seat}
            selectionLocked={selectionLocked}
            {...this.props}
          />
        ))}
      </div>
    );
  }
}
