import { applyMiddleware, compose, createStore } from 'redux';
import middlewares from '../middlewares';
import rootReducer from '../reducers';

export default function configureStore(eventEmitter = {}, config) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && __DEV__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          name: `Seating widget for: ${
            config.flightNumber
          } ${new Date().toString()}`,
        })
      : compose;

  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares(eventEmitter)))
  );
}
