import React from 'react';
import bem from 'react-bem-classes';

import './index.scss';

import { Text } from 'languages/components/text';
import { Price } from 'commonBlocks/components/price';
import { connect } from 'react-redux';
import { allCMSKeysRawSelector } from 'languages/selectors';

const getHeaderText = (journeyBased, segment) => {
  if (journeyBased) return 'ALL_PAGES_JOURNEY_BASED_BLOCK_TEXT';

  return {
    OUT: 'ALL_PAGES_OUTBOUND_BLOCK_TEXT',
    IN: 'ALL_PAGES_INBOUND_BLOCK_TEXT',
  }[segment.direction];
};

@connect(state => ({
  cmsKeys: allCMSKeysRawSelector(state),
}))
@bem({
  block: 'confirmationList',
})
export default class ConfirmationList extends React.Component {
  render() {
    // Segment can be undefined if it's a list for journeyBased products
    const { passengers, segment, orders, journeyBased, cmsKeys } = this.props;

    const servicesForThisSegment = journeyBased
      ? orders
          .filter(o => o.journeyBased === true)
          .reduce(mergeJourneyBased, [])
      : orders.filter(
          o => o.segmentId === segment.segmentId && !o.journeyBased
        );

    // IN/OUT direction shouldn't be hidden if empty, but journeyBased should
    const shouldBeHidden = journeyBased && servicesForThisSegment.length === 0;

    if (shouldBeHidden) return null;

    return (
      <div className={this.block({ journeyBased })}>
        <div
          className={this.element('flightDirection', {
            takeoff: segment && segment.direction === 'OUT',
            landing: segment && segment.direction === 'IN',
          })}
        >
          <Text useSpan>{getHeaderText(journeyBased, segment)}</Text>
        </div>
        {passengers.map(passenger => {
          const ordersBySegmentAndPassenger = servicesForThisSegment.filter(
            order => order.passengerId === passenger.passengerId
          );

          return (
            ordersBySegmentAndPassenger.length > 0 && (
              <div key={passenger.passengerId}>
                <div
                  className={this.element('flight', { passengerName: true })}
                >
                  {passenger.firstName} {passenger.lastName}
                </div>
                <ul className={this.element('flightOrders', { list: true })}>
                  {ordersBySegmentAndPassenger.map((order, i) => (
                    <li
                      className={this.element('flightOrders', { item: true })}
                      key={`order_${i}`}
                    >
                      <div
                        className={this.element('flight', { itemText: true })}
                      >
                        <Text useSpan fallback={order.name}>
                          {order.nameCmsKey}
                        </Text>
                        {order.seatNumber && (
                          <span>
                            &nbsp; {order.seatNumber.rowNumber}
                            {order.seatNumber.columnLetter}
                          </span>
                        )}
                        {order.serviceId === 3000 && ( // Bio Fuel
                          <span>
                            &nbsp;{' '}
                            {order.quantity +
                              ' ' +
                              (cmsKeys.BIOFUEL_QTY_HOUR || 'hour')}
                          </span>
                        )}
                      </div>
                      <Price
                        className={this.element('flight', { itemText: true })}
                        price={order.price * order.quantity}
                        currency={order.currency}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )
          );
        })}
        {servicesForThisSegment.length === 0 && (
          <Text className={this.element('noPurchases')}>
            CONFIRMATION_NO_PURCHASES_TEXT
          </Text>
        )}
      </div>
    );
  }
}

// Callback for .reduce method
// second reduce argument expected to be empty array
function mergeJourneyBased(acc, service) {
  if (!service.journeyBased) return [...acc, service];

  const existingServiceInOrder = acc.find(
    addedService =>
      addedService.serviceId === service.serviceId &&
      addedService.passengerId === service.passengerId
  );

  if (!existingServiceInOrder) {
    return [...acc, { ...service }];
  }

  existingServiceInOrder.price = (
    Number(existingServiceInOrder.price) + Number(service.price)
  ).toFixed(2);

  return acc;
}
