import React, { Component, RefObject } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { connect } from 'react-redux';
import {
  change,
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { Bem } from 'react-bem-classes';
import { Text } from 'languages/components/text';
import {
  allCMSKeysRawSelector,
  currentLangSelector,
  dateOptionsSelector,
  localeDataSelector,
  localeUtilsSelector,
} from 'languages/selectors';
import { paxshopDateToInternal } from '_helpers/dateFormat';
import { detectIE } from '_helpers/detectIE';
import TextInput from 'commonBlocks/components/textInput';
import {
  dateFormatValidator,
  regexValidator,
  textLengthValidator,
  validate,
  validateEmail,
  validateNotEmpty,
  validateNotEmptyArray,
  validatePhone,
} from '_helpers/reduxFormHelpers';
import {
  authFieldsSelector,
  selectIsBookingNumberMandatory,
} from 'authorization/selectors';
import { DateInput } from 'commonBlocks/components/dateInput';
import { renderToggleField } from 'commonBlocks/components/Toggle';
import { IStoreState } from 'types/store';
import { ICMSKeysMap, ILocaleData } from 'types/languages';
import { IPaxshopData } from 'types/init';
import './index.scss';
import { requestSpecialAssistance } from '../../actions/asyncActions';
import SpecialAssistanceForm from '../SpecialAssistanceForm';

const IDENTIFIER_ALPHA_NUMERIC_NAME_REPLACE_REGEX = /[^a-zA-Z0-9]+/;

interface IStoreProps {
  paxshopDateFormat: string;
  resellerData: string | null;
  authFields: { [key: string]: boolean };
  dispatch: (action: any) => void;
  departureDateValue: string;
  assistanceNeeded: 'yes' | 'no' | void;
  allCMSKeys: ICMSKeysMap;
  paxshopData: IPaxshopData;
  localeUtils: any;
  localeData: ILocaleData;
  currentLocale: string;
  isBookingNumberMandatory: boolean;
  bookingNumberValue: string;
}

interface IOwnProps {
  portalRef: RefObject<HTMLDivElement>;
  className?: string;
  login: (formValues: { [field: string]: string }) => void;
}

type IProps = IOwnProps & IStoreProps;

const valueSelector = formValueSelector('loginForm');

const connector = connect<{}, {}, IOwnProps>((state: IStoreState) => {
  const authFields = authFieldsSelector(state);
  const paxshopData = state.init.paxshopData.data;

  const initialValues = {
    ...(authFields.lastName && {
      lastName: '',
    }),
    ...(paxshopData.showSpecialAssistance && {
      assistanceNeeded: null,
      phoneNumber: '',
      emailAddress: '',
      disabilitiesList: [],
    }),
  };

  return {
    currentLocale: currentLangSelector(state),
    allCMSKeys: allCMSKeysRawSelector(state),
    localeUtils: localeUtilsSelector(state),
    localeData: localeDataSelector(state),
    paxshopDateFormat: dateOptionsSelector(state).paxshopDateFormat,
    paxshopData: state.init.paxshopData.data,
    isBookingNumberMandatory: selectIsBookingNumberMandatory(state),
    departureDateValue: valueSelector(state, 'departureDate'),
    assistanceNeeded: valueSelector(state, 'assistanceNeeded'),
    bookingNumberValue: valueSelector(state, 'bookingNUmber'),
    authFields,
    resellerData: state.init.paxshopAppCtx.paxPartner
      ? state.init.paxshopAppCtx.paxPartner.resellerData
      : null,
    initialValues,
  };
});

const decorateForm = reduxForm<{}, any, string>({
  form: 'loginForm',
  validate: validateLoginForm,
  onSubmitFail: () => {},
});

const bem = new Bem('loginForm');

class LoginForm extends Component<IProps & InjectedFormProps> {
  constructor(props) {
    super(props);
    this.formSubmit = this.formSubmit.bind(this);
  }

  componentWillUpdate(nextProps) {
    const {
      paxshopDateFormat,
      authFields,
      dispatch,
      departureDateValue,
    } = this.props;

    if (!authFields.departureDate) {
      return;
    }

    if (paxshopDateFormat === nextProps.paxshopDateFormat) {
      return;
    }

    const isValidDate = moment(
      departureDateValue,
      paxshopDateFormat,
      true
    ).isValid();

    let newDateStr = departureDateValue;

    if (isValidDate) {
      newDateStr = moment(departureDateValue, paxshopDateFormat).format(
        nextProps.paxshopDateFormat
      );
    }

    dispatch(change('loginForm', 'departureDate', newDateStr));
  }

  formSubmit = values => {
    const { login, resellerData, paxshopDateFormat } = this.props;

    const dataToSubmit = {
      ...values,
      departureDate: paxshopDateToInternal(
        values.departureDate,
        paxshopDateFormat
      ),
    };

    if (resellerData) {
      dataToSubmit.resellerData = resellerData;
    }

    return login(dataToSubmit);
  };

  requestACall = values => {
    const { dispatch } = this.props;
    return dispatch(requestSpecialAssistance(values));
  };

  render() {
    const {
      className,
      resellerData,
      allCMSKeys,
      paxshopDateFormat,
      paxshopData,
      localeUtils,
      authFields,
      handleSubmit,
      submitting,
      assistanceNeeded,
      submitSucceeded,
    } = this.props;

    const ieVersion = detectIE();

    if (assistanceNeeded === 'yes' && submitSucceeded) {
      return (
        <div className={bem.block()}>
          <Text className={bem.element('head')}>
            LOGIN_PAGE_SPECIAL_ASSISTANCE_REQUESTED_TITLE
          </Text>
          <Text className={bem.element('body')}>
            LOGIN_PAGE_SPECIAL_ASSISTANCE_REQUESTED_BODY
          </Text>
        </div>
      );
    }

    return (
      <div className={className}>
        <form
          className={bem.block()}
          onSubmit={handleSubmit(
            assistanceNeeded === 'yes' ? this.requestACall : this.formSubmit
          )}
        >
          {!!ieVersion && resellerData && (
            <div className={bem.element('ieWarning')}>
              <Text>LOGIN_PAGE_IE_WARNING_TEXT</Text>
            </div>
          )}
          <div className={bem.element('head')}>
            <Text>LOGIN_PAGE_LOGIN_BLOCK_TEXT</Text>
          </div>
          <div className={bem.element('body')}>
            <div>
              {resellerData || authFields.lastName ? (
                <div className={bem.element('formGroup')}>
                  <Text className={bem.element('label')}>
                    LOGIN_PAGE_LOGIN_BLOCK_LASTNAME_LABEL
                  </Text>
                  <TextInput
                    name="lastName"
                    placeholder={
                      allCMSKeys.LOGIN_PAGE_LOGIN_BLOCK_LASTNAME_PLACEHOLDER
                    }
                  />
                  {resellerData && (
                    <input
                      type="hidden"
                      name="resellerData"
                      value={resellerData}
                    />
                  )}
                </div>
              ) : null}
            </div>

            {authFields.bookingNumber ? (
              <div className={bem.element('formGroup')}>
                <Text className={bem.element('label')}>
                  LOGIN_PAGE_LOGIN_BLOCK_BOOKING_NR_LABEL
                </Text>
                <TextInput
                  name="bookingNumber"
                  placeholder={
                    allCMSKeys.LOGIN_PAGE_LOGIN_BLOCK_BOOKING_NR_PLACEHOLDER
                  }
                />
              </div>
            ) : null}

            {authFields.departureDate ? (
              <div className={bem.element('formGroup')}>
                <Text className={bem.element('label')}>
                  LOGIN_PAGE_LOGIN_BLOCK_DATE_LABEL
                </Text>

                <Field
                  name="departureDate"
                  component={DateInput}
                  dateFormatStr={paxshopDateFormat}
                  anchorToParent
                  shiftToTop={
                    authFields.departureDate &&
                    Object.keys(authFields).length === 1
                  }
                  showHeader
                  localeUtils={localeUtils}
                  headerTitle={allCMSKeys.LOGIN_PAGE_LOGIN_BLOCK_DATE_LABEL}
                  placeholder={
                    allCMSKeys.LOGIN_PAGE_LOGIN_BLOCK_DATE_PLACEHOLDER
                  }
                  getErrorMsg={error => allCMSKeys[error]}
                />
              </div>
            ) : null}

            {paxshopData.showSpecialAssistance && (
              <Field
                className={bem.element('assistanceToggle')}
                cmsLabel="LOGIN_PAGE_SPECIAL_ASSISTANCE_TOGGLE_LABEL"
                name="assistanceNeeded"
                component={renderToggleField}
                props={{
                  options: [
                    {
                      label:
                        allCMSKeys.LOGIN_PAGE_SPECIAL_ASSISTANCE_TOGGLE_YES,
                      value: 'yes',
                      type: 'positive',
                    },
                    {
                      label: allCMSKeys.LOGIN_PAGE_SPECIAL_ASSISTANCE_TOGGLE_NO,
                      value: 'no',
                      type: 'negative',
                    },
                  ],
                }}
              />
            )}
            {assistanceNeeded === 'yes' && (
              <SpecialAssistanceForm
                allCMSKeys={allCMSKeys}
                localeUtils={localeUtils}
                paxshopDateFormat={paxshopDateFormat}
              />
            )}
            <button type="submit" className={bem.element('loginButton')}>
              {assistanceNeeded === 'yes' ? (
                <Text>LOGIN_PAGE_SPECIAL_ASSISTANCE_REQUEST_BUTTON</Text>
              ) : (
                <Text>LOGIN_PAGE_LOGIN_BLOCK_BUTTON_TEXT</Text>
              )}
            </button>
          </div>

          <div className={cn('local-loader', { active: submitting })} />
        </form>
      </div>
    );
  }
}

function validateLoginForm(values, props: IProps) {
  let validationRules: { [key: string]: any[] } = {};

  const {
    paxshopDateFormat,
    resellerData,
    authFields,
    isBookingNumberMandatory,
    paxshopData,
  } = props;

  if (paxshopData.showSpecialAssistance) {
    validationRules = {
      ...validationRules,
      assistanceNeeded: [validateNotEmpty],
      name: [
        validateNotEmpty,
        textLengthValidator(1, 200, 'CMS_NAME_TOO_LONG'),
      ],
      disabilitiesList: [validateNotEmptyArray],
      phoneNumber: [validatePhone],
      emailAddress: [validateEmail],
    };
  }

  if (authFields.departureDate) {
    validationRules.departureDate = [
      validateNotEmpty,
      dateFormatValidator(paxshopDateFormat),
    ];
  }

  if (authFields.bookingNumber) {
    validationRules.bookingNumber = [
      isBookingNumberMandatory && validateNotEmpty,
      textLengthValidator(1, 30, 'CMS_BOOKING_NUMBER_TOO_LONG'),
      regexValidator(
        IDENTIFIER_ALPHA_NUMERIC_NAME_REPLACE_REGEX,
        false,
        'CMS_VALIDATION_ONLY_ALPHANUMERIC'
      ),
    ].filter(Boolean);
  }

  if (resellerData || authFields.lastName) {
    validationRules.lastName = [validateNotEmpty];
  }

  return validate(values, validationRules);
}

export default connector(decorateForm(LoginForm));
