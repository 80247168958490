export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function appendIfInList(
  list: string[],
  postfix: string
): (string) => string {
  return value => (list.includes(value) ? value + postfix : value);
}

export function emptyStringObjectFromPropsArray(array: string[]): object {
  return array.reduce((res, prop) => {
    res[prop] = '';
    return res;
  }, {});
}

export function pick(array: string[], obj: object): object {
  return array.reduce((res, prop) => {
    res[prop] = obj[prop];
    return res;
  }, {});
}

export function uuid(): string {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}
