import * as types from '../constants/SeatActionTypes';

export default function seatsReducer(state = [], action) {
  switch (action.type) {
    case types.SEATS_CHANGE:
      return action.seats;

    default:
      return state;
  }
}
