import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { IStoreState } from 'types/store';
import { getAllServices } from 'services/actions';
import {
  allCMSKeysRawSelector,
  currentLangSelector,
  localeDataSelector,
} from 'languages/selectors';
import {
  selectAvailablePaymentCountries,
  selectDefaultPaymentCountry,
} from '../../selectors';
import { fetchAvailableCountries } from '../../actions';
import { selectUpToDateBasket } from '../../../basket/selectors/selectBasket';
import { isKlarnaVariant } from '../../helpers';

const valueSelector = formValueSelector('paymentForm');

const mapStateToProps = (state: IStoreState) => ({
  localeData: localeDataSelector(state),
  cmsKeys: allCMSKeysRawSelector(state),
  currentLanguage: currentLangSelector(state),
  isKlarnaPayment: isKlarnaVariant(state.payment.currentPayVariant),
  availableCountries: selectAvailablePaymentCountries(state),
  containsFabServices: selectUpToDateBasket(state).some(
    service => service.supplier === 'fab'
  ),
  termsFieldValue: valueSelector(state, 'terms'),
  payment: state.payment.payment,
  initialValues: {
    citizenship: '',
    personalId: '',
    terms: false,
    country: selectDefaultPaymentCountry(state),
  },
});

const mapDispatchToProps = {
  getAllServices,
  fetchAvailableCountries,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type PropsFromStore = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
