import * as basketActions from 'basket/actions';
import { passengersSelector } from 'passengers/helpers/selectors';
import { segmentsSelector } from 'segments/helpers/selectors';
import { segmentService } from 'segments/services';
import ServiceListHelper from 'services/_helpers/ServiceListHelper';
import { store } from 'store';
import {
  IApiService,
  ISeatInBasket,
  IServiceFromApi,
  IServiceInBasket,
} from 'types/services';
import { IStoreState } from '../../types/store';
import { selectAllServices } from '../../services/selectors';
import { selectUpToDateBasket } from '../../basket/selectors/selectBasket';

type ICandidateForBasket = IApiService & {
  passengerId: number;
  seatNumber?: ISeatInBasket;
};

function forAllSegment(
  connectedServices: ICandidateForBasket[],
  serviceListHelper: ServiceListHelper,
  segments: any[]
): ICandidateForBasket[] {
  const addedServices: ICandidateForBasket[] = [];

  connectedServices
    .filter(
      service =>
        service.allSectorRequiredToBuy && !service.tags.includes('seating')
    )
    .forEach(service => {
      segments
        .filter(segment => segment.segmentId !== service.segmentId)
        .forEach(segment => {
          const addedService = serviceListHelper.findService({
            serviceId: service.serviceId,
            segmentId: segment.segmentId,
          });
          if (
            addedService &&
            serviceListHelper.isPassangerEligibleForService(
              service.passengerId,
              service
            )
          ) {
            addedServices.push(
              Object.assign(
                {
                  passengerId: service.passengerId,
                  seatNumber: service.seatNumber,
                },
                addedService
              )
            );
          }
        });
    });

  return addedServices;
}

function forAllPassengers(
  connectedServices: ICandidateForBasket[],
  serviceListHelper: ServiceListHelper,
  passengersList
): ICandidateForBasket[] {
  const addedServices: ICandidateForBasket[] = [];

  connectedServices
    .filter(service => service.allPassengersRequiredToBuy)
    .forEach(service => {
      passengersList
        .filter(
          ({ passengerId, passengerType }) =>
            passengerType !== 'INF' && passengerId !== service.passengerId
        )
        .forEach(({ passengerId }) => {
          if (
            serviceListHelper.isPassangerEligibleForService(
              passengerId,
              service
            )
          ) {
            addedServices.push(Object.assign({}, service, { passengerId }));
          }
        });
    });

  return addedServices;
}

function neededSeat(
  connectedServices: ICandidateForBasket[],
  serviceListHelper: ServiceListHelper,
  basket: IServiceInBasket[]
): ICandidateForBasket[] {
  const addedServices: ICandidateForBasket[] = [];

  connectedServices
    .filter(service => service.tags.includes('seating'))
    .forEach(service => {
      addedServices.push(
        ...basket
          .filter(
            serviceInBasket =>
              serviceInBasket.segmentId === service.segmentId &&
              serviceInBasket.passengerId === service.passengerId
          )
          .filter(serviceInBasket =>
            serviceListHelper
              .findService(serviceInBasket)
              .eligiblePassengersAfterSeating.find(
                s => s.passengerId === service.passengerId
              )
          )
          .map(serviceInBasket =>
            Object.assign(
              {},
              serviceInBasket,
              serviceListHelper.findService(serviceInBasket)
            )
          )
      );
    });

  return addedServices;
}

function checkSsrsAffectToSeats(
  connectedServices: ICandidateForBasket[],
  serviceListHelper: ServiceListHelper,
  basket: IServiceInBasket[]
): ICandidateForBasket[] {
  const addedServices: ICandidateForBasket[] = [];

  connectedServices
    .filter(service => (service.ssrsAffectToSeats || []).length !== 0)
    .forEach(service => {
      addedServices.push(
        ...basket
          .filter(
            serviceInBasket =>
              serviceInBasket.segmentId === service.segmentId &&
              serviceInBasket.passengerId === service.passengerId
          )
          .filter(serviceInBasket => serviceInBasket.addedAs === 'seating')
          .map(serviceInBasket =>
            Object.assign(
              {},
              serviceInBasket,
              serviceListHelper.findService(serviceInBasket)
            )
          )
      );
    });

  return addedServices;
}

function getSameGroupServices(
  connectedServices: ICandidateForBasket[],
  basket: IServiceInBasket[],
  serviceListHelper: ServiceListHelper
): ICandidateForBasket[] {
  const addedServices: ICandidateForBasket[] = [];

  connectedServices
    .filter(service => service.uniqueNumGroup !== null)
    .forEach(connectedService => {
      let serviceForAdded = basket.map(s => ({
        ...serviceListHelper.findService(s),
        passengerId: s.passengerId,
      }));

      serviceForAdded = serviceForAdded
        .filter(
          service =>
            connectedService.uniqueNumGroup === service.uniqueNumGroup &&
            connectedService.segmentId === service.segmentId &&
            connectedService.passengerId === service.passengerId
        )
        .map(service =>
          Object.assign({}, service, {
            passengerId: connectedService.passengerId,
          })
        );
      addedServices.push(...serviceForAdded);
    });

  return addedServices;
}

function transformServiceForBasket(
  candidateForBasket: ICandidateForBasket,
  addedAs: string = ''
) {
  const { segmentId, ...restProps } = candidateForBasket;
  return {
    ...restProps,
    segmentId,
    direction: segmentService.getSegmentById(segmentId).direction,
    quantity: 1,
    addedAs,
  };
}

export function addServicesToBasket(
  services: ICandidateForBasket[],
  addedAs: string
): void {
  store.dispatch(
    basketActions.addServicesToBasket(
      services.map(s => transformServiceForBasket(s, addedAs))
    )
  );
}

export function getListForAddingService(
  initServices: IServiceFromApi[] | IServiceFromApi,
  passengerId: number
) {
  const state: IStoreState = store.getState();
  const servicesList = selectAllServices(state);
  const segments = segmentsSelector(state);
  const passengersList = passengersSelector(state);
  const serviceListHelper = new ServiceListHelper(servicesList);
  const basket = selectUpToDateBasket(state);
  const services =
    initServices instanceof Array ? initServices : [initServices];

  const servicesForAdding: ICandidateForBasket[] = services.map(service =>
    Object.assign({}, { passengerId }, service)
  );

  servicesForAdding.push(
    ...forAllPassengers(servicesForAdding, serviceListHelper, passengersList)
  );

  let servicesForRemoving: ICandidateForBasket[] = [];

  servicesForRemoving.push(
    ...getSameGroupServices(servicesForAdding, basket, serviceListHelper)
  );
  servicesForRemoving.push(
    ...forAllSegment(servicesForRemoving, serviceListHelper, segments)
  );
  servicesForRemoving.push(
    ...forAllPassengers(servicesForRemoving, serviceListHelper, passengersList)
  );
  servicesForRemoving.push(
    ...checkSsrsAffectToSeats(servicesForAdding, serviceListHelper, basket)
  );

  servicesForRemoving = servicesForRemoving.reduce(
    (previousResult, serviceForDeleting) => {
      const serviceInResult = !!previousResult.find(
        service =>
          service.serviceId === serviceForDeleting.serviceId &&
          service.passengerId === serviceForDeleting.passengerId &&
          service.segmentId === serviceForDeleting.segmentId
      );

      const serviceInBasket = !!basket.find(
        serviceInBasket =>
          serviceInBasket.serviceId === serviceForDeleting.serviceId &&
          serviceInBasket.passengerId === serviceForDeleting.passengerId &&
          serviceInBasket.segmentId === serviceForDeleting.segmentId
      );

      return serviceInBasket && !serviceInResult
        ? previousResult.concat([serviceForDeleting])
        : previousResult;
    },
    []
  );

  return {
    servicesForAdding,
    servicesForRemoving,
  };
}

export function removeService(
  service: IServiceInBasket,
  basket: IServiceInBasket[]
) {
  const servicesForRemoving = getRemovingListByService(service, basket);

  removeServicesFromBasket(servicesForRemoving);
}

export function getRemovingListByService(
  service: IServiceInBasket,
  basket: IServiceInBasket[]
): ICandidateForBasket[] {
  const state = store.getState();
  const servicesList = selectAllServices(state);
  const segments = segmentsSelector(state);
  const passengersList = passengersSelector(state);
  const serviceListHelper = new ServiceListHelper(servicesList);

  const connectedServices: ICandidateForBasket[] = [];

  connectedServices.push(service as any);
  connectedServices.push(
    ...forAllSegment(connectedServices, serviceListHelper, segments)
  );
  connectedServices.push(
    ...forAllPassengers(connectedServices, serviceListHelper, passengersList)
  );
  connectedServices.push(
    ...neededSeat(connectedServices, serviceListHelper, basket)
  );
  connectedServices.push(
    ...checkSsrsAffectToSeats(connectedServices, serviceListHelper, basket)
  );

  return connectedServices;
}

export function removeServicesFromBasket(
  services: ICandidateForBasket[]
): void {
  store.dispatch(
    basketActions.removeServicesFromBasket(
      services.map(s => transformServiceForBasket(s))
    )
  );
}
