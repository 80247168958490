import * as React from 'react';
import { appConfig } from 'config';
import PropTypes from 'prop-types';
import {
  reduxForm,
  getFormValues,
  Field,
  FieldArray,
  SubmissionError,
} from 'redux-form';
import { Text } from 'languages/components/text';
import { Bem } from 'react-bem-classes';
import http from 'axios';
import * as norecActions from 'norec/actions';
import {
  backendErrorFromAxiosError,
  defaultBackendErrorHandler,
  anyToBackendError,
} from '_helpers/backendError';
import * as wizLogic from 'norec/wizardLogic';
import TextInput from 'commonBlocks/components/textInput';
import SelectInput from 'commonBlocks/components/selectInput';
import SlideCheckbox from 'commonBlocks/components/slideCheckbox';
import { DateInput } from 'commonBlocks/components/dateInput';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import {
  isValid,
  validate,
  validateNotEmpty,
  dateFormatValidator,
  filterInputProps,
} from '_helpers/reduxFormHelpers';
import { authRequest } from 'authorization/helpers/backendAuth';
import {
  authFieldsSelector,
  createAuthFinishPromise,
} from 'authorization/selectors';
import * as authActions from 'authorization/actions';
import { paxshopDateToInternal } from '_helpers/dateFormat';
import {
  allCMSKeysRawSelector,
  localeUtilsSelector,
} from 'languages/selectors';
import { connect } from 'react-redux';
import { IStoreState } from 'types/store';
import { FocusEventHandler } from 'react';

const formDef = wizLogic.FORMS_DEF.FLIGHTS_FORM;
const bem = new Bem('modalNorec');

function checkFlights(carrierCustomerId, flights) {
  return http.post(`${appConfig.apiUrl}/info/flights-check`, {
    carrierCustomerId,
    flights,
    paxshopId: appConfig.paxshopId,
  });
}

function shouldDisplayTourOperatorSelect(bookingConfig) {
  return (
    bookingConfig.tourOperators !== null &&
    bookingConfig.tourOperators.length > 1
  );
}

function validateFlightsForm(values, props) {
  const { norecState } = props;
  const { bookingConfig } = norecState;
  const errors: any = {};

  const validateNonEqualToBookingNumber = value =>
    value.toLowerCase() === norecState.bookingNumber.toLowerCase()
      ? 'CMS_FLIGHT_NUM_IS_EQUAL_TO_BOOKING'
      : null;

  if (values.segments) {
    errors.segments = values.segments.map(segment =>
      !segment.enabled
        ? {}
        : validate(segment, {
            bookingClassId: [validateNotEmpty],
            flightNumber: [validateNotEmpty, validateNonEqualToBookingNumber],
            departureDate: [
              validateNotEmpty,
              dateFormatValidator(props.paxshopDateFormat),
            ],
          })
    );

    if (
      values.segments.length === 2 &&
      values.segments[0].enabled &&
      values.segments[1].enabled &&
      isValid(errors)
    ) {
      const outDepartureDate = moment.utc(
        values.segments[0].departureDate,
        props.paxshopDateFormat
      );
      const inDepartureDate = moment.utc(
        values.segments[1].departureDate,
        props.paxshopDateFormat
      );
      if (inDepartureDate.unix() < outDepartureDate.unix() - 3600 * 24) {
        errors.segments[1].departureDate = 'CMS_ERROR_IN_DATE_BEFORE_OUT';
      }

      if (
        values.segments[0].flightNumber.toUpperCase() ===
        values.segments[1].flightNumber.toUpperCase()
      ) {
        errors.segments[1].flightNumber = 'CMS_NOREC_SAME_IN_OUT_FLIGHT';
      }
    }
  }

  if (shouldDisplayTourOperatorSelect(bookingConfig)) {
    if (!values.overriddenTourOperatorId) {
      errors.overriddenTourOperatorId = 'CMS_REQUIRED_FIELD';
    }
  }

  return errors;
}

const renderTermsCheckbox = ({ input }) => (
  <input type="checkbox" name={input.name} {...filterInputProps(input)} />
);

const renderSegments = ({
  fields,
  formValues,
  paxshopDateFormat,
  cmsKeys,
  localeUtils,
  norecState,
  onFieldRendered,
}) =>
  fields.map((segment, segmentIdx) => (
    <div key={segmentIdx} className={`${bem.element('formCol')} col-md-6`}>
      {/* Segment header + enabled/disabled */}
      <div className={bem.element('formRow')}>
        <div className={`${bem.element('formCol')} col-md-12`}>
          {segmentIdx === 0 ? (
            <div className={bem.element('subTitle')}>
              <i className="icon plane_takeoff" />
              <Text useSpan>ALL_PAGES_OUTBOUND_BLOCK_TEXT</Text>
            </div>
          ) : (
            <div className={bem.element('subTitle')}>
              <i className="icon plane-landing" />
              <Text useSpan>ALL_PAGES_INBOUND_BLOCK_TEXT</Text>
              <SlideCheckbox
                name={`${segment}.enabled`}
                className={bem.element('subTitleCheckbox')}
                cmsKeys={cmsKeys}
                checked={formValues.segments[segmentIdx].enabled}
              />
            </div>
          )}
        </div>
      </div>

      {/* Departure date */}
      <div className={bem.element('formRow')}>
        <div className={`${bem.element('formCol')} col-md-12`}>
          <label>
            <Text useSpan>NOREC_POPUP_DATE</Text>
          </label>

          <Field
            name={`${segment}.departureDate`}
            disabled={!formValues.segments[segmentIdx].enabled}
            component={DateInput}
            dateFormatStr={paxshopDateFormat}
            localeUtils={localeUtils}
            getErrorMsg={error => cmsKeys[error]}
            placeholder={cmsKeys.LOGIN_PAGE_LOGIN_BLOCK_DATE_PLACEHOLDER}
          />
        </div>
      </div>

      {/* Flight number */}
      <div className={bem.element('formRow')}>
        <div className={`${bem.element('formCol')} col-md-12`}>
          <label>
            <Text useSpan>FLIGHT_NUMBER</Text>
          </label>
          <TextInput
            name={`${segment}.flightNumber`}
            disabled={!formValues.segments[segmentIdx].enabled}
            emptyIfDisabled
            onFieldRendered={onFieldRendered}
          />
        </div>
      </div>

      {/* Booking class */}
      {appConfig.norecHideBookingClassIfOnlyOne &&
      norecState.bookingConfig.cabinClasses.length === 1 ? (
        ''
      ) : (
        <div className={bem.element('formRow')}>
          <div className={`${bem.element('formCol')} col-md-12`}>
            <label>
              <Text useSpan>NOREC_POPUP_BOOKING_CLASS</Text>
            </label>
            <SelectInput
              allowEmptyValue={norecState.bookingConfig.cabinClasses.length > 1}
              name={`${segment}.bookingClassId`}
              disabled={!formValues.segments[segmentIdx].enabled}
              emptyIfDisabled
              options={norecState.bookingConfig.cabinClasses || []}
              getOptionId={bc => bc.bookingClass}
              getOptionText={bc =>
                cmsKeys[`CMS_CABIN_CLASS_${bc.bookingClass}`]
              }
              cmsKeys={cmsKeys}
              onFieldRendered={onFieldRendered}
            />
          </div>
        </div>
      )}
    </div>
  ));

class FlightsForm extends React.Component<any, any> {
  shouldScrollToInvalid: boolean;

  static contextTypes = {
    store: PropTypes.object.isRequired,
    showTextInfoDialog: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.shouldScrollToInvalid = false;
  }

  submit = (values, dispatch) => {
    this.shouldScrollToInvalid = false;
    const localValidation = validateFlightsForm(values, this.props);
    if (!isValid(localValidation)) {
      this.shouldScrollToInvalid = true;
      return null;
    }

    const { store } = this.context;
    const { norecState, paxshopDateFormat } = this.props;

    const carrierCustomerId = norecState.carrierCustomerId;
    const checkFlightsReq = values.segments
      .filter(seg => seg.enabled)
      .map(seg => ({
        flightNumber: seg.flightNumber,
        departureDate: paxshopDateToInternal(
          seg.departureDate,
          paxshopDateFormat
        ),
      }));

    const bookingAuthSrc = {
      bookingNumber: norecState.bookingNumber,
      departureDate: paxshopDateToInternal(
        values.segments[0].departureDate,
        paxshopDateFormat
      ),
      paxshopId: appConfig.paxshopId,
      referenceData: null,
    };

    return new Promise((resolve, reject) => {
      const finishFlightFormFlow = () => {
        checkFlights(carrierCustomerId, checkFlightsReq)
          .then((result: any) => {
            const freshNorecState = store.getState().norec;
            if (wizLogic.isStaleState(norecState, freshNorecState)) {
              return;
            }

            const flightsRes = result.data.flights;
            const segmentsErrors = [];

            const processedSegments = values.segments.map(seg => {
              let segmentError = {};

              let segmentDepartures = [];

              if (seg.enabled) {
                const flightData = flightsRes.find(
                  f => f.flightNumber == seg.flightNumber
                );
                if (!flightData) {
                  segmentError = { flightNumber: 'CMS_FLIGHT_NOT_FOUND' };
                } else {
                  segmentDepartures = flightData.depatures;
                }
              }

              segmentsErrors.push(segmentError);
              return {
                enabled: seg.enabled,
                flightNumber: seg.flightNumber,
                departureDate: paxshopDateToInternal(
                  seg.departureDate,
                  paxshopDateFormat
                ),
                bookingClassId: seg.bookingClassId || null,
                departures: segmentDepartures,
              };
            });

            if (isValid(segmentsErrors)) {
              dispatch(
                norecActions.finishFlightsForm(
                  processedSegments,
                  values.overriddenTourOperatorId || null,
                  values.cabinClassTermsAccepted
                )
              );
              dispatch(norecActions.norecNext());
              resolve();
            } else {
              this.shouldScrollToInvalid = true;
              // reject({ segments: segmentsErrors});
              reject(new SubmissionError({ segments: segmentsErrors as any }));
            }
          })
          .catch(axiosError => {
            if (
              axiosError.name === 'SubmissionError' ||
              (!!axiosError.errors && axiosError.errors.segments)
            ) {
              reject(new SubmissionError(axiosError.errors));
              // throw new SubmissionError(axiosError.errors);
            }

            const freshNorecState = store.getState().norec;
            if (wizLogic.isStaleState(norecState, freshNorecState)) {
              return;
            }

            const err = backendErrorFromAxiosError(axiosError);
            defaultBackendErrorHandler(err);
            resolve();
          });
      };

      if (bookingAuthSrc.bookingNumber) {
        authRequest(bookingAuthSrc).then(
          authData => {
            dispatch(
              authActions.initiateLogin({
                authType: 'BOOKING',
                bookingSrc: bookingAuthSrc,
              })
            );
            const authFinishedPromise = createAuthFinishPromise(store);
            authFinishedPromise
              .then(res => {
                dispatch(norecActions.hideNorecDialog());
                resolve();
              })
              .catch(err => {
                const backendError = anyToBackendError(err);
                defaultBackendErrorHandler(err);
                resolve();
              });
          },
          err => {
            finishFlightFormFlow();
          }
        );
      } else {
        finishFlightFormFlow();
      }
    });
  };

  showTermPopup = () => {
    const { showTextInfoDialog } = this.context;
    showTextInfoDialog('info', '', 'NOREC_TC_PANEL_TEXT');
  };

  render() {
    const {
      norecState: { bookingConfig },
      formValues,
      submitting,
    } = this.props;

    const formInitialized =
      bookingConfig.origin &&
      !bookingConfig.origin.fetching &&
      formValues._formInitialized;

    return (
      <div className={bem.element('formRoot')}>
        {!formInitialized ? this.renderWaitingForBackend() : this.renderForm()}
        <div
          className={`local-loader ${
            submitting || !formInitialized ? 'active' : ''
          }`}
        />
      </div>
    );
  }

  renderWaitingForBackend() {
    return (
      <form>
        <div className={bem.element('form')} style={{ height: '300px' }} />
      </form>
    );
  }

  renderTourOperatorSelect(norecState) {
    const { bookingConfig } = norecState;

    if (!shouldDisplayTourOperatorSelect(bookingConfig)) {
      return null;
    }

    return (
      <div className={`${bem.element('formRow')} row`}>
        <div className={`${bem.element('formCol')} col-md-12`}>
          <label>
            <Text useSpan>NOREC_POPUP_TOUR_OPERATOR</Text>
          </label>
          <SelectInput
            allowEmptyValue
            // field={overriddenTourOperatorId}
            name="overriddenTourOperatorId"
            disabled={norecState.bookingConfig.tourOperators === null}
            options={norecState.bookingConfig.tourOperators || []}
            getOptionId={top => top.topId}
            getOptionText={top => top.name}
            cmsKeys={this.props.cmsKeys}
            onFieldRendered={this.onFieldRendered.bind(this)}
          />
        </div>
      </div>
    );
  }

  onFieldRendered(field, elem) {
    if (this.shouldScrollToInvalid && field.invalid) {
      this.shouldScrollToInvalid = false;
      elem.parentNode.scrollIntoView(true);
    }
  }

  renderForm() {
    const {
      authFields,
      paxshopDateFormat,
      norecState,
      formValues,
      handleSubmit,
      cmsKeys,
      localeUtils,
      changeNorecBookingNumber,
    } = this.props;

    const showBookingClassTerms =
      norecState.bookingConfig.cabinClasses.length > 1;

    const bookingClassTermsNotAccepted =
      showBookingClassTerms && !formValues.cabinClassTermsAccepted;

    const wrappedFormSubmit = ev => {
      this.shouldScrollToInvalid = true;
      const handler = handleSubmit(this.submit);
      return handler.apply(null, [ev]);
    };

    const changeBookingNumber: FocusEventHandler<HTMLInputElement> = e =>
      changeNorecBookingNumber(e.target.value);

    return (
      <form onSubmit={wrappedFormSubmit}>
        <div className={bem.element('form')}>
          {/* Heading text */}
          <div className={bem.element('formRow')}>
            <div className={`${bem.element('formCol')} col-md-12`}>
              <div className={bem.element('subText')}>
                <Text useSpan>CMS_NOREC_FLIGHTS_FORM_HEADER</Text>
              </div>
            </div>
          </div>

          {authFields['bookingNumber'] && (
            <div className={bem.element('bookingNumberField')}>
              <label htmlFor="bookingNumberField">
                <Text useSpan>BOOKING_NUMBER</Text>
              </label>
              <div className="textInput__inputWrapper">
                <input
                  className="textInput"
                  id="bookingNumberField"
                  type="text"
                  defaultValue={norecState.bookingNumber}
                  name="bookingNumber"
                  onBlur={changeBookingNumber}
                />
              </div>
            </div>
          )}

          <div className={`${bem.element('formRow')} row`}>
            <FieldArray
              name="segments"
              component={renderSegments as any}
              onFieldRendered={this.onFieldRendered.bind(this)}
              formValues={formValues}
              paxshopDateFormat={paxshopDateFormat}
              cmsKeys={cmsKeys}
              norecState={norecState}
              localeUtils={localeUtils}
            />
          </div>

          {/* TourOpearotId */}
          {this.renderTourOperatorSelect(norecState)}

          {/* Validation notification & terms */}
          <div className={`${bem.element('formRow')} row`}>
            <div className={`${bem.element('formCol')} col-md-12`}>
              <div className={`${bem.element('validationText')}`}>
                <Text>NOREC_VALIDATION_TEXT</Text>
              </div>

              {!showBookingClassTerms ? null : (
                <div className={bem.element('terms')}>
                  <label>
                    <Field
                      name="cabinClassTermsAccepted"
                      component={renderTermsCheckbox}
                    />
                    <Text useSpan>NOREC_TC_PANEL_LABEL</Text>
                  </label>
                  <span
                    onClick={this.showTermPopup}
                    className={`${bem.element('termsLink')} icon flaticon-info`}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Next button */}
        <div className={`${bem.element('buttonRow')} row`}>
          <div className="col-md-6 col-md-offset-3">
            <div data-tip data-for="mustAcceptTermsTooltip">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={bookingClassTermsNotAccepted}
              >
                <Text useSpan>NOREC_CHECK_POPUP_SUBMIT_BUTTON</Text>
              </button>
            </div>

            <div
              className={bem.element('tooltip', {
                hidden: !bookingClassTermsNotAccepted,
              })}
            >
              <ReactTooltip
                place="top"
                type="dark"
                effect="solid"
                id="mustAcceptTermsTooltip"
              >
                <Text useSpan>NOREC_TC_REQUIRED_MESSAGE</Text>
              </ReactTooltip>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const connectForm = connect(
  (state: IStoreState) => ({
    cmsKeys: allCMSKeysRawSelector(state),
    formValues: getFormValues(formDef.reduxFormId)(state),
    localeUtils: localeUtilsSelector(state),
    authFields: authFieldsSelector(state),
  }),
  {
    changeNorecBookingNumber: norecActions.changeNorecBookingNumber,
  }
);

// Copy-paste from https://github.com/redux-form/redux-form/issues/2809
const shouldValidate = ({
  values,
  nextProps,
  props,
  initialRender,
  structure,
}) => {
  if (initialRender) {
    return true;
  }
  return (
    initialRender ||
    !structure.deepEqual(values, nextProps.values) ||
    props.norecState !== nextProps.norecState // must specifically check prop we know might change
  );
};

const wrappedForm = reduxForm({
  form: formDef.reduxFormId,
  validate: validateFlightsForm,
  shouldError: shouldValidate,
})(FlightsForm);

export default connectForm(wrappedForm);
