import * as types from '../constants/PlaneActionTypes';

const defaultState = new Map();

export default function seatImagesReducer(state = defaultState, action) {
  switch (action.type) {
    case types.FETCH_CONFIGURATION:
      const seatImages = action.res.data.definitions.images;
      return new Map(seatImages.map(image => [image.id, image.image]));
    default:
      return state;
  }
}
