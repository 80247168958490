import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import cn from 'classnames';
import { Bem } from 'react-bem-classes';
import { authOnly } from 'authorization/helpers/authOnly';
import BasketWrapper from 'basket/containers';
import { Text } from 'languages/components/text';
import { Modal } from 'layouts/modal';
import Page, { Content } from 'layouts/page';
import Cloud, { CloudTitle } from 'layouts/pageCloud';
import Columns from 'layouts/pageColumns';
import { CurrentPageWizardHeader } from 'mainMenu/containers/currentPageWizardHeader';
import { SalesWindowWarningModal } from 'pageSeating/components/salesWindowWarningModal/index';
import Message from 'pageSeating/components/seatingMessage';
import * as passengersAction from 'passengers/actions';
import { PassengerSelector } from 'passengers/containers/selector';
import { IEnhancedSegment } from 'types/segments';
import { IPassenger } from 'types/passengers';
import * as seatingActions from 'seatingWidget/actions';
import SeatingWidgetWrap from 'seatingWidget/components/seatingWidgetWrap';
import { SegmentsSelector } from 'segments/containers/selector';
import { segmentService } from 'segments/services';
import ServiceListHelper from 'services/_helpers/ServiceListHelper';
import { ObservationPopup } from 'services/components/observationPopup';
import Legend from 'services/components/seatsList';
import './index.scss';
import { ISeatBind, ISeatSegmentValidationResult } from 'types/seatingWidget';
import { IServiceFromApi, IServiceInBasket } from 'types/services';
import { ICMSKeysMap, ILocaleData } from 'types/languages';
import Info from '../components/seatingPagePaxInfo';
import connect from './connector';
import { CheckinTeaser } from '../components/checkinTeaser';
import {
  acceptSuggestedSeats,
  ISuggestedSeat,
  suggestSeatsForSegment,
} from '../actions';
import { useSegmentedState } from '../../_helpers/useSegmentedState';

function intersect<T>(a: T[], b: T[]): T[] {
  if (b.length > a.length) {
    return intersect(b, a);
  }

  return a.filter(e => b.includes(e));
}

const bem = new Bem('seating');

interface IStoreProps {
  dispatch: Dispatch;
  autoAllocationAllowed: boolean;
  currentSegment: IEnhancedSegment;
  suggestedSeats: ISuggestedSeat[];
  currentPassengerId: number;
  passengers: IPassenger[];
  segments: IEnhancedSegment[];
  isSeatsBought: boolean;
  seatingErrors: ISeatSegmentValidationResult[];
  hasSelectedSeatsForSegment: boolean;
  haveAvailabilityToSelectSeatsForOtherSegment: boolean;
  haveAvailabilityToSelectSeatsForCurrentSegment: boolean;
  chosenSeats: ISeatBind[];
  currentSegmentId: number;
  serviceList: IServiceFromApi[];
  basket: IServiceInBasket[];
  bookingNumber: string;
  cmsKeys: ICMSKeysMap;
  localeData: ILocaleData;
  allInfantsHaveRef: boolean;

  widgetInfoLoading: boolean;
  isValidationPending: boolean;
  forceLoadCounter: number;
  seatServices: IServiceFromApi[];
  ssrsAffectToSeatsServices: IServiceInBasket[];
  seatingPageMoreInfoIsOpened: boolean;
}

interface IOwnProps {
  addItem: any;
  removeItem: any;
}

type IProps = IStoreProps & IOwnProps;

function PageSeating(props: IProps) {
  const {
    currentPassengerId,
    chosenSeats,
    isSeatsBought,
    suggestedSeats,
    currentSegment,
    haveAvailabilityToSelectSeatsForCurrentSegment,
    hasSelectedSeatsForSegment,
    bookingNumber,
    localeData,
    cmsKeys,
    seatingErrors,
    passengers,
    widgetInfoLoading,
    isValidationPending,
    forceLoadCounter,
    seatServices,
    ssrsAffectToSeatsServices,
    seatingPageMoreInfoIsOpened,
    autoAllocationAllowed,
    allInfantsHaveRef,
    dispatch,
  } = props;

  /*
   This mechanism is related to task 9373 and enabled for Smartlynx shop
   If autoAllocation is allowed by backend and you are not selected
   or bought any seats for currentSegment, then seat selection is locked
   until you accept or reject free seats offer
  */
  const [seatSelectionLocked, setSeatSelectionLocked] = useSegmentedState(
    autoAllocationAllowed && !isSeatsBought && !hasSelectedSeatsForSegment,
    String(currentSegment.segmentId)
  );

  const selectSeat = ({ seat }) => handleSeatSelect(props, seat);

  const handleWidgetInit = data => {
    dispatch(seatingActions.getSeatsInfoFromWidget(data));
  };

  const handleAcceptSuggestedSeats = () => {
    if (suggestedSeats.length > 0) {
      dispatch(acceptSuggestedSeats(currentSegment));
    }
  };

  useEffect(() => {
    if (autoAllocationAllowed && allInfantsHaveRef) {
      dispatch(suggestSeatsForSegment(currentSegment));
    }
  }, [currentSegment, allInfantsHaveRef]);

  const firstPassengerId = passengers[0] ? passengers[0].passengerId : null;

  const hasPrmPassengers = passengers.some(p =>
    (p.passengerInfo || []).some(
      info =>
        intersect(
          [
            'WCHR',
            'WCHS',
            'WCHC',
            'WCHP',
            'BLND',
            'DEAF',
            'DEAF/BLND',
            'DPNA',
            'WCMP',
            'WCBD',
            'WCBW',
          ],
          info.ssrCodes
        ).length > 0
    )
  );

  const nextStepAvailabilityStatus = getNextStepAvailablityStatus(props);

  const seatingServicesId2Service = {};

  seatServices.forEach(service => {
    seatingServicesId2Service[service.serviceId] = service;
  });

  let seatingMessage = null;
  let messageType = null;

  if (currentSegment.cmsMessages[0] && !isSeatsBought) {
    seatingMessage = {
      title: `${currentSegment.cmsMessages[0]}_TITLE`,
      content: currentSegment.cmsMessages[0],
    };
    messageType = 'close';
  }

  if (isSeatsBought) {
    seatingMessage = {
      title: 'SEATING_PAGE_MSG_SEATS_IS_BOUGHT_TITLE',
      content: 'SEATING_PAGE_MSG_SEATS_IS_BOUGHT_CONTENT',
    };
    messageType = 'success';
  }

  if (
    currentSegment.cmsMessages[0] &&
    currentSegment.cmsMessages[0].indexOf(
      `CMS_SEATING_SALES_WINDOWS_CLOSED_${currentSegment.direction}`
    ) !== -1
  ) {
    seatingMessage = {
      title: `${currentSegment.cmsMessages[0]}_TITLE`,
      content: currentSegment.cmsMessages[0],
    };
    messageType = 'close';
  }

  if (
    currentSegment.cmsMessages[0] &&
    currentSegment.cmsMessages[0].indexOf(
      `CMS_FLIGHT_DEPARTED_${currentSegment.direction}`
    ) !== -1
  ) {
    seatingMessage = {
      title: `${currentSegment.cmsMessages[0]}_TITLE`,
      content: currentSegment.cmsMessages[0],
    };
    messageType = 'departed';
  }

  const showWarning =
    !isSeatsBought &&
    !widgetInfoLoading &&
    hasPrmPassengers &&
    (haveAvailabilityToSelectSeatsForCurrentSegment || chosenSeats.length > 0);

  return (
    <Page>
      <CurrentPageWizardHeader
        nextStepAvailabilityStatus={nextStepAvailabilityStatus}
        isValidationPending={isValidationPending}
      />

      <Content mobileFullWidth>
        <SegmentsSelector seatingErrors={seatingErrors} />
        <Columns>
          <div>
            <Cloud>
              <CloudTitle>
                <Text>LIST_OF_PASSENGERS</Text>
              </CloudTitle>
              <PassengerSelector
                key={`${currentSegment.segmentId}${firstPassengerId}`}
                useOverriddenSeats={
                  seatSelectionLocked && suggestedSeats.length > 0
                }
                overriddenSeats={suggestedSeats}
              />
            </Cloud>
            <Cloud>
              <CloudTitle hideForMobile>
                <Text>SEAT_MAP_KEYS</Text>
              </CloudTitle>
              <Legend handleClick={() => {}} />
              <Info seatingPageMoreInfoIsOpened={seatingPageMoreInfoIsOpened} />
            </Cloud>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Cloud>
              {!widgetInfoLoading && !!seatingMessage && (
                <Message
                  type={messageType}
                  titleCms={seatingMessage.title || ''}
                >
                  <Text>{seatingMessage.content}</Text>
                </Message>
              )}
              <CheckinTeaser />
              {showWarning && (
                <Message type="prmWarning">
                  <Text>PRM_WARNING_TEXT</Text>
                </Message>
              )}
              <SalesWindowWarningModal />
              {suggestedSeats.length > 0 && !isSeatsBought && (
                <div className={bem.element('suggested-seats')}>
                  <p className={bem.element('suggested-seats-text')}>
                    {localeData.printCmsTextWithParams(
                      'PAGE_SEATING_WE_OFFER_SEATS_FOR_FREE',
                      {
                        seats: suggestedSeats
                          .map(seat => seat.seatNumber)
                          .join(', '),
                      }
                    )}
                  </p>
                  {seatSelectionLocked ? (
                    <>
                      <button
                        className={cn(
                          'btn',
                          'btn-info',
                          bem.element('suggested-seats-button')
                        )}
                        type="button"
                        onClick={handleAcceptSuggestedSeats}
                      >
                        {localeData.printCmsText(
                          'PAGE_SEATING_ACCEPT_SUGGESTED_SEATS'
                        )}
                      </button>
                      <button
                        className={cn(
                          'btn',
                          'btn-default',
                          bem.element('suggested-seats-button')
                        )}
                        type="button"
                        onClick={() => setSeatSelectionLocked(false)}
                      >
                        {localeData.printCmsText(
                          'PAGE_SEATING_SELECT_OTHER_SEATS'
                        )}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className={cn(
                          'btn',
                          'btn-info',
                          bem.element('suggested-seats-button')
                        )}
                        type="button"
                        onClick={() => setSeatSelectionLocked(true)}
                      >
                        {localeData.printCmsText(
                          'PAGE_SEATING_BACK_TO_SUGGESTED'
                        )}
                      </button>
                    </>
                  )}
                </div>
              )}
              <SeatingWidgetWrap
                key={`${currentSegment.segmentId}||${forceLoadCounter}||${ssrsAffectToSeatsServices}`}
                cmsKeys={cmsKeys}
                seatingServicesId2Service={seatingServicesId2Service}
                selectionLocked={
                  seatSelectionLocked &&
                  suggestedSeats.length > 0 &&
                  !isSeatsBought
                }
                seats={chosenSeats}
                suggestedSeats={suggestedSeats}
                ssrsAffectToSeatsServices={ssrsAffectToSeatsServices}
                onSeatSelect={selectSeat}
                onSeatUnSelect={selectSeat}
                segment={currentSegment}
                bookingNumber={bookingNumber}
                currentPassengerId={currentPassengerId}
                onInit={handleWidgetInit}
              />
            </Cloud>
          </div>
        </Columns>
      </Content>

      <Modal centered>
        <ObservationPopup />
      </Modal>
    </Page>
  );
}

function selectNextPassenger(props: IProps) {
  const { dispatch, currentPassengerId, passengers } = props;

  const currentPassengerIndex = passengers.findIndex(
    p => p.passengerId === currentPassengerId
  );
  if (currentPassengerIndex + 1 !== passengers.length) {
    dispatch(
      passengersAction.switchPassenger(
        passengers[currentPassengerIndex + 1].passengerId
      )
    );
  }
}

function getNextStepAvailablityStatus(props: IProps) {
  const {
    seatingErrors,
    currentSegment,
    segments,
    isSeatsBought,
    chosenSeats,
    haveAvailabilityToSelectSeatsForOtherSegment,
    haveAvailabilityToSelectSeatsForCurrentSegment,
  } = props;

  const seatingErrorForCurrentSegment = seatingErrors.find(
    segment =>
      !segment.status && segment.airPathDirection === currentSegment.direction
  );

  const seatingErrorAboutRequiredSeats = seatingErrors.find(
    segment =>
      segment.description === 'CMS_ALL_SECTOR_REQUIRED_TO_BUY_FOR_SEATING'
  );

  if (seatingErrorAboutRequiredSeats) {
    return {
      type: 'error',
      messageCmsKey: seatingErrorAboutRequiredSeats.description,
      onRejectToolTip: () =>
        seatingErrorAboutRequiredSeats.airPathDirection !==
          currentSegment.direction && segmentService.selectAnotherSegment(),
    };
  }

  if (seatingErrorForCurrentSegment) {
    return {
      type: 'error',
      messageCmsKey: seatingErrorForCurrentSegment.description,
    };
  }

  if (
    chosenSeats.length === 0 &&
    !isSeatsBought &&
    haveAvailabilityToSelectSeatsForCurrentSegment
  ) {
    return {
      type: 'confirm',
      messageCmsKey: 'WTT_SEATS_ARE_NOT_SELECT_FOR_CURRENT_SEGMENT',
      rejectButtonCmsKey: 'WTT_SELECT_SEATS_BUTTON',
      resolveButtonCmsKey: 'WTT_CONTINUE_BUTTON',
    };
  }

  if (
    segments.length !== 1 &&
    (chosenSeats.length !== 0 || isSeatsBought) &&
    haveAvailabilityToSelectSeatsForOtherSegment
  ) {
    return {
      type: 'confirm',
      messageCmsKey: 'WTT_SEATS_ARE_NOT_SELECT_FOR_OTHER_SEGMENT',
      rejectButtonCmsKey: 'WTT_SELECT_SEATS_BUTTON',
      resolveButtonCmsKey: 'WTT_CONTINUE_BUTTON',
      onRejectToolTip: () => {
        segmentService.selectAnotherSegment();
      },
    };
  }

  return null;
}

function handleSeatSelect(props: IProps, seat) {
  const {
    currentPassengerId,
    currentSegmentId,
    serviceList,
    basket,
    addItem,
    removeItem,
  } = props;

  const serviceListHelper = new ServiceListHelper(serviceList);

  const seatPosition = seat.id.split(/(\d+)/).filter(Boolean);

  const foundService: IServiceFromApi = serviceListHelper.findService({
    serviceId: seat.service.id,
    segmentId: currentSegmentId,
  });

  const service = {
    ...foundService,
    seatNumber: {
      rowNumber: parseInt(seatPosition[0], 10),
      columnLetter: seatPosition[1],
    },
  };

  const seatInBasket = basket
    .filter(
      basketService =>
        !!basketService.seatNumber &&
        basketService.segmentId === currentSegmentId &&
        basketService.passengerId === currentPassengerId
    )
    .find(
      seat =>
        seat.seatNumber.rowNumber === service.seatNumber.rowNumber &&
        seat.seatNumber.columnLetter === service.seatNumber.columnLetter
    );

  if (seatInBasket) {
    removeItem(seatInBasket, true);
  } else {
    addItem(service, currentPassengerId, 'seating').then(isAdded => {
      if (isAdded) {
        selectNextPassenger(props);
      }
    });
  }
}

const WrappedPageSeating = authOnly()(connect<IStoreProps>(PageSeating));

export default function PageSeatingWrapper() {
  return (
    <BasketWrapper useRenderProps>
      {(addItem, removeItem) => (
        <WrappedPageSeating addItem={addItem} removeItem={removeItem} />
      )}
    </BasketWrapper>
  );
}
