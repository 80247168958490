import * as React from 'react';
import './index.scss';
import bem from 'react-bem-classes';
import { Text } from 'languages/components/text';
import { Price } from 'commonBlocks/components/price';
import { BasketDropdownFooter } from 'basket/components/basketDropdownFooter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { allCMSKeysRawSelector } from 'languages/selectors';

// Callback for .reduce method
// second reduce argument expected to be empty array
function mergeJourneyBased(acc, service) {
  if (!service.journeyBased) return [...acc, service];

  const existingServiceInOrder = acc.find(
    addedService =>
      addedService.serviceId === service.serviceId &&
      addedService.passengerId === service.passengerId
  );

  if (!existingServiceInOrder) {
    return [...acc, { ...service }];
  }

  existingServiceInOrder.price = existingServiceInOrder.price + service.price;

  return acc;
}

@connect(state => ({
  cmsKeys: allCMSKeysRawSelector(state),
}))
@bem({
  block: 'basketDropdown',
})
export class BasketDropdownContent extends React.Component {
  static propTypes = {
    services: PropTypes.array,
    groups: PropTypes.array,
    currency: PropTypes.string,
    total: PropTypes.number,
    freezeCart: PropTypes.bool,
    checkoutClicked: PropTypes.func,
    deleteService: PropTypes.func,
  };

  renderEmptyList() {
    return (
      <div className={this.element('emptyList')}>
        <Text>CART_IS_EMPTY</Text>
      </div>
    );
  }

  renderServices() {
    const {
      services,
      groups,
      currency,
      freezeCart,
      deleteService,
      cmsKeys,
    } = this.props;

    const activeGroups = groups.filter(
      group => !!services.find(sv => sv.addedAs === group)
    );

    return (
      <div className={this.element('servicesList')}>
        {activeGroups.map(serviceGroup => (
          <div key={serviceGroup} className={this.element('serviceGroup')}>
            <div className={this.element('serviceGroupCaption')}>
              <Text>{`SERVICES_${serviceGroup.toUpperCase()}_GROUP`}</Text>
            </div>
            {services
              .filter(service => service.addedAs === serviceGroup)
              .reduce(mergeJourneyBased, [])
              .map((service, idx) => (
                <div className={this.element('serviceRow')}>
                  <div>
                    <p className={this.element('serviceName')}>
                      <Text useSpan fallback={service.name}>
                        {service.nameCmsKey}
                      </Text>
                      {service.seatNumber && (
                        <span>
                          &nbsp; {service.seatNumber.rowNumber}
                          {service.seatNumber.columnLetter}
                        </span>
                      )}
                      {service.serviceCode === 'BF' && (
                        <>
                          <br />
                          <span className={this.element('biofuelHourLabel')}>
                            {' '}
                            {service.quantity}{' '}
                            {cmsKeys.BIOFUEL_QTY_HOUR || 'hour'}
                          </span>
                        </>
                      )}
                    </p>
                    <p className={this.element('servicePrice')}>
                      <Price
                        price={service.price * service.quantity}
                        currency={currency}
                      />
                    </p>
                  </div>
                  {freezeCart ? null : (
                    <div
                      className={this.element('deleteBtn')}
                      onClick={() => deleteService(service)}
                    />
                  )}
                </div>
              ))}
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { checkoutClicked, services, currency, total } = this.props;

    return (
      <div className={this.block()}>
        {services.length > 0 ? this.renderServices() : this.renderEmptyList()}

        <BasketDropdownFooter
          checkoutClicked={checkoutClicked}
          disabled={services.length === 0}
          currency={currency}
          total={total}
        />
      </div>
    );
  }
}
