import './index.scss';

import * as React from 'react';
import bem from 'react-bem-classes';
import { Text } from 'languages/components/text';
import Image from 'languages/components/image/index';

export interface VaziloBannerProps {
  showVaziloBannerModal: () => void;
}

export interface VaziloBanner {
  block?(mods?: any): string;
  element?(elName: string, mods?: any): string;
}

@bem({
  block: 'vaziloBanner',
})
export class VaziloBanner extends React.Component<VaziloBannerProps, any> {
  render() {
    return (
      <div
        className={this.block()}
        onClick={() => this.props.showVaziloBannerModal()}
      >
        <Text className={this.element('tag')}>VALIZO_BANNER_TAG_TEXT</Text>
        <Image className={this.element('image')}>VALIZO_BANNER_IMG</Image>
        <div className={this.element('content')}>
          <Text className={this.element('text')}>VALIZO_BANNER_TEXT</Text>
          <Text className={this.element('btn')}>VALIZO_BANNER_BUTTON_TEXT</Text>
        </div>
      </div>
    );
  }
}
