export function hasDescendant(elem: HTMLElement, elemToCheck: HTMLElement) {
  let currentElement = elem;
  while (currentElement) {
    if (currentElement === elemToCheck) {
      return true;
    }
    currentElement = currentElement.parentElement;
  }

  return false;
}

export function getBodyOffset(
  element: HTMLElement
): { offsetTop: number; offsetLeft: number } {
  let itrElement = element;

  let offsetTop = 0;

  let offsetLeft = 0;

  while (itrElement && itrElement !== document.body) {
    offsetLeft += itrElement.offsetLeft;
    offsetTop += itrElement.offsetTop;
    itrElement = itrElement.offsetParent as HTMLElement;
  }

  return { offsetLeft, offsetTop };
}

export function scrollToTop() {
  const scrollElement = document.scrollingElement || document.documentElement;
  scrollElement.scrollTop = 0;
}
