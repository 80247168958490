import * as React from 'react';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';

import ServiceListHelper from 'services/_helpers/ServiceListHelper';
import {
  getRemovingListByService,
  removeServicesFromBasket,
} from 'serviceInBasket/actions';
import { totalPriceSelector } from 'serviceInBasket/helpers/selectors';
import { cartCurrencySelector } from 'authorization/selectors';
import { BasketDropdownContent } from 'basket/components/basketDropdownContent';
import { selectAllServices, servicesGroupsSelector } from 'services/selectors';
import BasketWrapper from 'basket/containers';
import PropTypes from 'prop-types';
import { selectUpToDateBasket } from '../../selectors/selectBasket';

@connect(state => ({
  serviceGroupList: servicesGroupsSelector(state),
  servicesList: selectAllServices(state),
  basket: selectUpToDateBasket(state),
  currency: cartCurrencySelector(state),
  total: totalPriceSelector(state),
  paxshopPage: state.routing.paxshopPage,
}))
export class BasketDropdownPanel extends React.Component {
  static propTypes = {
    checkoutClicked: PropTypes.func,
  };

  render() {
    const {
      serviceGroupList,
      basket,
      paxshopPage,
      servicesList,
      currency,
      total,
      removeItem,
      onDeletingTry,
    } = this.props;

    const freezeCart = paxshopPage.pageId === 'CHECKOUT';

    const serviceListHelper = new ServiceListHelper(servicesList);

    const services = basket.map(item => ({
      ...item,
      price: serviceListHelper.getPriceForPassengerByService(
        item,
        item.passengerId
      ),
    }));

    return (
      <div>
        <BasketDropdownContent
          services={services}
          groups={serviceGroupList}
          checkoutClicked={this.props.checkoutClicked}
          currency={currency}
          total={total}
          deleteService={service => onDeletingTry(removeItem(service))}
          freezeCart={freezeCart}
        />
      </div>
    );
  }
}

@onClickOutside
export class BasketDropdownPanelTooltip extends React.Component {
  static propTypes = {
    handleClickOutside: PropTypes.func,
    ...BasketDropdownPanel.propTypes,
  };

  componentDidMount() {
    this.setHandleClickOutside();
  }

  handleDeletingTry = promise => {
    this.customHandleClickOutside = () => {};

    promise.then(this.setHandleClickOutside, this.setHandleClickOutside);
  };

  setHandleClickOutside = () => {
    this.customHandleClickOutside = this.props.handleClickOutside;
  };

  handleClickOutside(e) {
    this.customHandleClickOutside(e);
  }

  render() {
    const { handleClickOutside, ...restProps } = this.props;
    return (
      <BasketWrapper>
        <BasketDropdownPanel
          onDeletingTry={this.handleDeletingTry}
          {...restProps}
        />
      </BasketWrapper>
    );
  }
}
