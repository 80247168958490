import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import styles from './seatInfo.css';
import Price from '../price';

const fallbackCms = (key, fallback) => key || fallback;

@connect(state => ({ cms: state.cms }))
class SeatInfo extends Component {
  constructor() {
    super();

    this.state = {
      style: {},
      showBelowSeat: false,
    };
  }

  componentDidMount() {
    const rect = ReactDOM.findDOMNode(this).getBoundingClientRect();

    this.setState({
      style: {
        position: 'fixed',
        top: rect.top < 0 ? rect.top + rect.height + 34 + 22 : rect.top,
        left: rect.left,
        bottom: 'auto',
        right: 'auto',
        margin: 0,
      },
      showBelowSeat: rect.top < 0,
    });
  }

  render() {
    const { seat, cms, widgetConfig, keys } = this.props,
      seatServiceData =
        widgetConfig.seatingServicesId2Service[
          seat.service ? seat.service.id : seat.display.image_ref
        ];

    return (
      <div
        className={`${this.props.className}
          psw-seatInfo
          ${seat.isHovering ? 'active' : ''}
          ${seat.availability ? 'available' : 'unavailable'}
          ${seat.isBusy ? 'selected' : ''}
          ${this.state.showBelowSeat ? 'showBelowSeat' : ''}
        `}
        style={this.state.style}
      >
        <div className="psw-seatInfo-title">
          {seatServiceData
            ? fallbackCms(cms[seatServiceData.nameCmsKey], seatServiceData.name)
            : fallbackCms(cms.SEATING_PAGE_SEAT_PLACE, 'Plats')}{' '}
          {seat.id}
        </div>
        <div className="psw-seatInfo-price">
          {(() => {
            if (seat.availability || seat.selected) {
              return (
                <Price
                  price={seat.price.amount}
                  currency={seat.price.currency}
                />
              );
            } else {
              return fallbackCms(cms.SEAT_PRICE, '');
            }
          })()}
        </div>
        <div className="psw-seatInfo-description">
          {(() => {
            if (seat.isAllowed && !seat.isBusy) {
              return fallbackCms(
                cms.SEATING_PAGE_SEAT_INFO,
                `Click to add to cart`
              );
            }

            if (seat.isBusy) {
              return `${fallbackCms(
                cms.SEATING_PAGE_SELECTED_SEAT,
                'Selected for'
              )} ${seat.passenger.first_name} ${seat.passenger.last_name}`;
            }

            if (seat.suggestedFor) {
              return fallbackCms(
                cms.SEAT_IS_SUGGESTED_FOR_TEXT,
                'This seat is suggested for {}, click the button above to accept'
              ).replace(
                '{}',
                `${seat.suggestedFor.first_name} ${seat.suggestedFor.last_name}`
              );
            }

            return fallbackCms(cms.SEAT_DESCRIPTION, cms.SEAT_UNAVAILABLE);
          })()}
        </div>
        <div className="psw-seatInfo__properties">
          {seat.availability &&
            (seat.seatProperties || []).map(property => {
              return (
                <div key={property}>{cms[`SEATPROPERTY_${property}`]}</div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default SeatInfo;
