import React from 'react';
import Page, { Content } from 'layouts/page';
import { Text } from 'languages/components/text';
import { connect } from 'react-redux';
import { PassengerSelector } from 'passengers/containers/selector';
import { ServicesContent } from 'services/containers/servicesContent';
import Columns from 'layouts/pageColumns';
import Cloud, { CloudTitle } from 'layouts/pageCloud';
import GroupsSelector from 'services/components/groups';
import { otherGroupsSelector } from 'services/selectors';
import { CurrentPageWizardHeader } from 'mainMenu/containers/currentPageWizardHeader';
import { authOnly } from 'authorization/helpers/authOnly';

@authOnly()
@connect(
  state => ({
    otherGroups: otherGroupsSelector(state),
    currentSegmentId: state.segments.currentSegmentId,
  }),
  dispatch => ({ dispatch })
)
export class PageOtherServices extends React.Component {
  render() {
    const { store, otherGroups, currentSegmentId } = this.props;

    return (
      <Page>
        <CurrentPageWizardHeader />

        <Content mobileFullWidth>
          <Columns
            fullWidthBreakPoint={LAYOUT_BREAKS.landscapeTablet}
            stickyPassengersSelector
          >
            <div>
              <Cloud>
                <CloudTitle>
                  <Text>LIST_OF_PASSENGERS</Text>
                </CloudTitle>
                <PassengerSelector
                  adultsFirst
                  key={currentSegmentId}
                  mobileBreakpoint={LAYOUT_BREAKS.landscapeTablet}
                />
              </Cloud>
            </div>
            <div>
              <GroupsSelector primaryGroup="otherservices" />
              <ServicesContent otherGroups={otherGroups || []} />
            </div>
          </Columns>
        </Content>
      </Page>
    );
  }
}
