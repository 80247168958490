import './index.scss';
import React, { Component } from 'react';
import bem from 'react-bem-classes';
import ListBySegmentPassegnerGroup from 'services/components/listBySegmentPassegnerGroup';

@bem({
  block: 'cartPassengerBySegment',
})
class CartPassengerBySegment extends Component {
  render() {
    const {
      segment,
      removeItem,
      basketInfo,
      serviceGroups,
      passenger,
      currency,
    } = this.props;

    return (
      <div className={this.block()}>
        <div className={this.element('cartServicesByGroup')}>
          {serviceGroups.map((group, i) => (
            <ListBySegmentPassegnerGroup
              key={i}
              currency={currency}
              removeItem={removeItem}
              passenger={passenger}
              group={group}
              list={basketInfo.filter(
                service =>
                  service.segmentId === segment.segmentId &&
                  service.passengerId === passenger.passengerId &&
                  service.addedAs === group &&
                  !service.journeyBased
              )}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default CartPassengerBySegment;
