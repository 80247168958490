import React, { Component } from 'react';
import * as wizLogic from 'norec/wizardLogic';
import bem from 'react-bem-classes';
import { Text } from 'languages/components/text';

@bem({
  block: 'modalNorec',
})
class NorecTrain extends React.Component {
  renderFlightNum(flightNumber) {
    return (
      <div className={this.element('separator')}>
        <div className={this.element('trainElement')}>
          <div className={`${this.element('formRow')} row`}>
            <div className={`${this.element('formCol')} col-md-12`}>
              <div className={`${this.element('okLabel')}`}>
                <Text useSpan>FLIGHT_NUMBER</Text>: {flightNumber}
              </div>
              <i
                className={`${this.element('iconCheckmark')} icon checkmark`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFlights() {
    return (
      <div className={this.element('separator')}>
        <div className={this.element('trainElement')}>
          <div className={`${this.element('formRow')} row`}>
            <div className={`${this.element('formCol')} col-md-12`}>
              <div className={`${this.element('okLabel')}`}>
                <Text useSpan>NOREC_CHECK_MAIN_FORM</Text>
              </div>
              <i
                className={`${this.element('iconCheckmark')} icon checkmark`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      norecState: { carrierFlightNumber, norecStep },
    } = this.props;

    const shouldRenderFlightNumber =
      !!carrierFlightNumber &&
      wizLogic.STEP_TO_INDEX[norecStep] >
        wizLogic.STEP_TO_INDEX.FLIGHT_NUMBER_FORM;

    const shouldRenderFlights =
      wizLogic.STEP_TO_INDEX[norecStep] > wizLogic.STEP_TO_INDEX.FLIGHTS_FORM;

    return (
      <div>
        {!shouldRenderFlightNumber
          ? null
          : this.renderFlightNum(carrierFlightNumber)}
        {!shouldRenderFlights ? null : this.renderFlights()}
      </div>
    );
  }
}

export default NorecTrain;
