import * as React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { Text } from 'languages/components/text';
import { Bem } from 'react-bem-classes';
import * as norecActions from 'norec/actions';
import * as wizLogic from 'norec/wizardLogic';
import { isValid, validate } from '_helpers/reduxFormHelpers';
import SelectInput from 'commonBlocks/components/selectInput';
import { allCMSKeysRawSelector } from 'languages/selectors';
import { connect } from 'react-redux';
import { IStoreState } from 'types/store';

const formDef = wizLogic.FORMS_DEF.AIRPORTS_FORM;

function isMultilegFlight(segmentIdx, norecState) {
  return norecState.segments[segmentIdx].departures.length > 1;
}

function validateAirportsForm(values, props) {
  const { norecState } = props;

  const departureExists = (departureId, segmentIdx) =>
    !!(
      departureId &&
      norecState.segments[segmentIdx].departures.find(
        d => d.departureId == departureId
      )
    );

  const departureExistsValidator = segmentIdx =>
    function(value) {
      return value && departureExists(value, segmentIdx)
        ? null
        : 'CMS_REQUIRED_FIELD';
    };

  return {
    segments: values.segments.map((segment, segmentIdx) =>
      !segment.enabled
        ? {}
        : validate(segment, {
            beginningDepartureId: [departureExistsValidator(segmentIdx)],
            endingDepartureId: [departureExistsValidator(segmentIdx)],
          })
    ),
  };
}

interface AirportsForm {
  block(mods?: any): string;
  element(elName: string, mods?: any): string;
}

const bem = new Bem('modalNorec');

const renderSegments = ({
  fields,
  meta: { error },
  buildAirportsList,
  norecState,
  cmsKeys,
  onFieldRendered,
  segmentsValues,
}) =>
  fields.map((segment, segmentIdx) => (
    <div key={segmentIdx} className={`${bem.element('formCol')} col-md-6`}>
      {/* Header + enabled/disabled */}
      <div className={bem.element('formRow')}>
        <div className={`${bem.element('formCol')} col-md-12`}>
          {segmentIdx === 0 ? (
            <div className={bem.element('subTitle')}>
              <i className="icon plane_takeoff" />
              <Text useSpan>ALL_PAGES_OUTBOUND_BLOCK_TEXT</Text>
            </div>
          ) : (
            <div className={bem.element('subTitle')}>
              <i className="icon plane-landing" />
              <Text useSpan>ALL_PAGES_INBOUND_BLOCK_TEXT</Text>
            </div>
          )}
        </div>
      </div>

      {/* Departure airport */}
      <div className={bem.element('formRow')}>
        <div className={`${bem.element('formCol')} col-md-12`}>
          <label>
            <Text useSpan>NOREC_POPUP_DEPARTURE_AIRPORT</Text>
          </label>
          <SelectInput
            allowEmptyValue
            name={`${segment}.beginningDepartureId`}
            disabled={
              !segmentsValues[segmentIdx].enabled ||
              !isMultilegFlight(segmentIdx, norecState)
            }
            options={buildAirportsList(segmentIdx, 'departureAirport')}
            getOptionId={airportInfo => airportInfo.departureId}
            getOptionText={airportInfo => airportInfo.airport.name}
            onFieldRendered={onFieldRendered}
            cmsKeys={cmsKeys}
          />
        </div>
      </div>

      {/* Arrival airport */}
      <div className={bem.element('formRow')}>
        <div className={`${bem.element('formCol')} col-md-12`}>
          <label>
            <Text useSpan>NOREC_POPUP_ARRIVAL_AIRPORT</Text>
          </label>
          <SelectInput
            allowEmptyValue
            name={`${segment}.endingDepartureId`}
            disabled={
              !segmentsValues[segmentIdx].enabled ||
              !isMultilegFlight(segmentIdx, norecState)
            }
            options={buildAirportsList(segmentIdx, 'arrivalAirport')}
            getOptionId={airportInfo => airportInfo.departureId}
            getOptionText={airportInfo => airportInfo.airport.name}
            onFieldRendered={onFieldRendered}
            cmsKeys={cmsKeys}
          />
        </div>
      </div>
    </div>
  ));

class AirportsForm extends React.Component<any, any> {
  shouldScrollToInvalid: boolean;

  static contextTypes = {
    store: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.shouldScrollToInvalid = false;
  }

  nextSubmit = (values, dispatch) => {
    const { norecState } = this.props;

    const localValidation = validateAirportsForm(values, this.props);

    if (!isValid(localValidation)) {
      this.shouldScrollToInvalid = true;
      return null;
    }

    const segmentsErrors = [];
    const processedSegments = values.segments.map((segment, segmentIdx) => {
      let segmentError = {};

      let res = { beginningDepartureId: null, endingDepartureId: null };

      if (segment.enabled) {
        const beginningDeparture = norecState.segments[
          segmentIdx
        ].departures.find(d => d.departureId == segment.beginningDepartureId);
        const endingDeparture = norecState.segments[segmentIdx].departures.find(
          d => d.departureId == segment.endingDepartureId
        );

        if (
          endingDeparture.departureDateTime <
          beginningDeparture.departureDateTime
        ) {
          segmentError = {
            beginningDepartureId: 'CMS_NOREC_DEPARTURE_AFTER_ARRIVAL',
          };
        }

        res = {
          beginningDepartureId: segment.beginningDepartureId,
          endingDepartureId: segment.endingDepartureId,
        };
      }

      segmentsErrors.push(segmentError);
      return res;
    });

    if (!isValid(segmentsErrors)) {
      this.shouldScrollToInvalid = true;
      return new Promise((resolve, reject) =>
        reject({ segments: segmentsErrors })
      );
    }

    dispatch(norecActions.finishAirportsForm(processedSegments));
    dispatch(norecActions.norecNext());
    return null;
  };

  backSubmit = () => {
    const { store } = this.context;

    const { segmentsValues } = this.props;

    const processedSegments = segmentsValues.map(segment => ({
      beginningDepartureId: segment.beginningDepartureId || null,
      endingDepartureId: segment.endingDepartureId || null,
    }));

    store.dispatch(norecActions.finishAirportsForm(processedSegments));
    store.dispatch(norecActions.norecPrev());
  };

  onFieldRendered(field, elem) {
    if (this.shouldScrollToInvalid && field.invalid) {
      this.shouldScrollToInvalid = false;
      elem.parentNode.scrollIntoView(true);
    }
  }

  render() {
    const {
      norecState,
      handleSubmit,
      submitting,
      cmsKeys,
      segmentsValues,
    } = this.props;

    const buildAirportsList = (segmentIdx, fieldName) =>
      norecState.segments[segmentIdx].departures.map(departure => ({
        departureId: departure.departureId,
        airport: departure[fieldName],
      }));

    return (
      <form
        onSubmit={handleSubmit(this.nextSubmit)}
        className={bem.element('formRoot')}
      >
        <div className={bem.element('form')}>
          {/* Heading text */}
          <div className={bem.element('formRow')}>
            <div className={`${bem.element('formCol')} col-md-12`}>
              <div className={bem.element('subText')}>
                <Text useSpan>CMS_NOREC_AIRPORTS_FORM_HEADER</Text>
              </div>
            </div>
          </div>

          <div className={`${bem.element('formRow')} row`}>
            <FieldArray
              name="segments"
              component={renderSegments as any}
              onFieldRendered={this.onFieldRendered.bind(this)}
              cmsKeys={cmsKeys}
              buildAirportsList={buildAirportsList}
              norecState={norecState}
              segmentsValues={segmentsValues}
            />
          </div>
        </div>

        {/* Next button */}
        <div className={`${bem.element('buttonRow')} row`}>
          <div className="col-md-6">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.backSubmit}
            >
              <Text useSpan>PREV_BUTTON</Text>
            </button>
          </div>

          <div className="col-md-6">
            <button className="btn btn-primary" type="submit">
              <Text useSpan>NOREC_CHECK_POPUP_SUBMIT_BUTTON</Text>
            </button>
          </div>
        </div>

        <div className={`local-loader ${submitting ? 'active' : ''}`} />
      </form>
    );
  }
}
const valueSelector = formValueSelector(formDef.reduxFormId);

const connectedForm = connect((state: IStoreState) => ({
  cmsKeys: allCMSKeysRawSelector(state),
  segmentsValues: valueSelector(state, 'segments'),
}))(AirportsForm);

export default reduxForm({
  form: formDef.reduxFormId,
  validate: validateAirportsForm,
})(connectedForm);
