import React, { ChangeEvent } from 'react';
import { Bem } from 'react-bem-classes';
import './index.scss';
import { Text } from 'languages/components/text';
import { WrappedFieldProps } from 'redux-form';

const bem = new Bem('toggle');

interface IProps {
  name: string;
  value: string;
  onChange: (value: string) => void;
  options: IOption[];
  submitFailed: boolean;
}

interface IOption {
  label: string;
  value: string;
  type: 'default' | 'positive' | 'negative';
}

const SPACE_CHAR_CODE = 32;

function createKeyPressHandler(selectOption: () => void) {
  return function handle(e: React.KeyboardEvent) {
    if (e.charCode === SPACE_CHAR_CODE) {
      selectOption();
    }
  };
}

export function Toggle(props: IProps) {
  const { name, value, onChange, options, submitFailed } = props;

  return (
    <span
      className={bem.element('wrapper')}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
    >
      {options.map(option => (
        <label
          className={bem.element('button', {
            active: value === option.value,
            [option.type]: true,
          })}
          key={option.value}
          tabIndex={0}
          onKeyPress={createKeyPressHandler(() => onChange(option.value))}
        >
          {option.label}
          <input
            className={bem.element('input')}
            type="radio"
            name={name}
            value={option.value}
          />
        </label>
      ))}
      {submitFailed &&
        value === null &&
        'You have to select one of the options'}
    </span>
  );
}

export function renderToggleField(
  field: WrappedFieldProps & Partial<IProps> & { cmsLabel: string }
) {
  const { input, options, meta, cmsLabel } = field;

  return (
    <div>
      <div className={bem.element('label')}>
        <Text className={bem.element('label-text')} useSpan>
          {cmsLabel}
        </Text>
        <Toggle
          name={input.name}
          value={input.value}
          onChange={input.onChange}
          submitFailed={meta.submitFailed}
          options={options}
        />
      </div>
      {meta.touched && meta.error && (
        <Text className={bem.element('field-error')}>{meta.error}</Text>
      )}
    </div>
  );
}
