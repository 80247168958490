import React, { useReducer } from 'react';
import { Bem } from 'react-bem-classes';
import { connect } from 'react-redux';
import { addServicesToBasket, removeServicesFromBasket } from 'basket/actions';
import { IFabService } from 'types/services';
import { IEnhancedSegment } from 'types/segments';
import { IStoreState } from 'types/store';
import { selectLoungesForSegment } from 'basket/selectors';
import { ConnectedFabServiceItem } from '../FabServiceItem';
import './index.scss';
import { CalendarIcon, OpenIcon, PlaneIcon, SelectedIcon } from './icons';
import {
  getAddHandlerForService,
  getReplaceHandlerForService,
} from './handlers';

const bem = new Bem('airportServicesList');

type IOwnProps = {
  services: IFabService[];
  segment: IEnhancedSegment;
  addToBasket: typeof addServicesToBasket;
  removeFromBasket: typeof removeServicesFromBasket;
};

const mapStateToProps = (state: IStoreState, props: IOwnProps) => ({
  addedLoungesOfSegment: selectLoungesForSegment(
    state,
    props.segment.segmentId
  ),
});

function AirportServicesList(
  props: IOwnProps & ReturnType<typeof mapStateToProps>
) {
  const {
    services,
    addToBasket,
    removeFromBasket,
    segment,
    addedLoungesOfSegment,
  } = props;
  const [isOpened, toggleSpoiler] = useReducer(
    state => !state,
    addedLoungesOfSegment.length === 0
  );

  const servicesForSegment = services.filter(
    service => service.segmentId === segment.segmentId
  );

  if (servicesForSegment.length === 0) {
    return null;
  }

  return (
    <div className={bem.block()}>
      <button
        className={bem.element('spoiler')}
        type="button"
        onClick={toggleSpoiler}
      >
        <span className={bem.element('flightInfo')}>
          <PlaneIcon
            className={bem.element('icon')}
            outbound={segment.direction === 'OUT'}
          />
          {segment.flights[0].departureAirportName}{' '}
        </span>
        <span className={bem.element('infoBadge')}>
          <CalendarIcon className={bem.element('icon')} />
          {segment.flights[0].departureDateTimeMoment.format('LL')}
        </span>
        {addedLoungesOfSegment.length > 0 && (
          <span className={bem.element('infoBadge')}>
            <SelectedIcon className={bem.element('icon')} />
            Selected: {addedLoungesOfSegment[0].name}
          </span>
        )}
        <button type="button" className={bem.element('openButton')}>
          <OpenIcon opened={isOpened} />
        </button>
      </button>
      <div className={bem.element('list', { opened: isOpened })}>
        {servicesForSegment.map(service => (
          <ConnectedFabServiceItem
            className={bem.element('item')}
            key={service.serviceId}
            onAdd={getAddHandlerForService(
              service,
              segment.direction,
              addToBasket
            )}
            onReplace={getReplaceHandlerForService(
              service,
              segment.direction,
              addedLoungesOfSegment,
              addToBasket,
              removeFromBasket
            )}
            service={service}
          />
        ))}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(AirportServicesList);
