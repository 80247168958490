import errorLogger from '_helpers/errorLogger';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { segmentsSelector } from 'segments/helpers/selectors';
import {
  addServicesToBasket,
  getListForAddingService,
  getRemovingListByService,
  removeServicesFromBasket,
} from 'serviceInBasket/actions';
import { hasSeatsForCurrentSegment } from 'serviceInBasket/helpers/selectors';
import { IStoreState } from 'types/store';
import { IServiceInBasket } from 'types/services';
import { ISegment } from 'types/segments';
import { selectUpToDateBasket } from '../selectors/selectBasket';

const connectContainer = connect<IStoreProps>((state: IStoreState) => ({
  basket: selectUpToDateBasket(state),
  hasSeatsForCurrentSegment: hasSeatsForCurrentSegment(state),
  segments: segmentsSelector(state),
}));

interface IStoreProps {
  basket: IServiceInBasket[];
  hasSeatsForCurrentSegment: boolean;
  segments: ISegment[];
}

interface IPropsForRenderProps extends IStoreProps {
  useRenderProps: true;
  children: (addItem, removeItem) => JSX.Element;
}

interface ILegacyProps extends IStoreProps {
  useRenderProps: false;
  children: React.ReactElement;
}

type IProps = IPropsForRenderProps | ILegacyProps;

class BasketContainer extends React.Component<IProps> {
  static contextTypes = {
    showConfirmationBasketDialog: PropTypes.func.isRequired,
    showObservationPopupDialog: PropTypes.func.isRequired,
  };

  addItem = async (
    services,
    passengerId,
    addedAs,
    withoutConfirmation = false
  ) => {
    const initialServicesForAdding =
      services instanceof Array ? services : [services];

    const isSeat = !!initialServicesForAdding[0].seatNumber;

    const lists = getListForAddingService(services, passengerId);

    if (!withoutConfirmation) {
      try {
        const FilteredRemovingServiceForShowing = isSeat
          ? lists.servicesForRemoving.filter(
              service => !service.tags.includes('seating')
            )
          : lists.servicesForRemoving;

        if (FilteredRemovingServiceForShowing.length > 0) {
          await this.context.showConfirmationBasketDialog({
            baseService: services instanceof Array ? services[0] : services,
            services: FilteredRemovingServiceForShowing,
            messageCmsKey: `PRODUCT_CANT_BE_ADDED_WITH_THESE_SERVICES`,
          });
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    }

    removeServicesFromBasket(lists.servicesForRemoving);
    addServicesToBasket(lists.servicesForAdding, addedAs);

    return true;
  };

  removeItem = async service => {
    const { basket } = this.props;

    const listForRemoving = getRemovingListByService(service, basket);

    // If there is only one item needs to be removed (no dependencies), we just remove it
    // If there are dependencies caused by journey based items (like airshells), we just remove it
    // Otherwise, we ask for confirmation that we are deleting items for other passengers or conflicting items
    const shouldImmediatelyRemove =
      listForRemoving.length === 1 ||
      listForRemoving.every(item => item.journeyBased);

    if (shouldImmediatelyRemove) {
      removeServicesFromBasket(listForRemoving);
      return null;
    }

    return this.context
      .showConfirmationBasketDialog({
        baseService: service,
        services: listForRemoving,
        messageCmsKey: `PRODUCTS_WILL_BE_REMOVED_ALSO`,
      })
      .then(() => {
        removeServicesFromBasket(listForRemoving);
      }, errorLogger);
  };

  render() {
    // It's highly recommended to use this API, since it is more explicit and type safe!
    if (this.props.useRenderProps) {
      return this.props.children(this.addItem, this.removeItem);
    }

    const props = this.props as ILegacyProps;

    return React.cloneElement(props.children, {
      addItem: this.addItem,
      removeItem: this.removeItem,
      ...props.children.props,
    });
  }
}

export default connectContainer(BasketContainer);
