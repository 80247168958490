import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { lockSelection, unlockSelection } from './actions/selectionLockActions';
import App from './containers/app';
import MicroEvent from 'microevent';
import configureStore from './store/configureStore';
import './constants/WindowPsw';
import { togglePassenger } from './actions/passengersActions';
import { changeSuggestedSeats, seatsChange } from './actions/seatActions';

export default class PaxportSeatingWidget extends MicroEvent {
  constructor(config) {
    super();
    this.store = configureStore(this, config);
    this.config = config;

    this.store.dispatch({
      type: 'INIT',
      config,
    });

    if (!!config.currentPassengerId) {
      this.store.dispatch(togglePassenger({ id: config.currentPassengerId }));
    }

    this.bind('suggested-seats:update', seats => {
      this.store.dispatch(changeSuggestedSeats(seats));
    });

    this.bind('passenger:switch', passenger => {
      this.store.dispatch(togglePassenger(passenger));
    });

    this.bind('seats:change', seats => {
      this.store.dispatch(seatsChange(seats));
    });

    this.bind('selection-lock:set', locked => {
      this.store.dispatch(locked ? lockSelection() : unlockSelection());
    });

    this.bind('cmsKeys:change', cmsKeys => {
      this.store.dispatch({
        type: 'SET_CMS_KEYS',
        cmsKeys,
      });
    });
  }

  mount(element) {
    this.targetElement = element;

    ReactDOM.render(
      <Provider store={this.store}>
        <App widgetConfig={this.config} />
      </Provider>,
      this.targetElement
    );
  }

  unmount() {
    ReactDOM.unmountComponentAtNode(this.targetElement);
  }
}
