import React, { useRef } from 'react';
import LoginForm from 'authorization/forms/login';
import NorecDialogs from 'norec/containers/norecDialogs';
import HomeInfo from 'pageHome/components/homeInfo';
import { Bem } from 'react-bem-classes';
import './index.scss';

const bem = new Bem('homeContent');

function HomeContentWrapper(props) {
  const { login, showHowtoModal } = props;
  const portalRef = useRef<HTMLDivElement>(null);

  return (
    <div className={bem.element('contentRow')}>
      <HomeInfo
        className={bem.element('info')}
        showHowtoModal={showHowtoModal}
      />
      <div ref={portalRef} />
      <LoginForm
        className={bem.element('login')}
        login={login}
        portalRef={portalRef}
      />
      <NorecDialogs />
    </div>
  );
}

export default HomeContentWrapper;
