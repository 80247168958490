import * as types from '../constants/PlaneActionTypes';
import http from '../vendors/http';

export function fetchConfiguration(url) {
  return {
    type: types.FETCH_CONFIGURATION,
    promise: http.get(url),
  };
}

export function fetchAllocation(url, occupiedSeats, ssrs = []) {
  return {
    type: types.FETCH_ALLOCATION,
    promise: http.post(url, ssrs),
    occupiedSeats,
    triggerEvent: {
      type: 'allocation:get',
      data: action => ({
        seatsServices: action.res.data.definitions.services,
        passengers: action.res.data.context.passengers,
        boughtSeats: action.res.data.seatmap.filter(
          seat => !!seat.allocation.passenger_ref
        ),
        seatMap: action.res.data.seatmap,
      }),
    },
  };
}

export function changeLoadStatus(status) {
  return {
    type: types.CHANGE_LOAD_STATUS,
    status: status,
  };
}
