const disability = (
  cmsLabel: string,
  name: string,
  immobilityRelated?: boolean
) => ({ cmsLabel, name, immobilityRelated: !!immobilityRelated });

export const disabilityTypes = [
  disability(
    'SPECIAL_ASSISTANCE_TYPE_ASSISTANCE_TO_AIRCRAFT',
    'ASSISTANCE_TO_AIRCRAFT',
    true
  ),
  disability(
    'SPECIAL_ASSISTANCE_TYPE_ASSISTANCE_TO_AIRCRAFT_DOOR',
    'ASSISTANCE_TO_AIRCRAFT_DOOR',
    true
  ),
  disability(
    'SPECIAL_ASSISTANCE_TYPE_ASSISTANCE_TO_AIRCRAFT_SEAT',
    'ASSISTANCE_TO_AIRCRAFT_SEAT',
    true
  ),
  disability(
    'SPECIAL_ASSISTANCE_TYPE_COLLECTION_AND_ASSISTANCE',
    'COLLECTION_AND_ASSISTANCE'
  ),
  disability(
    'SPECIAL_ASSISTANCE_TYPE_PASSENGER_VISUALLY_IMPAIRED',
    'PASSENGER_VISUALLY_IMPAIRED'
  ),
  disability(
    'SPECIAL_ASSISTANCE_TYPE_PASSENGER_HEARING_IMPAIRED',
    'PASSENGER_HEARING_IMPAIRED'
  ),
  disability('SPECIAL_ASSISTANCE_TYPE_PASSENGER_MUTE', 'PASSENGER_MUTE'),
  disability(
    'SPECIAL_ASSISTANCE_TYPE_PASSENGER_DEAF_MUTE',
    'PASSENGER_DEAF_MUTE'
  ),
  disability(
    'SPECIAL_ASSISTANCE_TYPE_PASSENGER_TO_BE_CARRIED_ON_STRETCHER',
    'PASSENGER_TO_BE_CARRIED_ON_STRETCHER'
  ),
  disability(
    'SPECIAL_ASSISTANCE_TYPE_ALL_OTHER_PASSENGERS_REQUIRE_ASSISTANCE',
    'ALL_OTHER_PASSENGERS_REQUIRE_ASSISTANCE'
  ),
];

const immobilityRelatedDisabilities = disabilityTypes
  .filter(el => el.immobilityRelated)
  .map(el => el.name);

export function doesListIncludeImmobilityRelatedDisabilities(
  disabilities: string[]
) {
  return (
    disabilities.filter(opt => immobilityRelatedDisabilities.includes(opt))
      .length > 0
  );
}
