import './index.scss';

import React, { Component } from 'react';
import bem from 'react-bem-classes';
import ItemBySegmentPassegnerGroup from 'services/components/itemBySegmentPassegnerGroup';

@bem({
  block: 'listBySegmentPassegnerGroup',
})
class ListBySegmentPassegnerGroup extends React.Component {
  render() {
    const { removeItem, group, list, currency, passenger } = this.props;

    return list.length === 0 ? null : (
      <div className={this.block()}>
        <div className={this.element('name')}>
          {passenger.firstName} {passenger.lastName}
        </div>
        <div className={this.element('services')}>
          {list.map((service, i) => (
            <ItemBySegmentPassegnerGroup
              key={i}
              currency={currency}
              removeItem={removeItem}
              service={service}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default ListBySegmentPassegnerGroup;
