import React, { Component } from 'react';
import bem from 'react-bem-classes';
import { Text } from 'languages/components/text';
import './index.scss';
import { Link } from '_helpers/link';
import Icon from '_helpers/Icon';
import { Price } from 'commonBlocks/components/price';

@bem({
  block: 'BasketDropdownButton',
  modifiers: ['isActive'],
})
class BasketDropdownButton extends Component {
  render() {
    const { total, currency } = this.props;

    return (
      <div className={this.block()}>
        <Link toPageId="cart" className={this.element('basketLink')} />
        <Icon iconCode="cart" className={this.element('icon')} />
        <div className={this.element('content')}>
          <Price
            className={this.element('total')}
            price={total}
            currency={currency}
          />
          <Text className={this.element('title')}>
            ALL_PAGES_SHOPPING_CART_TEXT
          </Text>
        </div>
      </div>
    );
  }
}

export default BasketDropdownButton;
