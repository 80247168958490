import * as types from '../constants/PlaneActionTypes';
import * as passengersActionTypes from '../constants/PassengersActionTypes';

const defaultState = {
  current: null,
  list: new Map(),
};

export default function passengersReducer(state = defaultState, action) {
  switch (action.type) {
    case types.FETCH_ALLOCATION:
      return Object.assign({}, state, {
        list: new Map(
          action.res.data.context.passengers.map(passenger => [
            passenger.id,
            passenger,
          ])
        ),
      });
    case passengersActionTypes.SWITCH_PASSENGER:
      return Object.assign({}, state, {
        current: action.passenger,
      });
    case passengersActionTypes.TOGGLE_PASSENGER:
      return Object.assign({}, state, {
        current: action.passenger,
      });
    default:
      return state;
  }
}
