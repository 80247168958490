import React, { SetStateAction, useState } from 'react';

/*
 * Stores different states under keys
 * E.g. when one component is responsible
 * for two different segments of flight or passengers
 *
 * Second argument specifies current key
 */
export function useSegmentedState<T>(initialState: T, key: string) {
  const [segmentedState, setSegmentedState] = useState<{ [key: string]: T }>(
    {}
  );

  const currentValue =
    key in segmentedState ? segmentedState[key] : initialState;
  const setState = (value: T) =>
    setSegmentedState({ ...segmentedState, [key]: value });

  return [currentValue, setState] as [T, React.Dispatch<SetStateAction<T>>];
}
