import React from 'react';
import NorecTrain from 'norec/components/train';
import FlightNumForm from 'norec/components/flightNumForm';
import PassengersForm from 'norec/components/passengersForm';
import FlightsForm from 'norec/components/flightsForm';
import AirportsForm from 'norec/components/airportsForm';
import { Text } from 'languages/components/text';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'norec/actions';
import bem from 'react-bem-classes';
import {
  dateOptionsSelector,
  allCMSKeysRawSelector,
} from 'languages/selectors';
import { Modal } from 'layouts/modal';

import './index.scss';

@connect(
  state => ({
    norecState: state.norec,
    cmsKeys: allCMSKeysRawSelector(state),
    dateFormatOptions: dateOptionsSelector(state),
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch),
  })
)
@bem({
  block: 'modalNorec',
})
class NorecDialogs extends React.Component {
  requestClose = () => {
    const { actions } = this.props;
    actions.hideNorecDialog();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.norecState.norecStep !== this.props.norecState.norecStep) {
      if (this.refs.title) {
        this.refs.title.scrollIntoView();
      }
    }
  }

  render() {
    const {
      norecState,
      dateFormatOptions: { paxshopDateFormat },
      cmsKeys,
    } = this.props;

    const visible = norecState.norecStep !== 'DISABLED';

    return (
      <Modal
        stateless
        opened={visible}
        requestClose={this.requestClose}
        dontCloseOnOutsideClick
      >
        <div className={this.block()}>
          <div className={this.element('title')} ref="title">
            <Text>NOREC_CHECK_POPUP_HEADING</Text>
          </div>

          <NorecTrain norecState={norecState} />

          {norecState.norecStep !== 'FLIGHT_NUMBER_FORM' ? null : (
            <FlightNumForm
              norecState={norecState}
              paxshopDateFormat={paxshopDateFormat}
            />
          )}

          {norecState.norecStep !== 'FLIGHTS_FORM' ? null : (
            <FlightsForm
              norecState={norecState}
              paxshopDateFormat={paxshopDateFormat}
              cmsKeys={cmsKeys}
            />
          )}

          {norecState.norecStep !== 'AIRPORTS_FORM' ? null : (
            <AirportsForm
              norecState={norecState}
              paxshopDateFormat={paxshopDateFormat}
            />
          )}

          {norecState.norecStep !== 'PASSENGERS_FORM' ? null : (
            <PassengersForm
              norecState={norecState}
              paxshopDateFormat={paxshopDateFormat}
            />
          )}
        </div>
      </Modal>
    );
  }
}

export default NorecDialogs;
