import * as wizLogic from 'norec/wizardLogic';

export function changeNorecBookingNumber(value) {
  return {
    type: 'NOREC_CHANGE_BOOKING_NUMBER',
    payload: value,
  };
}

export function showNorecDialog(bookingNumber, bookingDate, lastName) {
  return function(dispatch, getState) {
    dispatch({
      type: 'NOREC_WIZARD_INIT',
      bookingNumber,
      bookingDate,
      lastName,
    });
    dispatch(norecNext());
  };
}

export function hideNorecDialog() {
  return { type: 'NOREC_SET_STEP', norecStep: 'DISABLED' };
}

export function norecNext() {
  return function(dispatch, getState) {
    const state = getState();

    const paxshopData = state.init.paxshopData.data;

    const norecState = state.norec;

    const nextStep = wizLogic.getNorecNextStep(norecState, paxshopData);

    if (nextStep) {
      dispatch(wizLogic.FORMS_DEF[nextStep].prepareForm(norecState, state));
      dispatch({ type: 'NOREC_SET_STEP', norecStep: nextStep });
    } else {
      dispatch({ type: 'NOREC_SET_STEP', norecStep: 'DISABLED' });
    }
  };
}

export function norecPrev() {
  return function(dispatch, getState) {
    const state = getState();

    const paxshopData = state.init.paxshopData.data;

    const norecState = getState().norec;

    const prevStep = wizLogic.getNorecPrevStep(norecState, paxshopData);

    if (prevStep) {
      dispatch(wizLogic.FORMS_DEF[prevStep].prepareForm(norecState, state));
      dispatch({ type: 'NOREC_SET_STEP', norecStep: prevStep });
    } else {
      dispatch({ type: 'NOREC_SET_STEP', norecStep: 'DISABLED' });
    }
  };
}

export function finishFlightNumberForm(carrierFlightNumber, carrierCustomerId) {
  return {
    type: 'NOREC_FINISH_FLIGHT_NUMBER_FORM',
    carrierFlightNumber,
    carrierCustomerId,
  };
}

export function finishFlightsForm(
  segments,
  overriddenTourOperatorId,
  cabinClassTermsAccepted
) {
  return {
    type: 'NOREC_FINISH_FLIGHT_FORM',
    segments,
    overriddenTourOperatorId,
    cabinClassTermsAccepted,
  };
}

export function finishAirportsForm(segments) {
  return {
    type: 'NOREC_FINISH_AIRPORTS_FORM',
    segments,
  };
}

export function finishPassengersForm(passengers) {
  return {
    type: 'NOREC_FINISH_PASSENGERS_FORM',
    passengers,
  };
}

export function updateBookingConfig(origin, cabinClasses, tourOperators) {
  return {
    type: 'NOREC_UPDATE_BOOKING_CONFIG',
    bookingConfig: { origin, cabinClasses, tourOperators },
  };
}
