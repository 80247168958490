import * as React from 'react';
import bem from 'react-bem-classes';
import { connect } from 'react-redux';
import moment from 'moment';

import { Text } from 'languages/components/text';
import { Modal } from 'layouts/modal';
import { getSalesWindowOpenDate } from 'services/selectors';
import { DisplayDate, DisplayTime } from '_helpers/displayDate';
import { localeDataSelector } from 'languages/selectors';
import salesWindowIcon from './icon.svg';
import './index.scss';

@connect(state => ({
  salesOpenDates: getSalesWindowOpenDate(state),
  localeData: localeDataSelector(state),
}))
@bem({
  block: 'salesWindowWarning',
  modifiers: [],
})
export class SalesWindowWarningModal extends React.Component {
  componentDidMount() {
    const { salesOpenDates } = this.props;
    if (salesOpenDates.length && salesOpenDates[0] > new Date()) {
      this._modal.open();
    }
  }

  render() {
    const { salesOpenDates, localeData } = this.props;
    return (
      <Modal
        ref={e => (this._modal = e)}
        plain
        popupRootClass="salesWindowWarningModal"
      >
        <div className={this.block()}>
          <div className={this.element('content')}>
            <img className={this.element('image')} src={salesWindowIcon} />
            <Text className={this.element('title')}>
              SALES_WINDOW_WARNING_TITLE
            </Text>
            <Text className={this.element('mainText')}>
              SALES_WINDOW_WARNING_BEFORE_DATE
            </Text>
            &nbsp;
            <DisplayDate
              className={this.element('mainText')}
              inline
              date={moment(salesOpenDates[0])}
              localeData={localeData}
              utc={false}
            />
            &nbsp;
            <DisplayTime
              className={this.element('mainText')}
              inline
              date={moment(salesOpenDates[0])}
              localeData={localeData}
              utc={false}
            />
            <Text className={this.element('mainText')}>
              SALES_WINDOW_WARNING_AFTER_DATE
            </Text>
          </div>
        </div>
      </Modal>
    );
  }
}
