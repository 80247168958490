import { Text } from 'languages/components/text';
import React from 'react';
import { Bem } from 'react-bem-classes';
import './index.scss';
import { connect } from 'react-redux';
import { IStoreState } from 'types/store';
import { selectIsAutoAllocationAllowed } from 'authorization/selectors';

const bem = new Bem('seat');

interface IProps {
  hasInfant: boolean;
  autoAllocationAllowed: boolean;
}

function mapStateToProps(state: IStoreState) {
  return {
    autoAllocationAllowed: selectIsAutoAllocationAllowed(state),
  };
}

function LegendTypes(props: IProps) {
  const { autoAllocationAllowed, hasInfant } = props;

  return (
    <div className="seats">
      <div className={bem.block({ selected: true })}>
        <Text className={bem.element('title')}>
          SEATING_PAGE_LEGEND_SELECTED_SEAT
        </Text>
      </div>
      <div className={bem.block({ occupied: true })}>
        <Text className={bem.element('title')}>
          SEATING_PAGE_LEGEND_OCCUPIED_SEAT
        </Text>
      </div>
      {autoAllocationAllowed && (
        <div className={bem.block({ suggested: true })}>
          <Text className={bem.element('title')}>
            SEATING_PAGE_LEGEND_SUGGESTED_SEAT
          </Text>
        </div>
      )}
      {hasInfant && (
        <div className={bem.block({ infant: true })}>
          <Text className={bem.element('title')}>
            SEATING_PAGE_LEGEND_INFANT_SEAT
          </Text>
        </div>
      )}
    </div>
  );
}

export default connect(mapStateToProps)(LegendTypes);
