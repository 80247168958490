import React from 'react';
import { appConfig } from 'config';
import moment from 'moment';
import PaxportSeatingWidget from 'seating-widget';
import 'seating-widget/lib/seating-widget.css';

const formatSsrServices = services =>
  services.reduce((result, { passengerId, ssrsAffectToSeats }) => {
    const passengerIndex = result.findIndex(
      item => item.passengerId === passengerId
    );

    if (passengerIndex === -1) {
      result.push({
        passengerId,
        ssrs: ssrsAffectToSeats,
      });
    } else {
      result[passengerIndex].ssrs.push(...ssrsAffectToSeats);
    }

    return result;
  }, []);

class SeatingWidgetWrap extends React.Component {
  constructor(props) {
    super(props);

    const {
      segment,
      bookingNumber,
      cmsKeys,
      ssrsAffectToSeatsServices,
      selectionLocked,
    } = props;

    const swInitConfig = {
      selectionLocked,
      flightNumber: segment.flightNumber,
      departureDate: moment(segment.flights[0].departureDateTime).format(
        'YYYY-MM-DD'
      ),
      bookingNumber: bookingNumber.toUpperCase(),
      direction: segment.direction,
      occupiedSeats: [],
      cmsKeys,
      swApiUrl: appConfig.swApiUrl || null,
      seatingServicesId2Service: props.seatingServicesId2Service,
      currentPassengerId: props.currentPassengerId,
      ssrs: formatSsrServices(ssrsAffectToSeatsServices),
    };

    this.widget = new PaxportSeatingWidget(swInitConfig);
  }

  componentDidMount() {
    this.widget.mount(this.root);

    this.widget.bind('allocation:get', this.handleInit);
    this.widget.bind('seat:select', this.props.onSeatSelect);
    this.widget.bind('seat:unSelect', this.props.onSeatUnSelect);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.currentPassengerId !== nextProps.currentPassengerId) {
      this.widget.trigger('passenger:switch', {
        id: nextProps.currentPassengerId,
      });
    }

    this.widget.trigger('seats:change', nextProps.seats);

    if (nextProps.cmsKeys !== this.props.cmsKeys) {
      this.widget.trigger('cmsKeys:change', nextProps.cmsKeys);
    }

    this.widget.trigger('suggested-seats:update', nextProps.suggestedSeats);

    if (nextProps.selectionLocked !== this.props.selectionLocked) {
      this.widget.trigger('selection-lock:set', nextProps.selectionLocked);
    }

    if (
      nextProps.onSeatSelect !== this.props.onSeatSelect ||
      nextProps.onSeatUnSelect !== this.props.onSeatUnSelect
    ) {
      this.widget.unbind('seat:select', this.props.onSeatSelect);
      this.widget.unbind('seat:unSelect', this.props.onSeatUnSelect);

      this.widget.bind('seat:select', nextProps.onSeatSelect);
      this.widget.bind('seat:unSelect', nextProps.onSeatUnSelect);
    }
  }

  componentWillUnmount() {
    this.widget.unmount();

    this.widget.unbind('allocation:get', this.handleInit);
    this.widget.unbind('seat:select', this.props.onSelectSeat);
    this.widget.unbind('seat:unSelectByWidget', this.props.onUnSelectSeat);
  }

  handleInit = data => {
    if (this.props.onInit) {
      this.props.onInit(data);
    }
  };

  render() {
    return (
      <div
        ref={c => {
          this.root = c;
        }}
      />
    );
  }
}

export default SeatingWidgetWrap;
