import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import { appConfig } from 'config';
import { Modal } from 'layouts/modal';
import ConfirmationShare from 'confirmation/components/share';
import { connect } from 'react-redux';
import { allCMSKeysRawSelector } from 'languages/selectors';
import ConfirmationHeader from 'pageConfirmation/components/pageHeader';
import ConfirmationContent from 'pageConfirmation/components/confirmationContent';
import Page, { SubHeader } from 'layouts/page';
import { Text } from 'languages/components/text';
import { CurrentPageWizardHeader } from 'mainMenu/containers/currentPageWizardHeader';
import { authOnly } from 'authorization/helpers/authOnly';
import { totalPriceSelector } from 'serviceInBasket/helpers/selectors';
import { passengersSelector } from 'passengers/helpers/selectors';
import { segmentsSelector } from 'segments/helpers/selectors';
import { ordersSelector } from 'payment/selectors';
import { getOrders } from 'payment/actions';
import { getCheckInInfo } from 'check-in/actions';
import { getDeepLink } from 'confirmation/actions';
import LoaderContainer from 'commonBlocks/components/loader';
import { clearBasket } from 'basket/actions';
import { IStoreState } from 'types/store';
import { ICMSKeysMap } from 'types/languages';
import { ISegment } from 'types/segments';
import { IPassenger } from 'types/passengers';
import { IPaymentStoreState } from '../types/payment';
import { IBookingOrder } from '../types/backendProtocol';
import { selectApisData } from '../apis/selectors';
import { IApisInfo } from 'types/apis';

interface IProps {
  cmsKeys: ICMSKeysMap;
  segmentsList: ISegment[];
  passengersList: IPassenger[];
  currentOrderId: string;
  bookingNumber: string;
  payment: IPaymentStoreState['payment'];
  deeplink: string;
  basketTotalPrice: number;
  resellerData;
  ordersList: IBookingOrder[];
  paymentLinkSent: string;
  apis: IApisInfo | {};

  getOrders: typeof getOrders;
  getCheckInInfo: typeof getCheckInInfo;
  getDeepLink: typeof getDeepLink;
  clearBasket: typeof clearBasket;
}

const connectConfirmationPage = connect(
  (state: IStoreState) => ({
    cmsKeys: allCMSKeysRawSelector(state),
    resellerData: state.init.paxshopAppCtx.paxPartner
      ? state.init.paxshopAppCtx.paxPartner.resellerData
      : null,
    ordersList: ordersSelector(state),
    bookingNumber: state.auth.authData.data.bookingNumber,
    segmentsList: segmentsSelector(state),
    passengersList: passengersSelector(state),
    deeplink: state.confirmation.deeplink,
    payment: state.payment.payment,
    basketTotalPrice: totalPriceSelector(state),
    currentOrderId:
      state.routing.urlParams.orderId || state.routing.urlParams.purchaseId,
    paymentLinkSent: state.routing.urlParams.paymentLinkSent,
    apis: selectApisData(state),
  }),
  {
    clearBasket,
    getOrders,
    getCheckInInfo,
    getDeepLink,
  }
);

function PageConfirmation(props: IProps) {
  const {
    cmsKeys,
    segmentsList,
    passengersList,
    currentOrderId,
    bookingNumber,
    payment,
    deeplink,
    basketTotalPrice,
    resellerData,
    ordersList,
    paymentLinkSent,
    apis,
    ...actions
  } = props;

  const shareModal = useRef(null);

  useEffect(() => {
    if (appConfig.turnOnSocialMedia && !resellerData) {
      shareModal.current.open();
    }
  }, []);

  useEffect(() => {
    actions.getOrders({ includeNotPaid: paymentLinkSent === 'true' });
    if (paymentLinkSent !== 'true') {
      actions.getCheckInInfo();
    }
    actions.getDeepLink();
  }, []);

  const orders = ordersList.filter(
    order => order.orderId === parseInt(currentOrderId, 10)
  );

  const totalPrice = orders
    .filter(o => o.orderId === parseInt(currentOrderId, 10))
    .reduce((sum, o) => sum + parseFloat(o.price) * o.quantity, 0);

  const { currency } = orders.find(
    o => o.orderId === parseInt(currentOrderId, 10)
  ) || { currency: '' };

  const confirmationHeaderProps = useMemo(() => {
    if (paymentLinkSent === 'true') {
      return {
        headerText: 'PAYMENT_LINK_SENT_TITLE',
      };
    }

    return {
      totalPrice: totalPrice || basketTotalPrice,
      currency,
      headerText: 'CONFIRMATION_PAGE_TITLE',
    };
  }, [totalPrice, basketTotalPrice, currency, paymentLinkSent]);

  return (
    <Page>
      <Fragment>
        <CurrentPageWizardHeader />
        <ConfirmationHeader {...confirmationHeaderProps} />
        <SubHeader>
          <Text>
            {paymentLinkSent === 'true'
              ? 'PAYMENT_LINK_SENT_SUB_TITLE'
              : 'CONFIRMATION_PAGE_SUB_TITLE'}
          </Text>
        </SubHeader>
        <LoaderContainer loading={payment.loading}>
          <ConfirmationContent
            orders={orders}
            segmentsList={segmentsList}
            passengersList={passengersList}
            deeplink={deeplink}
            bookingNumber={bookingNumber}
            paymentLinkSent={paymentLinkSent === 'true'}
            apis={apis}
          />
        </LoaderContainer>
        <Modal ref={shareModal} centered>
          <ConfirmationShare
            defaultText={cmsKeys.CONFIRM_SHARE_DEFAULT_TEXT}
            shareUrl={cmsKeys.CONFIRM_SHARE_URL}
          />
        </Modal>
      </Fragment>
    </Page>
  );
}

export default authOnly()(connectConfirmationPage(PageConfirmation));
