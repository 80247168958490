import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'languages/components/text';

import bem from 'react-bem-classes';
import 'react-spinner/react-spinner.css';
import './index.scss';

import { goToPage } from 'routing/routing';
import { BasketTooltipButton } from 'basket/components/basketTooltipButton';
import { WizardCartButton } from 'mainMenu/components/wizardCartButton';
import { WizardTooltip } from 'mainMenu/components/wizardTooltip';
import Spinner from 'react-spinner';
import { scrollToTop } from '_helpers/domHelper';

@bem({
  block: 'wizardFooter',
})
export class WizardFooter extends React.Component {
  static propTypes = {
    mainMenuItem: PropTypes.object,
    totalPrice: PropTypes.number,
    currency: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      basketOpen: false,
      isTooltipOpen: false,
    };
  }

  backClicked() {
    const { mainMenuItem } = this.props;
    if (!mainMenuItem || !mainMenuItem.prevAllowedItem) {
      return;
    }

    goToPage(mainMenuItem.prevAllowedItem.paxshopPageId);
    scrollToTop();
  }

  nextClicked() {
    const {
      mainMenuItem,
      nextStepAvailabilityStatus,
      isValidationPending,
    } = this.props;
    if (!mainMenuItem || !mainMenuItem.nextAllowedItem || isValidationPending) {
      return;
    }

    if (nextStepAvailabilityStatus) {
      const tooltip = this._tooltip.getInstance();
      tooltip.showTooltip().then(
        () => {
          nextStepAvailabilityStatus.onResolveToolTip
            ? nextStepAvailabilityStatus.onResolveToolTip()
            : goToPage(mainMenuItem.nextAllowedItem.paxshopPageId);
          scrollToTop();
        },
        () => {
          tooltip.hideTooltip();
          nextStepAvailabilityStatus.onRejectToolTip &&
            nextStepAvailabilityStatus.onRejectToolTip();
        }
      );
      return;
    }

    goToPage(mainMenuItem.nextAllowedItem.paxshopPageId);

    scrollToTop();
  }

  handleTooltipClickOutside = () => {
    this._tooltip.getInstance().hideTooltip();
  };

  render() {
    const {
      mainMenuItem,
      totalPrice,
      currency,
      nextStepAvailabilityStatus,
      isValidationPending,
      basketEmpty,
    } = this.props;

    if (!mainMenuItem) return null;
    const isCheckout =
      mainMenuItem.id === 'CHECKOUT' ||
      mainMenuItem.id === 'CONFIRMATION' ||
      mainMenuItem.id === 'PAYMENT';

    return (
      <div className={this.block()}>
        {!!nextStepAvailabilityStatus && (
          <WizardTooltip
            handleClickOutside={this.handleTooltipClickOutside}
            ref={c => (this._tooltip = c)}
            className={this.element('tooltipMessage')}
            position="top"
            nextStepAvailabilityStatus={nextStepAvailabilityStatus}
          />
        )}

        <div
          className={this.element('button', {
            disabled: !mainMenuItem.prevAllowedItem,
            type: 'prev',
          })}
        >
          <Text useSpan onClick={() => this.backClicked()}>
            PREV_BUTTON
          </Text>
        </div>

        <div
          className={this.element('button', {
            hidden: !mainMenuItem.nextItem,
            disabled: !mainMenuItem.nextAllowedItem,
            type: 'next',
            isload: isValidationPending,
          })}
        >
          <Text useSpan onClick={() => this.nextClicked()}>
            NEXT_BUTTON
          </Text>
          {isValidationPending && (
            <Spinner className={this.element('spinner')} />
          )}
        </div>
        <div className={this.element('cart', { hidden: isCheckout })}>
          <BasketTooltipButton shouldOpenTooltip={() => false}>
            <WizardCartButton
              basketEmpty={basketEmpty}
              totalPrice={totalPrice}
              currency={currency}
            />
          </BasketTooltipButton>
        </div>
      </div>
    );
  }
}
