/*
 * This is a fake service component
 * for Jettime shop only (see ../list/index.js)
 * IT IS TEMPORARY SOLUTION
 * IT SHOULD BE REMOVED ALONGSIDE WITH ALL THE CODE
 * CONTAINING "externalService" references
 * */

import React from 'react';
import { Bem } from 'react-bem-classes';
import { Text } from 'languages/components/text';
import { IActiveServiceWithOfferStatus, IApiService } from 'types/services';
import { IEnhancedSegment } from 'types/segments';
import '../item/index.scss';

/*
 * Styles are reused from the common '../item' component
 * so be careful
 * */
const bem = new Bem('servicesItem');

interface IServiceSegment
  extends IEnhancedSegment,
    IActiveServiceWithOfferStatus {}

type IService = IApiService & {
  outboundSegment: IServiceSegment;
  inboundSegment: IServiceSegment;
  isExternalService: boolean;
};

interface IProps {
  service: IService;
  openServiceModal: any;
}

export function FakeItem(props: IProps) {
  const { service, openServiceModal } = props;

  const openServicePopup = () => {
    openServiceModal(service, {
      getInboundSegment: () => {},
      getOutboundSegment: () => {},
      toggleInboundSegmentCheckbox: () => {},
      toggleOutboundSegmentCheckbox: () => {},
    });
  };

  return (
    <div onClick={openServicePopup} className={bem.block()}>
      <div
        className={bem.element('image')}
        style={{ backgroundImage: `url(${service.imageName})` }}
      />
      <div className={bem.element('body')}>
        <Text className={bem.element('title')}>
          {service.nameCmsKey as string}
        </Text>
        <Text useSpan className={bem.element('readMoreMobile')}>
          SERVICE_DETAILS_READ_MORE
        </Text>
        <Text deleteImage className={bem.element('serviceDescription')}>
          {service.infoCmsKey as string}
        </Text>
        <div style={{ marginTop: '10px' }} className={bem.element('button')}>
          <Text>SERVICE_DETAILS_READ_MORE</Text>
        </div>
      </div>
    </div>
  );
}
