import React, { Component } from 'react';
import { Bem } from 'react-bem-classes';
import './index.scss';

import { Text } from 'languages/components/text';
import FlagIcon from 'languages/components/flag';
import Icon from '_helpers/Icon';
import { connect } from 'react-redux';
import BasketDropdownButton from 'basket/components/basketDropdownButton';
import LanguageList from 'layouts/pageHeader/components/languageList';

import { BasketTooltipButton } from 'basket/components/basketTooltipButton';
import PropTypes from 'prop-types';
import { allLangsSelector } from '../../../../languages/selectors';

class MenuMisc extends Component {
  static propTypes = {
    checkIn: PropTypes.array,
    currentLanguage: PropTypes.string.isRequired,
    basket: PropTypes.array,
    basketTotalPrice: PropTypes.number,
    carrierHasMultipleLogos: PropTypes.bool,
    alreadyCheckinOrBoughtSeats: PropTypes.bool,
    allSegmentsDeparted: PropTypes.bool,
    languages: PropTypes.arrayOf(PropTypes.string),
  };

  static contextTypes = {
    store: PropTypes.object.isRequired,
    paxShopLogout: PropTypes.func,
    showFaqDialog: PropTypes.func,
    openMybookingPopup: PropTypes.func,
    openBoardingPassesPopup: PropTypes.func,
    openContactInfoPopup: PropTypes.func,
    enableNewCheckin: PropTypes.bool.isRequired,
    moveMyBooking: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    // mobile methods
    doLogout: PropTypes.func,
    doOpenFaq: PropTypes.func,
    doOpenMyBooking: PropTypes.func,
    doOpenBoardingPasses: PropTypes.func,
    doOpenContactInfoPopup: PropTypes.func,
  };

  renderMenuItem = (
    item,
    isMobile,
    itemClass,
    mobileIconClass,
    defaultIconClass,
    itemCaptionClass
  ) => (
    <li
      key={item.icon}
      className={itemClass}
      onClick={isMobile ? item.clickMobile : item.clickDesktop}
    >
      {isMobile ? (
        <div className={mobileIconClass}>
          <span className={`icon ${item.icon}`} />
        </div>
      ) : (
        <Icon iconCode={item.icon} className={defaultIconClass} />
      )}
      <Text className={itemCaptionClass} useSpan={!isMobile}>
        {item.captionCmsKey}
      </Text>
    </li>
  );

  render() {
    const {
      currentLanguage,
      checkIn,
      alreadyCheckinOrBoughtSeats,
      allSegmentsDeparted,
      cartCurrency,
      basketTotalPrice,
      languageMenu,
      languageTrigger,
      allCMSKeys,
      languages,
      carrierHasMultipleLogos,
      isMobile,
    } = this.props;

    const {
      showFaqDialog,
      paxShopLogout,
      openMybookingPopup,
      openBoardingPassesPopup,
      openContactInfoPopup,
      enableNewCheckin,
      moveMyBooking,
      loggedIn,
      // mobile methods
      doLogout,
      doOpenFaq,
      doOpenMyBooking,
      doOpenBoardingPasses,
      doOpenContactInfoPopup,
    } = this.context;

    const defaultBem = new Bem('menuMisc', this.props, ['enableWizard']);

    const mobileBem = new Bem('mobileMenuContent');

    const menuBlockClass = isMobile
      ? mobileBem.element('menuBlock')
      : defaultBem.block();

    const itemClass = isMobile
      ? mobileBem.element('menuItem')
      : defaultBem.element('item', {
          withText: true,
          carrierHasMultipleLogos,
        });

    const mobileIconClass = mobileBem.element('menuItemIcon');

    const defaultIconClass = defaultBem.element('icon');

    const itemCaptionClass = isMobile
      ? mobileBem.element('menuItemCaption')
      : defaultBem.element('text', { carrierHasMultipleLogos });

    const MENU_DEF = [
      {
        clickMobile: doOpenContactInfoPopup,
        clickDesktop: openContactInfoPopup,
        icon: 'contacts',
        captionCmsKey: 'ALL_PAGES_CONTACTS_BUTTONS_TEXT',
        isRendered:
          loggedIn &&
          (allCMSKeys.hasOwnProperty('CONTACT_INFO_PHONE') &&
            !!allCMSKeys.CONTACT_INFO_PHONE),
      },
      {
        clickMobile: doOpenBoardingPasses,
        clickDesktop: openBoardingPassesPopup,
        icon: 'pass2',
        captionCmsKey: 'ALL_PAGES_BOARDING_PASSES_BUTTON_TEXT',
        isRendered:
          loggedIn &&
          enableNewCheckin &&
          alreadyCheckinOrBoughtSeats &&
          !allSegmentsDeparted,
      },
      {
        clickMobile: doOpenMyBooking,
        clickDesktop: openMybookingPopup,
        icon: 'pass3',
        captionCmsKey: 'ALL_PAGES_CATEGORY_NAME_1',
        isRendered: loggedIn && moveMyBooking,
      },
      {
        clickMobile: doOpenFaq,
        clickDesktop: showFaqDialog,
        icon: 'help-filled',
        captionCmsKey: 'ALL_PAGES_FAQ_BUTTON_TEXT',
        isRendered: true,
      },
      {
        clickMobile: doLogout,
        clickDesktop: paxShopLogout,
        icon: 'logout',
        captionCmsKey: 'ALL_PAGES_LOG_OUT_BUTTON_TEXT',
        isRendered: loggedIn,
      },
    ];

    const menuItems = MENU_DEF.filter(item => item.isRendered);

    return (
      <ul className={menuBlockClass}>
        {menuItems
          .splice(0, menuItems.length - 1)
          .map(item =>
            this.renderMenuItem(
              item,
              isMobile,
              itemClass,
              mobileIconClass,
              defaultIconClass,
              itemCaptionClass
            )
          )}

        {!isMobile && languages.length > 1 && (
          <li
            className={defaultBem.element('item', {
              withText: true,
              languageButton: true,
              active: languageMenu,
              carrierHasMultipleLogos,
            })}
            onClick={languageTrigger}
          >
            <FlagIcon
              className={defaultBem.element('flagIcon')}
              code={currentLanguage}
            />
            <Text
              className={defaultBem.element('text', { leftPadding: true })}
              useSpan
            >
              {`LANG_${currentLanguage.toUpperCase()}`}
            </Text>
            <Icon
              iconCode="back2"
              className={defaultBem.element('icon', { down: true })}
            />

            <LanguageList
              typeModifier="desktop"
              handleOutsideClicks={languageMenu}
              languageMenu={languageMenu}
              languageTrigger={languageTrigger}
              currentLanguage={currentLanguage}
            />
          </li>
        )}
        {menuItems.map(item =>
          this.renderMenuItem(
            item,
            isMobile,
            itemClass,
            mobileIconClass,
            defaultIconClass,
            itemCaptionClass
          )
        )}

        {!isMobile && loggedIn && (
          <li
            className={`${defaultBem.element('item', {
              faq: true,
              isBasket: true,
            })}`}
          >
            <BasketTooltipButton
              className={defaultBem.element('basketBlock', { mm: true })}
            >
              <BasketDropdownButton
                total={basketTotalPrice}
                currency={cartCurrency}
              />
            </BasketTooltipButton>
            <div className={defaultBem.element('basketBlock', { mfs: true })}>
              <BasketDropdownButton
                total={basketTotalPrice}
                isActive={false}
                currency={cartCurrency}
              />
            </div>
          </li>
        )}
      </ul>
    );
  }
}

export default connect(state => ({
  languages: allLangsSelector(state),
}))(MenuMisc);
