import React from 'react';
import { Bem } from 'react-bem-classes';
import { Text } from 'languages/components/text';
import ItemBySegmentPassegnerGroup from 'services/components/itemBySegmentPassegnerGroup';
import { IServiceInBasketWithPrice } from 'types/services';
import { IPassenger } from 'types/passengers';
import './index.scss';

interface IProps {
  servicesList: IServiceInBasketWithPrice[];
  passengersList: IPassenger[];
  currency: string;
  removeItem: (item: IServiceInBasketWithPrice) => void;
}

const bem = new Bem('journeyBasedCart');

export function JourneyBasedList({
  servicesList,
  passengersList,
  currency,
  removeItem,
}: IProps) {
  return (
    <div>
      <Text className={bem.element('title')}>
        ALL_PAGES_JOURNEY_BASED_BLOCK_TEXT
      </Text>
      {passengersList.map(passenger => (
        <div key={passenger.passengerId}>
          <div className={bem.element('passengerName')}>
            {passenger.firstName} {passenger.lastName}
          </div>
          <div>
            {getJourneyBasedServicesForPassenger(servicesList, passenger).map(
              service => (
                <ItemBySegmentPassegnerGroup
                  key={service.serviceId}
                  currency={currency}
                  removeItem={removeItem}
                  service={service}
                />
              )
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

// Filters services for only one passenger
// and combines IN/OUT services into one, with total price
function getJourneyBasedServicesForPassenger(
  servicesList: IServiceInBasketWithPrice[],
  passenger: IPassenger
) {
  const filteredServices = servicesList.filter(
    service =>
      service.journeyBased && service.passengerId === passenger.passengerId
  );
  /* Now we need to merge separate services into one fake for both IN/OUT
        Example:
        ```
        [
          Bike Airshell / IN / 10$,
          Bike Airshell / OUT / 10$,
          Stoller Airshell / IN / 20$,
          Stoller Airshell / OUT / 20$
        ] => [
          Bike Airshell / IN / 20$, // combines two
          Stoller Airshell / IN / 20$ // combines other two
        ]
        ```
  */

  // Starts with empty array
  const mergedServices = filteredServices.reduce((acc, service) => {
    // Try to find the same service with other direction
    const existingService = acc.find(
      s =>
        s.serviceId === service.serviceId && s.direction !== service.direction
    );
    // If it's already added, just sum up the prices and return the same array
    if (existingService !== undefined) {
      existingService.price += service.price;
      return acc;
    }
    // Else add new service to list
    // Make a shallow copy of the service, because we are mutating it above
    return [...acc, { ...service }];
  }, [] as IServiceInBasketWithPrice[]);

  return mergedServices;
}
