import './index.scss';
import React from 'react';
import { Bem } from 'react-bem-classes';
import { Text } from 'languages/components/text';
import { Price } from 'commonBlocks/components/price';
import { IServiceInBasketWithPrice } from 'types/services';
import { ICMSKeysMap } from 'types/languages';
import { connect } from 'react-redux';
import { IStoreState } from 'types/store';
import { allCMSKeysRawSelector } from 'languages/selectors';

const bem = new Bem('itemBySegmentPassegnerGroup');

interface IProps {
  service: IServiceInBasketWithPrice;
  removeItem: (item: IServiceInBasketWithPrice) => any;
  currency: string;
  cmsKeys: ICMSKeysMap;
}

function ItemBySegmentPassegnerGroup(props: IProps) {
  const { service, removeItem, currency, cmsKeys } = props;

  return (
    <div className={bem.block()}>
      <div className={bem.element('item-info')}>
        <div className={bem.element('name')}>
          <Text useSpan fallback={service.name}>
            {service.nameCmsKey || ''}
          </Text>
          {service.seatNumber && (
            <span>
              &nbsp; {service.seatNumber.rowNumber}
              {service.seatNumber.columnLetter}
            </span>
          )}
        </div>
        {service.serviceCode === 'BF' && (
          <span>
            {service.quantity} {cmsKeys.BIOFUEL_QTY_HOUR || 'hour'}
          </span>
        )}
        <div className={bem.element('price')}>
          <Price price={service.price * service.quantity} currency={currency} />
        </div>
      </div>
      <button
        aria-label={cmsKeys.CART_REMOVE_BUTTON}
        type="button"
        className={bem.element('remove-button')}
        onClick={() => removeItem(service)}
      />
    </div>
  );
}

export default connect((state: IStoreState) => ({
  cmsKeys: allCMSKeysRawSelector(state),
}))(ItemBySegmentPassegnerGroup);
