import React from 'react';
import './index.scss';
import bem from 'react-bem-classes';
import { Text } from 'languages/components/text';
import PropTypes from 'prop-types';

@bem({
  block: 'basketError',
})
class BasketErrorsComponent extends React.Component {
  static propTypes = {
    errors: PropTypes.array.isRequired,
    segments: PropTypes.array.isRequired,
  };

  getRoute(direction) {
    const segment = this.props.segments.find(
      segment => segment.direction === direction
    );

    return `${segment.flights[0].departureCityName} - ${segment.flights[segment.flights.length - 1].arrivalCityName}`;
  }

  render() {
    const { errors } = this.props;

    return (
      <div className={this.block()}>
        {errors.map(error => (
          <div className={this.element('item')} key={error.airPathDirection}>
            <div className={this.element('route')}>
              {this.getRoute(error.airPathDirection)}
            </div>
            <div>-</div>
            <Text>{error.description}</Text>
          </div>
        ))}
      </div>
    );
  }
}

export const BasketErrors = BasketErrorsComponent;
