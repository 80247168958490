import './index.scss';
import React from 'react';
import bem from 'react-bem-classes';
import { connect } from 'react-redux';
import { setCurrentServiceGroup } from 'services/actions/index.ts';
import { ServicesListContent } from 'services/components/listContent';
import { activeServicesWithStatusGroupByIdSelector } from 'services/selectors';
import { basketForCurrentSegmentSelector } from 'serviceInBasket/helpers/selectors';

const HEADERS_CMS = {
  vip: 'VIP_SERVICE_RULES',
  baggage: 'BAGGAGE_PAGE_SERVICES_NOTE_TEXT',
  excess_baggage: 'SERVICES_EXCESS_BAGGAGE_NOTE_TEXT',
  celebration: 'SERVICES_CELEBRATION_GROUP_NOTE_TEXT',
  lounge: 'SERVICES_LOUNGE_GROUP_NOTE_TEXT',
  meal: 'SERVICES_MEAL_GROUP_NOTE_TEXT',
  GPST: 'SERVICES_GPST_GROUP_NOTE_TEXT',
  airshells: 'SERVICES_AIRSHELLS_GROUP_NOTE_TEXT',
  lounges: 'SERVICES_LOUNGES_GROUP_NOTE_TEXT',
};

@connect(
  state => ({
    currentServiceGroup: state.services.currentGroup,
    basketForCurrentPassengerAndSegment: basketForCurrentSegmentSelector(state),
    services: activeServicesWithStatusGroupByIdSelector(state),
    currentPassengerId: state.passengers.currentPassengerId,
  }),
  {
    setCurrentServiceGroup,
  }
)
@bem({
  block: 'servicesContent',
})
export class ServicesContent extends React.Component {
  componentWillMount() {
    const { groups } = this.props;

    if (!groups) {
      return;
    }

    this.props.setCurrentServiceGroup(groups.length > 0 ? groups[0] : null);
  }

  isHeaderShowing = () => {
    const isVip = this.props.currentServiceGroup === 'vip';

    const isThereServiceToBuy = this.props.services.find(
      svc => svc.offerStatus === 'CAN_BUY'
    );

    const isThereVipInBasket = this.props.basketForCurrentPassengerAndSegment.find(
      service => service.addedAs === 'vip'
    );

    if (!isVip) {
      return true;
    }

    return !isThereServiceToBuy && !isThereVipInBasket;
  };

  render() {
    const {
      servicesSortFunc,
      services,
      currentPassengerId,
      currentServiceGroup,
      otherGroups,
    } = this.props;

    const hasOtherGroups = !!otherGroups && otherGroups.length > 1;

    const servicesToDisplay = servicesSortFunc
      ? servicesSortFunc(services)
      : services;

    return (
      <div className={this.block()}>
        <ServicesListContent
          services={servicesToDisplay}
          headerText={HEADERS_CMS[currentServiceGroup]}
          isHeaderShowing={this.isHeaderShowing()}
          key={currentServiceGroup + currentPassengerId}
          currentServiceGroup={currentServiceGroup}
          hasOtherGroups={hasOtherGroups}
        />
      </div>
    );
  }
}
