import {
  LOCK_SEAT_SELECTION,
  UNLOCK_SEAT_SELECTION,
} from '../constants/selectionLockTypes';

const initialState = { locked: true };

export default function choiceLock(state = initialState, action) {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        locked: action.config.selectionLocked,
      };
    case LOCK_SEAT_SELECTION:
      return { ...state, locked: true };
    case UNLOCK_SEAT_SELECTION:
      return { ...state, locked: false };
    default:
      return state;
  }
}
