import React, { useRef } from 'react';
import { Bem } from 'react-bem-classes';
import cn from 'classnames';
import { Text } from 'languages/components/text';
import FlagIcon from 'languages/components/flag';
import './index.scss';
import { connect } from 'react-redux';
import { IStoreState } from 'types/store';
import { allLangsSelector, currentLangSelector } from 'languages/selectors';
import { useOnClickOutside } from '_helpers/useOnClickOutside';
import { setLanguage } from 'languages/actions';

interface IStoreProps {
  languages: string[];
  currentLanguage: string;
}

interface IDispatchProps {
  changeLanguage: (lang: string) => void;
}

interface IOwnProps {
  languageTrigger?: (arg: any) => any;
  typeModifier: string;
  languageMenu: boolean;
  handleOutsideClicks?: boolean;
}

type IProps = IStoreProps & IDispatchProps & IOwnProps;

function LanguageList(props: IProps) {
  const {
    typeModifier,
    languageMenu,
    languages,
    currentLanguage,
    languageTrigger = () => {},
    changeLanguage,
    handleOutsideClicks,
  } = props;

  const wrapperRef = useRef<HTMLUListElement>();
  useOnClickOutside(wrapperRef, e => {
    if (handleOutsideClicks && languageTrigger) {
      languageTrigger(e);
    }
  });

  const isMobileBlock = typeModifier === 'mobileMenuBlock';
  const defaultBem = new Bem('languageList');
  const menuBem = new Bem('mobileMenuContent');

  const blockClass = isMobileBlock
    ? menuBem.element('menuBlock')
    : defaultBem.block({ [typeModifier]: true, active: languageMenu });

  const langCaptionClass = isMobileBlock
    ? menuBem.element('menuItemCaption')
    : defaultBem.element('languageName');

  return (
    <ul
      className={blockClass}
      ref={wrapperRef}
      onClick={() => 'clicked on wrapper'}
    >
      {languages.map(language => (
        <li
          key={language}
          onClick={e => {
            changeLanguage(language);
            if (languageTrigger) {
              languageTrigger(e);
            }
          }}
          className={
            isMobileBlock
              ? menuBem.element('menuItem')
              : defaultBem.element('language', {
                  current: currentLanguage === language,
                })
          }
        >
          {isMobileBlock ? (
            <div
              className={cn(
                menuBem.element('menuItemIcon'),
                menuBem.element('langIcon', {
                  current: currentLanguage === language,
                })
              )}
            >
              <span className="icon checkmark" />
            </div>
          ) : (
            <FlagIcon code={language} />
          )}
          <Text className={langCaptionClass} useSpan={!isMobileBlock}>
            {`LANG_${language.toUpperCase()}`}
          </Text>
        </li>
      ))}
    </ul>
  );
}

export default connect<IStoreProps, IDispatchProps>(
  (state: IStoreState) => ({
    languages: allLangsSelector(state),
    currentLanguage: currentLangSelector(state),
  }),
  {
    changeLanguage: setLanguage,
  }
)(LanguageList);
