import React from 'react';
import PropTypes from 'prop-types';

import bem from 'react-bem-classes';

import { Link } from '_helpers/link';
import { isReactElementVisible } from '_helpers/reactHelpers';
import { Price } from 'commonBlocks/components/price';

import './index.scss';

@bem({
  block: 'wizardCartButton',
})
export class WizardCartButton extends React.Component {
  static propTypes = {
    totalPrice: PropTypes.number,
    currency: PropTypes.string,
    basketEmpty: PropTypes.bool,
  };

  linkVisible() {
    return isReactElementVisible(this.refs.link);
  }

  render() {
    const { totalPrice, currency, basketEmpty } = this.props;

    return (
      <div className={this.block()}>
        <Link
          disabled={basketEmpty}
          className={`${this.element('link')} ext-cart-link`}
          toPageId="cart"
          ref="link"
        />
        <span className={this.element('cartIcon')} />
        <Price price={totalPrice} currency={currency} />
      </div>
    );
  }
}
