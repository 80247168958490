import React from 'react';
import { Bem } from 'react-bem-classes';
import { Text } from 'languages/components/text';
import localStorageHelper from '_helpers/localStorage';
import './index.scss';

class SeatingMessage extends React.Component {
  constructor(props) {
    super(props);

    this.bem = new Bem('seatingMessage', this.props, ['type', 'prmWarning']);

    const now = new Date();
    const prmMessageReopenAt = localStorageHelper.getPRMMessageReopenAt()
      ? new Date(localStorageHelper.getPRMMessageReopenAt())
      : new Date();

    this.state = {
      closed:
        !!prmMessageReopenAt &&
        now.getTime() - prmMessageReopenAt.getTime() < 0,
    };
  }

  onCloseClicked = () => {
    this.setState({ closed: true });

    const d = new Date();
    d.setTime(d.getTime() + 30 * 60 * 1000);
    localStorageHelper.setPRMMessageReopenAt(d);
  };

  render() {
    const { titleCms } = this.props;

    return (
      <div
        className={this.bem.block({
          hide: this.props.type === 'prmWarning' && this.state.closed,
        })}
      >
        <div className={this.bem.element('icon')} />
        <div className={this.bem.element('content')}>
          <Text className={this.bem.element('title')}>{titleCms}</Text>
          <div className={this.bem.element('message')}>
            {this.props.children}
          </div>
        </div>
        <Text
          className={this.bem.element('closeButton', {
            hide: this.props.type !== 'prmWarning',
          })}
          onClick={this.onCloseClicked}
        >
          PRM_WARNING_CLOSE_BUTTON_TEXT
        </Text>
      </div>
    );
  }
}

export default SeatingMessage;
