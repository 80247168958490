import React, { Suspense, lazy } from 'react';
import bem from 'react-bem-classes';
import CMSImage from 'commonBlocks/components/CMSImage';
import { Text } from 'languages/components/text';
import ConfirmationList from 'pageConfirmation/components/confirmationList';
import CheckIn from 'check-in/containers/check-in';
import LoaderContainer from 'commonBlocks/components/loader';

import './index.scss';

const LazyApis = lazy(() => import('apis/containers/apis'));

@bem({
  block: 'confirmationContent',
})
export default class ConfirmationContent extends React.Component {
  render() {
    const {
      segmentsList,
      passengersList,
      bookingNumber,
      deeplink,
      orders,
      paymentLinkSent,
      apis,
    } = this.props;

    const apisShouldRender = Object.values(apis).some(api => api !== null);

    return (
      <div className={this.block()}>
        <div className={this.element('row', { paddingCompensation: true })}>
          <div className={this.element('bookingTitleInfo')}>
            <Text className={this.element('bookingTitle')}>
              {paymentLinkSent
                ? 'CONFIRMATION_PAGE_PAYMENT_LINK_TO_BE_PAID_TITLE'
                : 'CONFIRMATION_PAGE_BOOKING_TITLE'}
            </Text>
            {!paymentLinkSent && (
              <div className={this.element('bookingSubTitle')}>
                {bookingNumber}
              </div>
            )}
          </div>

          <div className={this.element('row')}>
            <ConfirmationList
              journeyBased
              orders={orders}
              segments={segmentsList}
              passengers={passengersList}
            />
          </div>
          <div className={this.element('row')}>
            {deeplink ? (
              <div>
                {segmentsList.map(segment => (
                  <div
                    className={this.element('segmentCol', {
                      withDeeplink: true,
                    })}
                  >
                    <ConfirmationList
                      key={segment.segmentId}
                      segment={segment}
                      orders={orders}
                      segments={segmentsList}
                      passengers={passengersList}
                    />
                  </div>
                ))}
                <div className={this.element('deeplinkCol')}>
                  <a
                    className={this.element('deeplink')}
                    href={deeplink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CMSImage
                      cmsKey="CMS_EMAIL_CONFIRMATION_DUTY_FREE_BANNER_IMG_URL"
                      className={this.element('deeplinkBgImage')}
                    />
                  </a>
                </div>
              </div>
            ) : (
              segmentsList.map(segment => (
                <div
                  className={this.element('segmentCol')}
                  key={`segment_${segment.segmentId}`}
                >
                  <ConfirmationList
                    key={`${segment.segmentId}_ordersLength_${orders.length}`}
                    segment={segment}
                    orders={orders}
                    segments={segmentsList}
                    passengers={passengersList}
                  />
                </div>
              ))
            )}
          </div>

          {apisShouldRender && (
            <Suspense fallback={<LoaderContainer loading />}>
              <LazyApis />
            </Suspense>
          )}

          {!paymentLinkSent && (
            <div>
              <CheckIn />
            </div>
          )}
          <div
            className={`local-loader ${
              typeof orders === 'undefined' ? 'active' : ''
            }`}
          />
        </div>
      </div>
    );
  }
}
