import React, { ReactNode } from 'react';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { ICMSKeysMap } from 'types/languages';
import { DateInput } from 'commonBlocks/components/dateInput';
import { Text, T } from 'languages/components/text';
import { Bem } from 'react-bem-classes';
import { IStoreState } from 'types/store';
import { renderMultiCheckboxSelect } from 'commonBlocks/MultiCheckboxInput';
import {
  validateNotEmpty,
  validatePositiveNumber,
} from '_helpers/reduxFormHelpers';
import { TextFieldWithLabel } from './TextFieldWithLabel';
import {
  disabilityTypes,
  doesListIncludeImmobilityRelatedDisabilities,
} from './disabilities';
import './index.scss';
import { useLocale } from '../../../languages/_helpers/localeContext';

const immobilityDevices: [string, ReactNode][] = [
  [
    'WHEELCHAIR',
    <T>LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_TYPE_MANUAL_WHEELCHAIR</T>,
  ],
  [
    'ELECTRIC_WHEELCHAIR',
    <T>LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_TYPE_ELECTRIC_WHEELCHAIR</T>,
  ],
  [
    'ELECTRIC_SCOOTER',
    <T>LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_TYPE_ELECTRIC_SCOOTER</T>,
  ],
];

const batteryTypes: [string, ReactNode][] = [
  ['DRY_CELL', <T>LOGIN_PAGE_SPECIAL_ASSISTANCE_BATTERY_TYPE_DRY_CELL</T>],
  ['LITHIUM_ION', <T>LOGIN_PAGE_SPECIAL_ASSISTANCE_BATTERY_TYPE_LI_ON</T>],
];

const bem = new Bem('specialAssistanceForm');
const valueSelector = formValueSelector('loginForm');

const connectForm = connect((state: IStoreState) => ({
  isAidTypeElectric: ['ELECTRIC_WHEELCHAIR', 'ELECTRIC_SCOOTER'].includes(
    valueSelector(state, 'mobilityAid.mobilityAidType')
  ),
  isImmobilityDisabilitySelected: doesListIncludeImmobilityRelatedDisabilities(
    valueSelector(state, 'disabilitiesList')
  ),
  bookingNumberFilled: !!valueSelector(state, 'bookingNumber'),
}));

function SpecialAssistanceForm({
  allCMSKeys,
  localeUtils,
  paxshopDateFormat,
  bookingNumberFilled,
  isAidTypeElectric,
  isImmobilityDisabilitySelected,
}: {
  allCMSKeys: ICMSKeysMap;
  localeUtils: any;
  paxshopDateFormat: string;
  bookingNumberFilled: boolean;
  isAidTypeElectric: boolean;
  isImmobilityDisabilitySelected: boolean;
}) {
  const { t } = useLocale();

  return (
    <div>
      <h2 className={bem.element('formSectionHeader')}>Contact information</h2>
      <TextFieldWithLabel
        labelCms="LOGIN_PAGE_SPECIAL_ASSISTANCE_NAME"
        placeholder={t('LOGIN_PAGE_SPECIAL_ASSISTANCE_NAME_PLACEHOLDER')}
        name="name"
        small
      />
      <TextFieldWithLabel
        labelCms="LOGIN_PAGE_SPECIAL_ASSISTANCE_EMAIL"
        placeholder={t('LOGIN_PAGE_SPECIAL_ASSISTANCE_EMAIL_PLACEHOLDER')}
        name="emailAddress"
        small
      />
      <TextFieldWithLabel
        labelCms="LOGIN_PAGE_SPECIAL_ASSISTANCE_PHONE_NUMBER"
        placeholder={t(
          'LOGIN_PAGE_SPECIAL_ASSISTANCE_PHONE_NUMBER_PLACEHOLDER'
        )}
        name="phoneNumber"
        small
      />
      {!bookingNumberFilled && (
        <div>
          <h2 className={bem.element('formSectionHeader')}>Flight details</h2>
          <div className={bem.element('gridFlightsForm')}>
            <TextFieldWithLabel
              labelCms="LOGIN_PAGE_SPECIAL_ASSISTANCE_FLIGHT"
              placeholder={t(
                'LOGIN_PAGE_SPECIAL_ASSISTANCE_FLIGHT_PLACEHOLDER'
              )}
              name="outSegment.flightNumber"
              validate={validateNotEmpty}
              small
            />
            <Field
              name="outSegment.departureDate"
              component={DateInput}
              dateFormatStr={paxshopDateFormat}
              showHeader
              localeUtils={localeUtils}
              headerTitle={allCMSKeys.LOGIN_PAGE_LOGIN_BLOCK_DATE_LABEL}
              placeholder={allCMSKeys.LOGIN_PAGE_LOGIN_BLOCK_DATE_PLACEHOLDER}
              getErrorMsg={error => t(error)}
              validate={validateNotEmpty}
              small
            />
            <TextFieldWithLabel
              labelCms="LOGIN_PAGE_SPECIAL_ASSISTANCE_FLIGHT"
              placeholder={t(
                'LOGIN_PAGE_SPECIAL_ASSISTANCE_FLIGHT_PLACEHOLDER'
              )}
              name="inSegment.flightNumber"
              small
            />
            <Field
              name="inSegment.departureDate"
              component={DateInput}
              dateFormatStr={paxshopDateFormat}
              showHeader
              localeUtils={localeUtils}
              headerTitle={t('LOGIN_PAGE_LOGIN_BLOCK_DATE_LABEL')}
              placeholder={t('LOGIN_PAGE_LOGIN_BLOCK_DATE_PLACEHOLDER')}
              getErrorMsg={error => t(error)}
              small
            />
          </div>
        </div>
      )}
      <div className={bem.element('formGroup')}>
        <Text className={bem.element('smallLabel')}>
          LOGIN_PAGE_SPECIAL_ASSISTANCE_DISABILITIES
        </Text>
        <Field
          name="disabilitiesList"
          component={renderMultiCheckboxSelect}
          options={disabilityTypes.map(type => ({
            label: allCMSKeys[type.cmsLabel],
            name: type.name,
          }))}
          small
        />
      </div>
      {isImmobilityDisabilitySelected && (
        <div>
          <h2 className={bem.element('formSectionHeader')}>
            {t('LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_HEADER')}
          </h2>
          <div>
            <Text className={bem.element('smallLabel')}>
              LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_TYPE_LABEL
            </Text>
            {immobilityDevices.map(([id, name]) => (
              <label className={bem.element('radioLabel')} key={id}>
                <Field
                  component="input"
                  type="radio"
                  name="mobilityAid.mobilityAidType"
                  value={id}
                  required
                />
                {name}
              </label>
            ))}
          </div>
          <div className={bem.element('gridFlightsForm', { horizontal: true })}>
            <TextFieldWithLabel
              labelCms="LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_BRAND_LABEL"
              placeholder={t(
                'LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_BRAND_PLACEHOLDER'
              )}
              name="mobilityAid.brand"
              validate={validateNotEmpty}
              small
            />
            <TextFieldWithLabel
              labelCms="LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_MODEL_LABEL"
              placeholder={t(
                'LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_MODEL_PLACEHOLDER'
              )}
              name="mobilityAid.model"
              validate={validateNotEmpty}
              small
            />
          </div>
          <label className={bem.element('radioLabel')}>
            <Field
              component="input"
              type="checkbox"
              name="mobilityAid.collapsible"
            />
            {t('LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_IS_COLLAPSIBLE')}
          </label>
          <div className={bem.element('gridFlightsForm')}>
            <TextFieldWithLabel
              labelCms="LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_HEIGHT_LABEL"
              placeholder={t(
                'LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_HEIGHT_PLACEHOLDER'
              )}
              name="mobilityAid.height"
              validate={validatePositiveNumber}
              rightAddon={t('CMS_UNIT_CM')}
              small
            />
            <TextFieldWithLabel
              labelCms="LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_WIDTH_LABEL"
              placeholder={t(
                'LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_WIDTH_PLACEHOLDER'
              )}
              name="mobilityAid.width"
              validate={validatePositiveNumber}
              rightAddon={t('CMS_UNIT_CM')}
              small
            />
            <TextFieldWithLabel
              labelCms="LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_LENGTH_LABEL"
              placeholder={t(
                'LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_LENGTH_PLACEHOLDER'
              )}
              name="mobilityAid.length"
              validate={validatePositiveNumber}
              rightAddon={t('CMS_UNIT_CM')}
              small
            />
            <TextFieldWithLabel
              labelCms="LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_WEIGHT_LABEL"
              placeholder={t(
                'LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_WEIGHT_PLACEHOLDER'
              )}
              name="mobilityAid.weight"
              validate={validatePositiveNumber}
              rightAddon={t('CMS_UNIT_KG')}
              small
            />
          </div>
          {isAidTypeElectric && (
            <div>
              <div>
                <Text className={bem.element('smallLabel')}>
                  LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_BATTERY_TYPES
                </Text>
                {batteryTypes.map(([id, name]) => (
                  <label className={bem.element('radioLabel')} key={id}>
                    <Field
                      component="input"
                      type="radio"
                      name="mobilityAid.batteryType"
                      value={id}
                      required
                    />
                    {name}
                  </label>
                ))}
              </div>
              <div>
                <Text className={bem.element('smallLabel')}>
                  LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_BATTERIES_COUNT_LABEL
                </Text>
                {['1', '2'].map(num => (
                  <label className={bem.element('radioLabel')} key={num}>
                    <Field
                      component="input"
                      type="radio"
                      name="mobilityAid.numberOfBatteries"
                      value={num}
                      required
                    />
                    {num}
                  </label>
                ))}
              </div>
              <label className={bem.element('radioLabel')}>
                <Field
                  component="input"
                  name="mobilityAid.needToRemoveForTransport"
                  type="checkbox"
                />
                {t('LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_BATTERIES_TRANSPORTABLE')}
                <p className={bem.element('mutedText')}>
                  {t(
                    'LOGIN_PAGE_SPECIAL_ASSISTANCE_MA_BE_AWARE_TO_DISCONNECT_POWER'
                  )}
                </p>
              </label>
            </div>
          )}
        </div>
      )}
      <TextFieldWithLabel
        labelCms="LOGIN_PAGE_SPECIAL_ASSISTANCE_OTHER_INFO_LABEL"
        type="text"
        placeholder={t('LOGIN_PAGE_SPECIAL_ASSISTANCE_OTHER_INFO_PLACEHOLDER')}
        name="mobilityAid.otherInfo"
        small
      />
    </div>
  );
}

export default connectForm(SpecialAssistanceForm);
