/* eslint-disable react/prop-types */
import './index.scss';
import { appConfig } from 'config';
import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import cn from 'classnames';
import { Field } from 'redux-form';
import { filterInputProps } from '_helpers/reduxFormHelpers';
import { Text } from 'languages/components/text';
import TextInput from 'commonBlocks/components/textInput';
import SelectInput from 'commonBlocks/components/selectInput';
import PayVariants from 'payment/components/payVariants';
import ReactTooltip from 'react-tooltip';
import bem from 'react-bem-classes';
import { addAuthParamsToReturnURI } from 'authorization/helpers/authParams';
import { getCurrentOrigin } from '_helpers/urlHelper';
import PropTypes from 'prop-types';
import { COLLECTOR_CITIZENSHIPS } from './collectorCitizenships';
import connect from './connector';
import { submitPaymentDetails } from './submit';
import { withForm } from './withForm';

const renderTermsCheckbox = ({ input }) => (
  <input type="checkbox" name={input.name} {...filterInputProps(input)} />
);

@bem({
  block: 'paymentForm',
})
class PaymentForm extends Component {
  static contextTypes = {
    showTextInfoDialog: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { initTerms: false };
  }

  componentDidMount() {
    this.setState({
      initTerms: false,
    });
  }

  initAcceptTerms = event => {
    this.setState({
      initTerms: true,
    });
  };

  submit = values => {
    const { currentPayVariant, currentLanguage, payment } = this.props;
    const origin = getCurrentOrigin();

    const createRedirectURL = url =>
      `${origin}breakOutIframe.html?redirectUrl=${encodeURIComponent(url)}`;

    const requestData = {
      locale: currentLanguage,
      apiPspId: currentPayVariant && currentPayVariant.name,
      basketId: payment.basketId,
      okUrlForClient: addAuthParamsToReturnURI(`${origin}confirmation`), // was wrapper by createRedirectURL() method before
      notOkUrlForClient: addAuthParamsToReturnURI(`${origin}payment-failed`), // was wrapper by createRedirectURL() method before
      collectorInfo: {
        citizenship: values.citizenship,
        personalId: values.personalId,
        ipAddress: '127.0.0.1',
      },
      klarnaUrls: {
        checkout: `${origin}checkout`,
        terms: `${origin}terms-and-conditions`,
      },
      contactDetails: { phone: values.phone, email: values.email },
      address: {
        country: values.country || null,
        address1: values.address1 || null,
        address2: values.address2 || null,
        address3: values.address3 || null,
        cityOrTown: values.city || null,
        postCode: values.postCode || null,
      },
    };

    return submitPaymentDetails(values, requestData);
  };

  showTermsModal = () => {
    const { showTextInfoDialog } = this.context;
    const { currentPayVariant } = this.props;
    const modalText =
      currentPayVariant && currentPayVariant.name === 'collector'
        ? 'CMS_COLLECTOR_PANEL_TC_TEXT'
        : 'CMS_CONTACTINFO_PANEL_TC_TEXT';

    showTextInfoDialog('info', '', modalText);
  };

  renderPaymentIframe(url) {
    return (
      <iframe
        title="payment-iframe"
        scrolling="auto"
        src={url}
        className={this.element('paymentIframe')}
        frameBorder="0"
      />
    );
  }

  renderContactDetails() {
    const {
      cmsKeys,
      availableCountries,
      isKlarnaPayment,
      containsFabServices,
    } = this.props;
    const isCountryRequired = isKlarnaPayment || containsFabServices;

    return (
      <Fragment>
        {appConfig.contactDetailsFields.map(field => (
          <div key={field} className={`${this.element('field')}`}>
            <label>
              <Text>{`PAYMENT_PAGE_${field.toUpperCase()}`}</Text>
            </label>
            <TextInput
              name={field}
              required="required"
              placeholder={cmsKeys[`${field.toUpperCase()}_PLACEHOLDER`]}
            />
          </div>
        ))}
        {isCountryRequired && availableCountries.length > 1 && (
          <div className={cn(this.element('field'))}>
            <label htmlFor="country-select">
              <Text>PAYMENT_PAGE_COUNTRY</Text>
              <SelectInput
                id="country-select"
                name="country"
                options={availableCountries}
                getOptionId={country => country.countryCode}
                getOptionText={country => country.countryName}
                placeholder={cmsKeys.PAYMENT_PAGE_COUNTRY_SELECTOR_PLACEHOLDER}
                cmsKeys={cmsKeys}
              />
            </label>
          </div>
        )}
        {containsFabServices && (
          <>
            <div>
              <label>
                <Text>CMS_ADDRESS_1</Text>
              </label>
              <TextInput name="address1" />
            </div>
            <div>
              <label>
                <Text>CMS_ADDRESS_2</Text>
              </label>
              <TextInput name="address2" />
            </div>
            <div>
              <label>
                <Text>CMS_ADDRESS_3</Text>
              </label>
              <TextInput name="address3" />
            </div>
            <div>
              <label>
                <Text>CMS_CITY</Text>
              </label>
              <TextInput name="city" />
            </div>
            <div>
              <label>
                <Text>CMS_POST_CODE</Text>
              </label>
              <TextInput name="postCode" />
            </div>
          </>
        )}
      </Fragment>
    );
  }

  renderContactsForm() {
    const {
      variants,
      basket,
      handleSubmit,
      cmsKeys,
      error,
      currentPayVariant,
      isKlarnaPayment,
      setPayVariant,
      showPaymentTypeSelector,
      termsFieldValue,
      payment,
      submitButtonCMSKey,
    } = this.props;

    const basketValid = basket.validationStatus === 'VALID';
    // We don't require terms acceptance for Klarna because they are required in Klarna frame itself
    const termsAcceptanceRequired = !isKlarnaPayment;
    // In order to continue basket should be valid and terms accepted or not required (for Klarna)
    const allowPayment =
      basketValid &&
      ((termsFieldValue && this.state.initTerms) || !termsAcceptanceRequired);

    return (
      <div className={`${this.block()} col-md-12`}>
        {/* Basket errors */}
        <div
          className={this.element('basketErrors', {
            hasErrors: basket.validationErrors.length > 0,
          })}
        >
          {basket.validationErrors.map(validationError => (
            <div
              key={validationError.uniqueId}
              className={this.element('basketError')}
            >
              {validationError.messagePrefixes.map((prefix, index) => (
                <span
                  key={`pre-${index}`}
                  className={this.element('validationMsgPrefix')}
                >
                  {prefix}
                </span>
              ))}
              <Text className={this.element('validationMsg')} useSpan>
                {validationError.errorMessage}
              </Text>
            </div>
          ))}
          {basket.errorMessage && (
            <div className={this.element('basketError')}>
              <Text className={this.element('validationMsg')} useSpan>
                {basket.errorMessage}
              </Text>
            </div>
          )}
        </div>

        <div className="row col-md-12">
          {showPaymentTypeSelector && (
            <PayVariants
              setCurrentPayVariant={setPayVariant}
              variants={variants}
              currentPayVariant={currentPayVariant}
            />
          )}
        </div>

        <div className="clearfix" />

        <div className={`${this.element('paymentFormRoot')}`}>
          <form className={this.block()} onSubmit={handleSubmit(this.submit)}>
            <div className={this.element('fieldsWrapper')}>
              {this.renderContactDetails()}

              {currentPayVariant && currentPayVariant.name === 'collector' && (
                <>
                  <div className={`${this.element('field')}`}>
                    <label>
                      <Text>CMS_CITIZENSHIP</Text>
                    </label>
                    <SelectInput
                      name="citizenship"
                      options={COLLECTOR_CITIZENSHIPS}
                      cmsKeys={cmsKeys}
                      getOptionId={c => c.id}
                      getOptionText={c => cmsKeys[c.caption] || c.caption}
                      allowEmptyValue
                    />
                  </div>

                  <div className={`${this.element('field')}`}>
                    <label>
                      <Text>CMS_PERSONAL_ID</Text>
                    </label>
                    <TextInput name="personalId" />
                  </div>
                </>
              )}
            </div>

            {termsAcceptanceRequired && (
              <div>
                <label className={this.element('termsLabel')}>
                  <Field
                    name="terms"
                    onChange={this.initAcceptTerms}
                    component={renderTermsCheckbox}
                  />
                  &nbsp;
                  <Text as="span">CMS_CONTACTINFO_PANEL_TC_LABEL</Text>
                </label>
                <button
                  type="button"
                  className={cn(
                    this.element('termsItem'),
                    'icon flaticon-info'
                  )}
                  onClick={this.showTermsModal}
                />

                {payment.termsAndConditions.map(terms => (
                  <a
                    className={this.element('termsItem')}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={terms.text}
                    key={terms.text}
                  >
                    {terms.supplier}
                  </a>
                ))}
              </div>
            )}

            {error && (
              <div className={`${this.element('overallErrorRow')} row`}>
                <div className="col-md-12">
                  <Text className={this.element('overallError')}>{error}</Text>
                </div>
              </div>
            )}

            <div className={`${this.element('submitRow')} row`}>
              <div className="col-md-6 no-padding-m">
                <div data-tip data-for="mustAcceptCardPaymentTermsTooltip">
                  <button
                    className={cn(
                      this.element('submitButton'),
                      'btn btn-primary'
                    )}
                    disabled={!allowPayment}
                  >
                    <Text useSpan>{submitButtonCMSKey}</Text>
                  </button>
                </div>

                {termsAcceptanceRequired && (
                  <div
                    className={this.element('tooltip', {
                      hidden: termsFieldValue,
                    })}
                  >
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      id="mustAcceptCardPaymentTermsTooltip"
                    >
                      <Text useSpan>
                        CMS_CONTACTINFO_PANEL_TC_REQUIRED_MESSAGE_ERROR
                      </Text>
                    </ReactTooltip>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  render() {
    const { submitting, payment } = this.props;

    return (
      <div>
        <div className={cn('local-loader', { active: submitting })} />

        {payment.status === 'FILL_CONTACTS' && this.renderContactsForm()}
        {payment.status === 'IFRAME_PAYMENT' &&
          this.renderPaymentIframe(payment.paymentIframeUrl)}
      </div>
    );
  }
}

export default compose(connect, withForm)(PaymentForm);
