import React from 'react';
import PropTypes from 'prop-types';
import bem from 'react-bem-classes';
import './index.scss';

import { Text } from 'languages/components/text';
import { Link } from '_helpers/link';
import { mainMenuItemsSelector } from 'mainMenu/selectors';
import { allCMSKeysRawSelector } from 'languages/selectors';
import { connect } from 'react-redux';
import { menuVisualData } from 'mainMenu/helpers/sharedData';

@connect(state => ({
  mainMenuItems: mainMenuItemsSelector(state),
  allCmsKeys: allCMSKeysRawSelector(state),
  paxshopPage: state.routing.paxshopPage,
  wizardMenu: state.init.paxshopData.data.wizardMenu,
}))
@bem({
  block: 'layoutMenu',
  modifiers: ['wizardMenu'],
})
class MenuNavigation extends React.Component {
  render() {
    const {
      allCmsKeys,
      mainMenuItems,
      haveHeaderBackImage,
      paxshopPage,
      wizardMenu,
    } = this.props;

    return (
      <div className={this.block({ withoutImage: !haveHeaderBackImage })}>
        {mainMenuItems
          .filter(item => item.visible)
          .map(page => (
            <MenuNavigationItem
              key={page.id}
              pageType={page.type}
              pageIndex={page.index}
              pageId={page.id}
              allCmsKeys={allCmsKeys}
              current={page.paxshopPageId === paxshopPage.pageId}
              pageEnabled={page.enabled}
              pagePending={page.pending}
              paxshopPageId={page.paxshopPageId}
              wizardMenu={wizardMenu}
            />
          ))}
      </div>
    );
  }
}

@bem({
  block: 'layoutMenu',
  modifiers: ['wizardMenu'],
})
class MenuNavigationItem extends React.PureComponent {
  static propTypes = {
    allCmsKeys: PropTypes.object,
    wizardMenu: PropTypes.bool,

    pageEnabled: PropTypes.bool,
    paxshopPageId: PropTypes.string,
    pageId: PropTypes.string,
    pagePending: PropTypes.bool,
    pageIndex: PropTypes.number,
    pageType: PropTypes.string,
    current: PropTypes.bool,
  };

  render() {
    const {
      pageEnabled,
      paxshopPageId,
      pageId,
      pagePending,
      pageIndex,
      pageType,
      current,
      allCmsKeys,
      wizardMenu,
    } = this.props;

    return (
      <Link
        disabled={!pageEnabled}
        toPageId={paxshopPageId}
        className={this.element('item', {
          isActive: current,
          disabled: !pageEnabled,
        })}
      >
        <span className="left-arrow" />
        <div className={this.element('itemContent')}>
          {menuVisualData[pageId].getChildren.call(null, this)}
          <i
            className={`${this.element('icon')} ${menuVisualData[
              pageId
            ].getIconClassName(allCmsKeys)}`}
          />
          <Text className={this.element('title')}>
            {menuVisualData[pageId].title}
          </Text>
          {!wizardMenu ? null : (
            <div
              className={`${this.element('wizardIcon', {
                isActive: current,
                type: pageType,
                progress: pagePending,
              })}`}
            >
              {pageType === 'NONE' && !pagePending ? `${1 + pageIndex}` : null}
              {pageType === 'NONE' && pagePending ? '|' : null}
              {pageType === 'ERROR' ? '!' : null}
            </div>
          )}
        </div>
        <span className="right-arrow" />
      </Link>
    );
  }
}

export default MenuNavigation;
